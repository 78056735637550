import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APP_CONFIG } from '@config/app-config.service';
import { from, Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AaosBaseService } from './aaos-base.service';
import { Constants } from './constants/constants';
import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService extends AaosBaseService {

  membershipApiKey: string;
  private subject = new Subject();

  constructor(protected _h: HttpClient, protected _l: LoggingService) {
    super(_l, _h);

    this.getAppConfig();
  }

  getAppConfig() {
    APP_CONFIG.subscribe(_ => {
      this.membershipApiKey = _.membershipApiKey;
    });
  }

  constructBody(files: File[], uploadType: string, applicantId: string): Observable<any> {
    const body = {
      applicantId: applicantId,
      fileTypeGuid: uploadType,
      files: []
    };

    const promises = [];
    files.forEach((file, index) => {
      const promise = this.readFile(file)
        .then(x => {
          body.files.push({
            fileName: file.name,
            fileBase64String: x.substring(x.indexOf('base64,') + 'base64,'.length)
          });
        });
      promises.push(promise);
    });

    // once all promises finish
    return from(Promise.all(promises)
      .then(x => {
        return this.sendRequest(body).toPromise();
      }));
  }

  sendRequest(body: any): Observable<any> {
    return this.http.post(Constants.ApiUrls.UploadFiles, body)
      .pipe(
        map(x => {
          return x;
        }),
        catchError(err => {
          this.handleError(err);
          return of(null);
        })
      );
  }

  public upload(files: File[], uploadType: string, applicantId: string): Observable<any> {
    return this.constructBody(files, uploadType, applicantId);
  }

  readFile(file): Promise<any> {
    return new Promise((resolve, reject) => {
      // Create file reader
      const reader = new FileReader();

      // Read file
      reader.readAsDataURL(file);

      // Register event listeners
      reader.addEventListener('loadend', e => resolve(e.target.result));
      reader.addEventListener('error', reject);
    });
  }

  getFilesUploaded(attachmentTypeId: string, applicantId: string): Observable<string[]> {
    return this.http.get<string[]>(`${Constants.ApiUrls.GetFiles}/${attachmentTypeId}/${applicantId}`).pipe();
  }

  sendFileNamesAsSubject(fileNames: string[]) {
    this.subject.next({ name: fileNames });

  }

  getFileNamesSubject() {
    return this.subject.asObservable();
  }
}
