import { Constants } from '@shared/services/constants/constants';

export enum LogLevel {
  Trace = 5,
  Debug = 4,
  Info = 3,
  Warn = 2,
  Error = 1,
  Silent = 0
}

const _logLevelToString = (val: LogLevel) => {
  let level = Constants.LogLevel.Empty;
  switch (val) {
  case LogLevel.Trace:
    level = 'Trace';
    break;
  case LogLevel.Debug:
    level = 'Debug';
    break;
  case LogLevel.Info:
    level = 'Info';
    break;
  case LogLevel.Warn:
    level = 'Warn';
    break;
  case LogLevel.Error:
    level = 'Error';
    break;
  case LogLevel.Silent:
    level = 'Silent';
    break;
  }
  return level;
};

export const LogLevelToString = _logLevelToString;
