<div class="container">
  <div class="row justify-content-center">
    <div class="col-12 col-lg-6">
      <h2>Join AAOS</h2>
      <app-yes-no (yesNoSelected)='selection($event)' [field]='account' [displayText]="account.name"></app-yes-no>
      <div>
        <p>Please note: if you are creating an AAOS account, please return to this page when the process is completed</p>
      </div>
    </div>

    <app-main-navigation [nextEnabled]="hasAnswered" (nextSelected)="continue()" [backEnabled]="false"></app-main-navigation>
  </div>
</div>

