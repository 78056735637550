import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { FormField } from '@shared/form-fields/models/form-field';
import { FieldsPrerequisite } from '@shared/models/data/fieldsPrerequisite';
import { HospitalPrivilegeDetail } from '@shared/models/data/hospital-privilege-detail';

@Component({
  selector: 'app-hospital-privilege-list',
  templateUrl: './hospital-privilege-list.component.html',
  styleUrls: ['./hospital-privilege-list.component.scss']
})
export class HospitalPrivilegeListComponent implements OnInit {

  @Output() componentChanged = new EventEmitter();

  @Input() dynamicForm: FormGroup;
  @Input() field: FormField;
  @Input() fieldsPrerequisite: FieldsPrerequisite;

  lastId = 0;
  hospitalPrivilegeDetails: HospitalPrivilegeDetail[];

  constructor() { }

  ngOnInit() {
    this.setHospitalList();
    this.validateFormGroup();
  }

  public setHospitalList() {
    this.hospitalPrivilegeDetails = new Array<HospitalPrivilegeDetail>();
    if (this.field.value) {
      const parsedJson: Array<HospitalPrivilegeDetail> = JSON.parse(this.field.value);
      this.hospitalPrivilegeDetails = parsedJson;

      const idList = this.hospitalPrivilegeDetails.map(x => x.id);
      this.lastId = Math.max.apply(Math, idList.map(function(o) { return o; })) + 1;
    } else {
      this.addHospital();
    }
  }

  public addHospital() {
    const additionalHospitalPrivilege = new HospitalPrivilegeDetail();
    additionalHospitalPrivilege.id = this.lastId;
    this.lastId++;
    this.hospitalPrivilegeDetails.push(additionalHospitalPrivilege);
    this.validateFormGroup();
  }

  onComponentDeleted(deletedHospitalPrivilege: HospitalPrivilegeDetail) {
    this.hospitalPrivilegeDetails = this.hospitalPrivilegeDetails.filter(x => x.id !== deletedHospitalPrivilege.id);
    this.validateFormGroup();
    this.onComponentChange(null);
  }

  onComponentChange(event: any) {
    if (event !== null) {
      const i = this.hospitalPrivilegeDetails.findIndex(x => x.id === event.id);
      if (i !== -1) {
        this.hospitalPrivilegeDetails[i].name = event.name;
        this.hospitalPrivilegeDetails[i].city = event.city;
        this.hospitalPrivilegeDetails[i].state = event.state;
        this.hospitalPrivilegeDetails[i].departmentName = event.departmentName;
      }
    }

    this.field.value = JSON.stringify(this.hospitalPrivilegeDetails);
    this.componentChanged.emit(this.field);
  }

  validateFormGroup() {
    if (this.hospitalPrivilegeDetails == null || this.hospitalPrivilegeDetails.length < 1) {
      this.dynamicForm.get(this.fieldsPrerequisite.formModuleTitle).setErrors(Validators.required);
    } else {
      this.dynamicForm.get(this.fieldsPrerequisite.formModuleTitle).setErrors(null);
    }
  }
}
