<section class="content">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-8">
        <div class="text-center">
          You are not eligible to change your member type.
        </div>
      </div>
    </div>
  </div>
</section>
