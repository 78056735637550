import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TermsOfServiceComponent } from 'src/app/termsofservice/terms-of-service/terms-of-service.component';
import { UserProfileService } from '@shared/services/user-profile.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isLoggedIn: boolean;

  constructor(private dialog: MatDialog, private userProfileService: UserProfileService) { }

  ngOnInit() {
    this.userProfileService.getIsAuthenticated().subscribe((response) => this.isLoggedIn = response);
  }

  getModalConfig() {
    return {
      panelClass: 'amp-dialog-double',
      disableClose: true,
      autoFocus: false,
    } as MatDialogConfig;
  }

  openDialog() {
    const dialogRef = this.dialog.open(TermsOfServiceComponent, this.getModalConfig());
  }

}
