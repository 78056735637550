import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpResponse
} from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';


@Injectable()
export class MembershipInterceptor implements HttpInterceptor {
  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.startsWith('http')) {
      // requests sent to the local application api or front end assets
      const headers = req.headers
        // prevent IE caching
        .set('Cache-Control', 'no-cache')
        .set('Pragma', 'no-cache')
        .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
        .set('Access-Control-Allow-Origin', '*')
        // JSON
        .set('Content-Type', 'application/json; charset=utf-8')
        .set('Access-Control-Allow-Origin', '*')
        // Membership API Key
        .set('ApiKey', '14c9f8c9704e4a29a77a85e394551491'); // TODO: remove this, it's hard-coded

      const authReq = req.clone({ headers });
      return next.handle(authReq);
    }
      return next.handle(req); // external requests
  }
}
