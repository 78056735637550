import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Preference, ProfileFormOption } from '@shared/models/data';
import { FieldsPrerequisite } from '@shared/models/data/fieldsPrerequisite';
import { FormOptionsMapperService } from '@shared/services/forms/form-options-mapper.service';
import { UserProfileService } from '@shared/services/user-profile.service';
import { forkJoin } from 'rxjs';
import { BaseMultipleOptionsField } from '../base-multiple-options-field';

@Component({
  selector: 'app-state-drop-down',
  templateUrl: './state-drop-down.component.html',
  styleUrls: ['./state-drop-down.component.scss']
})
export class StateDropDownComponent extends BaseMultipleOptionsField implements OnInit {

  @Input() fieldsPrerequisite: FieldsPrerequisite;
  formControlName: string;
  selectedValue: string;
  @Input() dynamicForm: FormGroup;
  formGroup: FormGroup;
  isRequired = true;

  loading = true;
  states: Preference[] = [];
  statesLoaded = false;

  constructor(private userProfileService: UserProfileService,
              formFieldsOptionsMapper: FormOptionsMapperService) {
    super(formFieldsOptionsMapper);
  }

  ngOnInit() {
    // load states first, then load the super's oninit
    this.userProfileService.getStates(this.fieldsPrerequisite.countryCode).subscribe(x => {
      this.states = x;
      this.statesLoaded = true;
      this.loading = false;

      if (this.field && !this.field.fieldIsDisabled) {
        this.formGroup = this.dynamicForm.get(this.fieldsPrerequisite.formModuleTitle) as FormGroup;
        super.ngOnInit();
      } else {
        this.formGroup = this.dynamicForm;
      }

      if (this.field.displayName.indexOf('Optional') !== -1) {
        this.isRequired = false;
        this.formGroup.get(this.field.name).setErrors(null);
        this.formGroup.get(this.field.name).setValidators(null);
      }

      // if there aren't states and the field is null, instead of showing the null object, show empty string
      if (x.length === 0 && !this.field.value) {
        this.formGroup.controls[this.field.name].setValue('');
      }
    });
  }

  componentChange(event: any) {
    this.field.value = this.selectedValue;
    this.componentChanged.emit(this.field);
  }

  detectInputChange(event: any) {
    this.field.value = event.target.value;
    this.componentChanged.emit(this.field);
  }

}
