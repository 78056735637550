<div class="container">
  <div class="row justify-content-center mt15">
    <div class="col-3">
      <h4>Member Type</h4>
      <mat-select [formControl]="addMemberType" required (selectionChange)="FilterCanApplyTo()">
        <mat-option [value]="null">--</mat-option>
        <mat-option *ngFor="let memberType of allMemberTypes" [value]="memberType">{{memberType}}</mat-option>
      </mat-select>
      <mat-error *ngIf="addMemberType.hasError('required')">Please choose a member type</mat-error>
    </div>
    <div class="col-3">
      <h4>Can Apply To</h4>
      <mat-select [formControl]="addCanApplyTo" required>
        <mat-option [value]="null">--</mat-option>
        <mat-option *ngFor="let memberType of applicationMemberTypes" [value]="memberType">{{memberType}}</mat-option>
      </mat-select>
      <mat-error *ngIf="addCanApplyTo.hasError('required')">Please choose a member type</mat-error>
    </div>
    <div class="col-3">
      <h4>From Date</h4>
      <div class="dateContainer">
          <div (click)="fromDatePicker.open()" class="date--form-control half-width">
              <input [disabled]='true' [required]="true" [matDatepicker]="fromDatePicker"
                  class="form-control" (dateInput)="$event" [(ngModel)]="fromDate">
          </div>
          <mat-datepicker-toggle matSuffix (click)="ClearRowFromDate()">
            <mat-icon matDatepickerToggleIcon>clear</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker-toggle [for]="fromDatePicker"></mat-datepicker-toggle>
          <mat-datepicker [disabled]='false' #fromDatePicker ngDefaultControl></mat-datepicker>
      </div>
    </div>
    <div class="col-3">
      <h4>To Date</h4>
      <div class="dateContainer">
          <div (click)="toDatePicker.open()" class="date--form-control half-width">
              <input [disabled]='true' [required]="true" [matDatepicker]="toDatePicker"
                  class="form-control" (dateInput)="$event" [(ngModel)]="toDate">
          </div>
          <mat-datepicker-toggle matSuffix (click)="ClearRowToDate()">
            <mat-icon matDatepickerToggleIcon>clear</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker-toggle [for]="toDatePicker"></mat-datepicker-toggle>
          <mat-datepicker [disabled]='false' #toDatePicker ngDefaultControl></mat-datepicker>
      </div>
    </div>
  </div>
  <div class="row mt15">
    <div class="col justify-content-end">
      <button class="btn btn--solid" (click)="AddRow()" [disabled]="addMemberType.hasError('required') || addCanApplyTo.hasError('required')">Add Row</button>
    </div>
  </div>
  <div class="row justify-content-center mt30">
    <h2>Restrictions</h2>
  </div>
  <div class="row justify-content-center mt15">
    <div class="col">
      <table class="w100" mat-table mat-elevation-z8 matSort [dataSource]="data">
        <ng-container matColumnDef="memberType">
          <mat-header-cell *matHeaderCellDef>Member Type</mat-header-cell>
          <mat-cell *matCellDef="let item">
            <div *ngIf="item.id === editingRow">
              <mat-select [formControl]="editMemberType" disableOptionCentering required>
                <mat-option *ngFor="let memberType of allMemberTypes" [value]="memberType">{{memberType}}</mat-option>
              </mat-select>
            </div>
            <div *ngIf="item.id !== editingRow">
              {{ item.memberCode }}
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="canApplyTo">
          <mat-header-cell *matHeaderCellDef>Can Apply To</mat-header-cell>
          <mat-cell *matCellDef="let item">
            <div *ngIf="item.id === editingRow">
              <mat-select [formControl]="editCanApplyTo" disableOptionCentering required>
                <mat-option *ngFor="let memberType of applicationMemberTypes" [value]="memberType">{{memberType}}</mat-option>
              </mat-select>
            </div>
            <div *ngIf="item.id !== editingRow">
              {{ item.canSeeApplyTo }}
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="fromDate">
          <mat-header-cell *matHeaderCellDef>From Date</mat-header-cell>
          <mat-cell *matCellDef="let item">
            <div *ngIf="item.id === editingRow" class="dateContainer">
              <div (click)="fromDatePicker.open()" class="date--form-control half-width">
                  <input [disabled]='true' [required]="true" [matDatepicker]="fromDatePicker"
                      class="form-control fs-08rem" (dateInput)="$event" [(ngModel)]="editFromDate">
              </div>
              <mat-datepicker-toggle matSuffix (click)="ClearEditFromDate()">
                <mat-icon matDatepickerToggleIcon>clear</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker-toggle [for]="fromDatePicker"></mat-datepicker-toggle>
              <mat-datepicker [disabled]='false' #fromDatePicker ngDefaultControl></mat-datepicker>
            </div>
            <div *ngIf="item.id !== editingRow">
              {{ item.availableFromDate | momentdate: 'YYYY-MM-DD' }}
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="toDate">
          <mat-header-cell *matHeaderCellDef>To Date</mat-header-cell>
          <mat-cell *matCellDef="let item">
            <div *ngIf="item.id === editingRow" class="dateContainer">
              <div (click)="toDatePicker.open()" class="date--form-control half-width">
                    <input [disabled]='true' [required]="true" [matDatepicker]="toDatePicker"
                        class="form-control fs-08rem" (dateInput)="$event" [(ngModel)]="editToDate">
                </div>
                <mat-datepicker-toggle matSuffix (click)="ClearEditToDate()">
                  <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker-toggle [for]="toDatePicker"></mat-datepicker-toggle>
                <mat-datepicker [disabled]='false' #toDatePicker ngDefaultControl></mat-datepicker>
            </div>
            <div *ngIf="item.id !== editingRow">
              {{ item.availableToDate | momentdate: 'YYYY-MM-DD' }}
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="delete">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let item"><button class="btn btn--solid" (click)="DeleteRow(item)">DELETE</button></mat-cell>
        </ng-container>
        <ng-container matColumnDef="edit">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let item">
            <button *ngIf="item.id !== editingRow" class="btn btn--solid" (click)="EditRow(item)">EDIT</button>
            <button *ngIf="item.id === editingRow" class="btn btn--solid" (click)="SaveRowChanges(item)">SAVE</button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
        <mat-row *matRowDef="let item; columns: columnsToDisplay;"></mat-row>
      </table>
    </div>
  </div>
</div>
<app-aaos-spinner [isLoading]="loadingMemberTypes || loadingMappings || loadingAllMemberGroupCodes || loadingAction"></app-aaos-spinner>