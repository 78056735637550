<div class="form-group" [formGroup]="baseFormGroup">
    <app-aaos-form-field-title [field]="field" [displayText]="getDisplayText()"></app-aaos-form-field-title>
    <mat-radio-group formControlName="{{field.name}}" [disabled]="field.fieldIsDisabled" required [value]="field.value">
        <mat-radio-button [value]="'yes'" [checked]="field.value === 'yes' || field.value ==='Yes'"
            (change)="componentChange($event)" (change)="populateFormGroup()" (change)="YesNoClick(true)"
            (change)="enableDisableChildren(true)" [disabled]="field.fieldIsDisabled">Yes</mat-radio-button>
        <mat-radio-button [value]="'no'" [checked]="field.value === 'no' || field.value ==='No'"
            (change)="componentChange($event)" (change)="populateFormGroup()" (change)="YesNoClick(false)"
            (change)="enableDisableChildren(false)" [disabled]="field.fieldIsDisabled">No&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span *ngIf="secretNoMessage && field.value === 'no'" class="no-message " [innerHtml]="secretNoMessage"></span>
        </mat-radio-button>
    </mat-radio-group>
    <div class="required-text" *ngIf="!field.value">
        This field is required
    </div>
</div>