import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FormField } from '@shared/form-fields/models/form-field';
import { CustomerSearchProfileMini } from '@shared/models/data/CustomerSearchProfileMini';
import { MemberFormListRequirement } from '@shared/models/data/MemberFormListRequirement';
import { ApplicantService } from '@shared/services/forms/applicant.service';
import { MemberFormListRequirementService } from '@shared/services/forms/member-form-list-requirement.service';
import { UserProfileService } from '@shared/services/user-profile.service';
import { SponsorsDialogComponent } from '../sponsors-dialog/sponsors-dialog.component';

@Component({
  selector: 'app-aaos-sponsors',
  templateUrl: './sponsors.component.html',
  styleUrls: ['./sponsors.component.scss']
})
export class SponsorsComponent implements OnInit {

  firstNameControl = 'firstName';
  lastNameControl = 'lastName';
  firstName: string;
  lastName: string;
  formGroup: FormGroup;
  sponsors = new Array<CustomerSearchProfileMini>();
  searchResults = new Array<CustomerSearchProfileMini>();
  searchResultDisplayText: string;
  memberFormListRequirement: MemberFormListRequirement;
  @Input() field: FormField;
  @Output() componentChanged = new EventEmitter();
  @Input() dynamicForm: FormGroup;
  @Input() fieldsPrerequisite;

  numberWordArray = ['one', 'two', 'three'];
  amountRequired: string;

  loading = true;

  maxLimitReached = false;



  constructor(
    private userProfileService: UserProfileService,
    private dialog: MatDialog,
    private memberFormListRequirementService: MemberFormListRequirementService,
    private applicantService: ApplicantService) { }

  ngOnInit() {
    this.memberFormListRequirementService
      .getMemberFormListRequirement(this.fieldsPrerequisite.formModuleId, this.applicantService.getApplicantId()).subscribe(x => {
        this.createFormGroup();

        this.memberFormListRequirement = x;
        if (x === null) {
          this.memberFormListRequirement = new MemberFormListRequirement();
          this.memberFormListRequirement.required = 1;
          this.memberFormListRequirement.total = 1;
        }
        this.amountRequired = this.numberWordArray[this.memberFormListRequirement.required - 1];

        if (this.field.value) {
          const parsedJson: Array<CustomerSearchProfileMini> = JSON.parse(this.field.value);
          this.sponsors = parsedJson;
        }

        this.validateFormGroup();

        this.loading = false;
      });
  }

  createFormGroup() {
    this.formGroup = new FormGroup({
      firstName: new FormControl(''),
      lastName: new FormControl('')
    });

  }

  invalidRequiredField(fieldName: string): boolean {
    if (this.formGroup.get(fieldName).hasError('required')) {
      return true;
    }
    return false;
  }

  searchSponsor() {
    let sponsorName = this.formGroup.controls[this.lastNameControl].value;
    if (typeof sponsorName !== 'undefined' && sponsorName) {
      const maxLimit = 40;
      this.loading = true;
      this.userProfileService.searchSponsors(sponsorName,
        this.formGroup.controls[this.firstNameControl].value,
        maxLimit)
        .subscribe(x => {
          this.loading = false;

          this.searchResults = x;

          if (maxLimit > -1) {
            this.maxLimitReached = false;
            if (this.searchResults.length > maxLimit) {
              // display a message that there are more users and to be more specific
              this.maxLimitReached = true;

              // remove the last user
              this.searchResults.pop();
            }
          }

          // filter out any sponsors that theyve already selected
          this.searchResults = this.searchResults.filter(results =>
             this.sponsors.findIndex(sponsor => sponsor.masterCustomerId === results.masterCustomerId) === -1);

          this.openDialog();
        });
      sponsorName = '';
    }
  }

  addSponsor(sponsor: CustomerSearchProfileMini) {
    this.sponsors.push(sponsor);
    this.validateFormGroup();
    this.componentChange();
  }

  componentChange() {
    this.field.value = JSON.stringify(this.sponsors);
    this.componentChanged.emit(this.field);
  }

  getModalConfig() {
    const data = {
      results: this.searchResults,
      maxLimitReached: this.maxLimitReached
    };

    return {
      panelClass: 'amp-dialog',
      disableClose: true,
      autoFocus: false,
      data: data,
    } as MatDialogConfig;
  }

  openDialog() {
    const dialogRef = this.dialog.open(SponsorsDialogComponent, this.getModalConfig());

    dialogRef.componentInstance.actionTaken.subscribe(x => {
      return this.addSponsor(x);
    });
  }

  deleteSponsor(id: string) {
    this.sponsors = this.sponsors.filter(x => x.masterCustomerId !== id);
    this.validateFormGroup();
    this.componentChange();
  }

  validateFormGroup() {
    if (this.sponsors !== null && this.sponsors.length < this.memberFormListRequirement.required) {
      this.dynamicForm.get(this.fieldsPrerequisite.formModuleTitle).setErrors(Validators.required);
    } else {
      this.dynamicForm.get(this.fieldsPrerequisite.formModuleTitle).setErrors(null);
    }
  }


}
