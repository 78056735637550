import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldsPrerequisite } from '@shared/models/data/fieldsPrerequisite';
import { Constants } from '@shared/services/constants/constants';
import { FileUploadService } from '@shared/services/file-upload.service';
import { ApplicantService } from '@shared/services/forms/applicant.service';
import { BaseFieldComponentDirective } from '../base-field-component';

@Component({
  selector: 'app-file-upload-info',
  templateUrl: './file-upload-info.component.html',
  styleUrls: ['./file-upload-info.component.scss']
})
export class FileUploadInfoComponent extends BaseFieldComponentDirective implements OnInit {
  attachmentTypeId: string;
  fileNames: string[] = [];
  loading = true;

  @Input() dynamicForm: FormGroup;
  @Input() fieldsPrerequisite: FieldsPrerequisite;

  constructor(private fileUploadService: FileUploadService,
    private applicantService: ApplicantService) {
    super();
  }

  ngOnInit() {
    if (this.fieldsPrerequisite.formModuleTitle.indexOf('Acceptance') !== -1) {
      this.attachmentTypeId = Constants.FileUploadTypes.AcceptanceLetter;
    } else if (this.fieldsPrerequisite.formModuleTitle.indexOf('Sponsor') !== -1) {
      this.attachmentTypeId = Constants.FileUploadTypes.LetterOfRecommendation;
    } else if (this.fieldsPrerequisite.formModuleTitle .indexOf('Curriculum') !== -1) {
      this.attachmentTypeId = Constants.FileUploadTypes.CV;
    } else if (this.fieldsPrerequisite.formModuleTitle.indexOf('National Practitioner Reports') !== -1) {
      this.attachmentTypeId = Constants.FileUploadTypes.NationalPractitioner;
    } else {
      this.attachmentTypeId = Constants.FileUploadTypes.AdditionalFile;
    }

    this.fileUploadService.getFilesUploaded(this.attachmentTypeId, this.applicantService.getApplicantId()).subscribe(x => {
      if (x) {
        this.fileNames = x;
        this.fileUploadService.sendFileNamesAsSubject(this.fileNames);
      }
      this.loading = false;
    });
  }

}
