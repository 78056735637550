import { Subscription } from 'rxjs';

abstract class LogLevel {
    public static readonly Empty: string = '<Log Level>';
    public static readonly Trace: string = 'Trace';
    public static readonly Debug: string = 'Debug';
    public static readonly Info: string = 'Info';
    public static readonly Warn: string = 'Warn';
    public static readonly Error: string = 'Error';
    public static readonly Silent: string = 'Silent';
}

abstract class FormModuleTitle {
    public static readonly Location: string = 'Location';
    public static readonly Language: string = 'Language';
    public static readonly PhoneAndEmail: string = 'Communication Email';
    public static readonly Specialization: string = 'Specialization';
    public static readonly Npi: string = 'Npi';
    public static readonly DomesticMedicalSchool: string = 'DomesticMedicalSchools';
    public static readonly StudentEmail: string = 'Student Email Module';
    public static readonly PersonalInformation: string = 'Personal Information';
    public static readonly PrimaryAddress: string = 'Communication Address';
    public static readonly HomeMedicalStudentAddressName: string = 'Home Address';
    public static readonly SchoolMedicalStudentAddressName: string = 'School Address';
}

abstract class Observables {
    public static readonly Closed: keyof Subscription = 'closed';
}

abstract class FormFieldGroupTitle {
    public static readonly LocationInformation: string = 'Location Information';
}

export class UserProfileFormOptions {
    public static readonly Languages: string = 'Languages';
    public static readonly Countries = 'ApplicationCountries';
    public static readonly PracticeSpecializationsPrimary = 'PracticeSpecializationsPrimary';
    public static readonly Subspecialty: string = 'Subspecialty';
    public static readonly DomesticMedicalSchools: string = 'DomesticMedicalSchools';
    public static readonly Ethnicities: string = 'Ethnicities';
    public static readonly Genders: string = 'Genders';
    public static readonly SexualOrientation: string = 'SexualOrientations';
    public static readonly Pronouns: string = 'Pronouns';
    public static readonly Credentials: string = 'Credentials';

}

abstract class DateFilterTypes {
    public static readonly Empty: string = '<Date Filter>';
    public static readonly None: string = 'None';
    public static readonly Day: string = 'Today';
    public static readonly Week: string = 'Last 7 Days';
    public static readonly Month: string = 'Last 30 Days';
    public static readonly BiMonth: string = 'Last 60 Days';
    public static readonly Quarter: string = 'Last 90 Days';
    public static readonly SemiAnnual: string = 'Last 6 Months';
    public static readonly Annual: string = 'Last Year';
}

abstract class Cookies {
    public static readonly Auth: string = '.AspNetCore.Cookies';
    public static readonly SsoAuthStatus: string = 'SsoAuthStatus';
    public static readonly AdminCookie = '05bcce6b-aed8-4f1a-a8ca-79a9b6dd12cb';
}

abstract class ApiUrls {
    public static readonly CustomerSearch: string = '/api/dataservice/customer/search';
    public static readonly UpdateProfileDetailed: string = '/api/updateprofiledetailed';
    public static readonly CustomerProfileDetailed: string = 'api/GetCustomerProfileDetailed';
    public static readonly GetAllMemberGroupCodes: string = '/api/getAllMemberGroupCodes';
    public static readonly GetStates: string = '/api/getstates';
    public static readonly GetAddresses: string = '/api/getAddresses';
    public static readonly GetPracticeTypes: string = '/api/getPracticeTypes';
    public static readonly GetAddressStructure: string = '/api/getaddressstructure';
    public static readonly GlobalNavigation = 'https://aaoscdndev01.azureedge.net/aaosepi-globalnav/globalNav.json';
    public static readonly GlobalNavigationSetting = '/api/appSettings';
    public static readonly GetProfileFormOptions: string = '/api/getProfileFormOptions';
    public static readonly SsoLogin = '/api/Authentication/login/';
    public static readonly CreateAccount = 'https://testebus.aaos.org/RegisterCustomer.aspx';
    public static readonly GetApplicationTypes = '/api/MemberApplicationType/GetMemberApplicationTypes';
    public static readonly GetMemberApplicationMappings = '/api/MemberApplicationType/GetMemberApplicationMappings';
    public static readonly AddMemberApplicationMapping = '/api/MemberApplicationType/AddMemberApplicationMapping';
    public static readonly DeleteMemberApplicationMapping = '/api/MemberApplicationType/DeleteMemberApplicationMapping';
    public static readonly ModifyMemberApplicationMapping = '/api/MemberApplicationType/ModifyMemberApplicationMapping';
    public static readonly GetAllMemberApplicationTypes = '/api/MemberApplicationType/GetAllMemberApplicationTypes';
    public static readonly GetDisabledMemberApplicationTypes = '/api/MemberApplicationType/GetDisabledMemberApplicationTypes';
    public static readonly GetApplicant = '/api/Applicant/GetApplicant';
    public static readonly GetApplicantPdf = '/api/Applicant/GetApplicant/pdf';
    public static readonly GetApplicationPdf = '/api/Application/GetApplication/pdf';
    public static readonly GetApplicationHtml = '/api/Application/GetApplication/html';
    public static readonly LogEvent = '/api/Application/logEvent';
    public static readonly DeleteApplication = '/api/Application/deleteApplication';
    public static readonly GetMemberFormListRequirement = '/api/Application/requirements';
    public static readonly GetQuestions = '/api/MemberApplicationType/GetMembershipQuestions/';
    public static readonly GetModuleList = '/api/getModuleList/';
    public static readonly GetModule = '/api/getModule/';
    public static readonly GetEmail = '/api/getPrimaryEmail';
    public static readonly GetPracticeInformation = '/api/getPracticeInformation';
    public static readonly GetAreasOfInterest = '/api/getAreasOfInterest';
    public static readonly GetCustomerProfileBase = '/api/getCustomerProfileBase';
    public static readonly GetRoles = 'api/getRoles';
    public static readonly GetSpecialization = '/api/getSpecialization';
    public static readonly UpdateApplication = '/api/Applicant/UpdateApplicationStatus';
    public static readonly GetTransitionSubspecialties = 'api/GetTransitionSubspecialties';
    public static readonly GetOrder = 'api/GetOrder';
    public static readonly GetOrderPrice = 'api/GetOrder/price/';
    public static readonly DoesCurrentPaidOrderExist = 'api/DoesCurrentPaidOrderExist';
    public static readonly ValidateDiscountCode = 'api/Applicant/ValidateDiscountCode';
    public static readonly NotifyApplication = '/api/Applicant/NotifyApplicant';
    public static readonly SaveFormState = '/api/FormState/SaveFormState';
    public static readonly LoadFormState = '/api/FormState/LoadFormState/';
    public static readonly CheckFormState = '/api/FormState/CheckFormState/';
    public static readonly GetApplicationForTest = 'api/FormState/GetApplicationForTest/';
    public static readonly LoadApplicationList = 'api/FormState/LoadApplicationList/';
    public static readonly GetSponsors = '/api/getSponsors';
    public static readonly GetDomesticMedicalSchools = '/api/GetDomesticMedicalSchools';
    public static readonly SubmitApplication = '/api/Applicant/SubmitApplication';
    public static readonly ValidateApplication = '/api/Applicant/ValidateApplication';
    public static readonly UpdateApplicationPersonify = '/api/UpdateMemberApplicationStatus';
    public static readonly NotifyUser = 'api/Applicant/NotifyApplicant';
    public static readonly UploadFiles = 'api/files';
    public static readonly GetFiles = 'api/files';
    public static readonly GetAlreadySentEmails = 'api/GetAlreadySentEmailRecords';
    public static readonly SendPaymentReminderEmails = 'api/SendEmailsToUnpaidSubmittedApplicants';
    public static readonly IsAuthenticated = '/api/authentication/isuserauthenticated';
    public static readonly GetContact = '/api/getContact';
    public static readonly SaveDiscountCode = '/api/Application/saveDiscountCode';
    public static readonly PreapprovalWorkflow = '/api/Application/preapprovalWorkflow';

    public static readonly SendLog = '/api/Logging/SendLog';
    public static readonly SendLogs = '/api/Logging/SendLogs';
}

abstract class ApiParams {
    public static readonly RedirectPath: string = 'redirectPath';
    public static readonly CountryCode: string = 'countryCode';
    public static readonly FirstName: string = 'firstName';
    public static readonly LastName: string = 'lastName';

}

abstract class AddressSubFieldNameConstants {
    public static readonly AddressLine1: string = 'Address1';
    public static readonly AddressLine2: string = 'Address2';
    public static readonly AddressLine3: string = 'Address3';
    public static readonly City: string = 'City';
    public static readonly State: string = 'State';
    public static readonly PostalCode: string = 'PostalCode';
    public static readonly AddressType: string = 'AddressType';
    public static readonly CountryCode: string = 'CountryCode';

}

abstract class YesNo {
    public static readonly Yes = 'yes';
    public static readonly No = 'no';
}

abstract class CountryConstants {
    public static readonly US = 'US';
    public static readonly UnitedStates = 'United States';
    public static readonly CA = 'CA';
    public static readonly Canada = 'Canada';
}

abstract class AppStatus {
    public static readonly IncompleteName: string = 'Incomplete';
    public static readonly DeniedName: string = 'Incomplete';
    public static readonly ApprovedName: string = 'Incomplete';
    public static readonly SubmittedName: string = 'Submitted';
    public static readonly DeferredName: string = 'Deferred';
    public static readonly InReviewName: string = 'InReview';
    public static readonly IncompleteId: string = '3A2578D9-1F16-4E10-9327-EF212BE6AA6A';
    public static readonly SubmittedId: string = '5DDB00B9-F28B-437F-A9F9-98C26B94AE54';
    public static readonly DeferredId: string = 'A9EEC592-B11A-4186-AD92-76D6161BDE23';
    public static readonly InReviewId: string = '35449B45-E0D0-4BF2-8584-45EB3377E4C2';
    public static readonly ApprovedId: string = '83502852-217D-4ACD-A99A-AF667020A2A0';
    public static readonly DeniedId: string = '1C52EC49-C0A8-4EC2-A8D3-8CA39C6549A3';
}

abstract class MemberApplicationTypes {
    public static readonly PhysicianAssistantId: string = '897EEF34-5552-451C-A147-041BB70AAD4E';
    public static readonly PhysicianAssistantDisplayName: string = 'Associate Member Physician Assistant';
    public static readonly AssocOrthoCandidateDisplayName: string = 'Associate Member Orthopaedic Candidate';
    public static readonly AssocOrthoCandidateId: string = '4F00A523-FC88-4669-A2D2-28B37579E357';
    public static readonly InternationalResidentId: string = '4149027C-7AE2-4D6F-AFCD-A94C977F7557';
    public static readonly InternationalResidentDisplayName: string = 'International Member - Resident';
    public static readonly InternationalMemberId: string = '6192F8B2-9A08-43BD-8F31-CEFE86614EE6';
    public static readonly InternationalMemberDisplayName: string = 'International Member - Orthopaedic Surgeon';
    public static readonly ActiveFellowId: string = 'A0DD1302-4311-47C7-82BA-D3CDD04D4679';
    public static readonly ActiveFellowDisplayName: string = 'Active Fellow';
    public static readonly AHMPhysicianId: string = '35D941E5-4EA0-4F08-833C-891C29F8FF01';
    public static readonly AHMPhysicianDisplayName: string = 'Allied Health Member - Physician';
    public static readonly AHMResearchId: string = 'A7CB00C1-90AE-49BC-8640-0B6DAB6530F2';
    public static readonly AHMResearchDisplayName: string = 'Allied Health Member - Orthopaedic Research';
    public static readonly AffiliateOrthoDisplayName: string = 'Affiliate - Orthopaedic Surgeon';
    public static readonly AffiliateOrthoId: string = '99866D03-564F-4F0A-ACE0-0CB502BF8D7A';
    public static readonly ActiveFellowOsteopathicDisplayName: string = 'Active Fellow Osteopathic';
    public static readonly ActiveFellowOsteopathicId: string = '3346B3A1-0C91-4B03-B4B7-3327408AD57F';
    public static readonly DomesticMedicalStudentDisplayName: string = 'Domestic Medical Student';
    public static readonly DomesticMedicalStudentId: string = 'f911d840-af7f-44a1-b2e9-5a05aa098ea2';
}

abstract class ApplicationResourceValues {
    public static readonly InternationalMember: string = '5,700';
    public static readonly InternationalResident: string = '4,000';
}

abstract class MemberTypes {
    public static readonly AMPhysicianAssistantMemberTypeId = 'A3EDC4D0-8C30-450A-9C17-120A349C1D49';
    public static readonly AssocOrthoCandidateMemberTypeId = 'ACF9DBFA-1014-493C-BF07-E68B1B52BABB';
    public static readonly AMInternationalResidentMemberTypeId = '22AA19F1-F481-4DE9-AC71-CCB7CA83D8F9';
    public static readonly InternationalMemberMemberTypeId = 'AFAEFF5C-D193-4F71-B021-AF8B0B16C05F';
    public static readonly ActiveFellowMemberTypeId = 'A65C5633-25A5-489B-A68B-89A71BFB3BE3';
    public static readonly AHMPhysicianMemberTypeId = '762A9479-2E44-4614-8268-5D05AFE99FE7';
    public static readonly AHMResearcherMemberTypeId = '9FF6015D-1030-416B-9670-BB5662272BB2';
    public static readonly AffiliateOrthoMemberTypeId = 'A8CD29D2-97AF-45DB-9254-31231CDB4F7C';
    public static readonly AssociateOsteopathicMemberTypeId = 'CD4E0826-C1B3-4FB6-875F-1BF8377235C2';
}

abstract class FileUploadTypes {
    public static readonly AcceptanceLetter = '1E8D2EE4-2B9F-46E2-9C53-49A9F46AA295';
    public static readonly LetterOfRecommendation = 'D39E72EF-C42D-416F-BE1C-D1966283CF3F';
    public static readonly CV = 'FA5552C0-F772-4CBF-AE6A-A58DFFDB6CC2';
    public static readonly AdditionalFile = '41807FF2-FB99-499F-A2B2-9BBB8984DC03';
    public static readonly NationalPractitioner = 'F48B24A9-4AA3-4259-860B-1F8F5CD24C8D';
}

abstract class ControlledType {
    public static readonly Yes = 'yesEnabled';
    public static readonly No = 'noEnabled';
}

abstract class UserProfile {
    public static readonly NPIPrefixTotal_USA: number = 24;

}

export const Constants = Object.freeze({
    ApiUrls,
    ApiParams,
    LogLevel,
    Observables,
    DateFilterTypes,
    AddressSubFieldNameConstants,
    Cookies,
    UserProfileFormOptions,
    AppStatus,
    CountryConstants,
    FormModuleTitle,
    FormFieldGroupTitle,
    YesNo,
    MemberApplicationTypes,
    ApplicationResourceValues,
    MemberTypes,
    FileUploadTypes,
    UserProfile,
    ControlledType
});
