import { Component, OnInit } from '@angular/core';
import { APP_CONFIG } from '@config/main';
import { ApplicantService } from '@shared/services/forms/applicant.service';

@Component({
  selector: 'app-application-status',
  templateUrl: './application-status.component.html',
  styleUrls: ['./application-status.component.scss']
})
export class ApplicationStatusComponent implements OnInit {
  // loads
  loadingPaidOrder = true;
  loadingApplicant = true;
  paymentUrl: string;

  applicant: any;
  doesCurrentPaidOrderExist: string; // hide by default until loading is done

  // stepper was grabbed from here: https://github.com/Johann-S/bs-stepper/tree/v1.7.0

  constructor(private applicantService: ApplicantService) { }

  ngOnInit() {
    this.GetApplicantInfo();
    APP_CONFIG.subscribe(_ => {
      this.paymentUrl = _.paymentUrl;
    });
  }

  GetApplicantInfo() {
    this.applicantService.getApplicant().subscribe((applicant: any) => {
      this.applicant = applicant;
      this.loadingApplicant = false;
    });

    this.applicantService.doesCurrentPaidOrderExist().subscribe(doesCurrentPaidOrderExist => {
      this.doesCurrentPaidOrderExist = doesCurrentPaidOrderExist.toString();
      this.loadingPaidOrder = false;
    });
  }

}
