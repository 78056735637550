import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseFieldComponentDirective } from '@shared/form-fields/base-field-component';
import { Constants } from '@shared/services/constants/constants';

@Component({
  selector: 'app-yes-no',
  templateUrl: './yes-no.component.html',
  styleUrls: ['./yes-no.component.scss']
})
export class YesNoComponent extends BaseFieldComponentDirective implements OnInit {

  @Output() yesNoSelected = new EventEmitter();
  @Output() enableItem = new EventEmitter();
  @Output() disableItem = new EventEmitter();
  yesNoBaseFormGroup = new FormGroup({});
  @Input() dynamicForm: any;
  @Input() fieldsPrerequisite: any;
  secretNoMessage: string = '';
  partialString: string[];

  constructor() {
    super();

  }

  ngOnInit(): void {
    this.formatSecretNoMessage();
    this.setChildrenFromSavedValue();
    this.buildFormGroup();
    this.populateFormGroup();
    this.validateFormGroup();
  }

  private formatSecretNoMessage() {
    if (this.field.name.includes('NoMessage:')) {
      this.partialString = this.field.name.split('NoMessage:');
      const beginningOfNoMessage = this.partialString[0].indexOf(':');
      this.secretNoMessage = this.partialString[1].substring(beginningOfNoMessage);
    }
  }

  populateFormGroup() {
    this.baseFormGroup.get(this.field.name)?.setValue(this.field.value?.toLowerCase());
    this.validateFormGroup();

  }

  YesNoClick($event: any) {
    this.yesNoSelected.emit($event);
  }

  enableDisableChildren($event: boolean) {
    if (this.field !== null && this.field !== undefined) {
      if ($event) {
        this.enableItem.emit(this.field);
      } else {
        this.disableItem.emit(this.field);
      }
    }
  }

  setChildrenFromSavedValue() {
    if (this.field && this.field.value) {
      if (this.field.value === Constants.YesNo.Yes) {
        this.enableDisableChildren(true);
      } else {
        this.enableDisableChildren(false);
      }
    }
  }

  getDisplayText() {
    if (this.field !== null && this.field !== undefined && this.field.displayName !== null && this.field.displayName !== undefined) {
      return this.field.displayName;
    }
    return this.displayText;
  }
}
