<div [formGroup]="formGroup" class="form-group" *ngIf="formGroup">
    Sponsors must be AAOS ACTIVE FELLOWS in your community. Please enter the last name of your sponsor, and click
    "Search Sponsor". Select your sponsor's name and click "Add Sponsor" to add them. Advise those listed you have named
    them as sponsors and have secured their permission.
    <br />
    <br />
    <div *ngFor="let sponsor of sponsors; index as i">
        <app-aaos-sponsor-item [sponsor]="sponsor" [index]="i"></app-aaos-sponsor-item>
    </div>
    <div class="required-text" *ngIf="memberFormListRequirement && sponsors.length<memberFormListRequirement.required">
        At least {{amountRequired}} sponsor(s) are required
    </div>
    <hr>

    <div class="title title--x">First Name (Optional)</div>
    <input matInput class="form-control" formControlName="{{firstNameControl}}" type="text" />
    <div class="title title--x">Last Name </div>
    <input matInput class="form-control" formControlName="{{lastNameControl}}" [required]="memberFormListRequirement && sponsors.length<memberFormListRequirement.required" type="text" />

    <div class="required-text" *ngIf="memberFormListRequirement && sponsors.length<memberFormListRequirement.required && invalidRequiredField(lastNameControl)">
        Last name is required
    </div>
</div>
<div>
    <button class="btn btn--solid" [ngClass]="{'btn--white': (memberFormListRequirement && sponsors.length<memberFormListRequirement.required && invalidRequiredField(lastNameControl)) === false}" [disabled]="memberFormListRequirement && sponsors.length<memberFormListRequirement.required && invalidRequiredField(lastNameControl)" (click)="searchSponsor()">SEARCH SPONSOR</button>
</div>
<app-aaos-spinner [isLoading]="loading"></app-aaos-spinner>