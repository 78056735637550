<div *ngIf="groups">
  <div *ngFor="let group of groups">
    <h2>
      {{group.displayName}}
    </h2>
    <div *ngFor="let field of group?.formFields">

      <div [ngSwitch]="field.fieldType.name">

        <app-yes-no (componentChanged)="onComponentChange($event)" [fieldsPrerequisite]="fieldsPrerequisite"
          [dynamicForm]="dynamicForm" (disableItem)="noClicked($event)" (enableItem)="yesClicked($event)"
          [formOptions]="formOptions" [field]="field" *ngSwitchCase="'yes-no'">
        </app-yes-no>

        <app-aaos-text-field [fieldsPrerequisite]="fieldsPrerequisite" [dynamicForm]="dynamicForm"
          (componentChanged)="onComponentChange($event)" [formOptions]="formOptions" [field]="field"
          *ngSwitchCase="'text-field'">
        </app-aaos-text-field>

        <app-aaos-check-box [dynamicForm]="dynamicForm" [fieldsPrerequisite]="fieldsPrerequisite"
          [formOptions]="formOptions" (componentChanged)="onComponentChange($event)" [field]="field"
          *ngSwitchCase="'check-box'">
        </app-aaos-check-box>

        <app-aaos-npi-form-field [dynamicForm]="dynamicForm" (componentChanged)="onComponentChange($event)"
          [fieldsPrerequisite]="fieldsPrerequisite" [formOptions]="formOptions" [field]="field" *ngSwitchCase="'npi'">
        </app-aaos-npi-form-field>

        <app-aaos-drop-down [dynamicForm]="dynamicForm" [fieldsPrerequisite]="fieldsPrerequisite"
          [formOptions]="formOptions" (componentChanged)="onComponentChange($event)" [field]="field"
          *ngSwitchCase="'drop-down'">
        </app-aaos-drop-down>

        <app-state-drop-down [dynamicForm]="dynamicForm" [fieldsPrerequisite]="fieldsPrerequisite" [form]="form"
          (componentChanged)="onComponentChange($event)" [field]="field" *ngSwitchCase="'state-drop-down'">
        </app-state-drop-down>

        <app-aaos-radio-button [active]="active" [fieldsPrerequisite]="fieldsPrerequisite" [dynamicForm]="dynamicForm"
          (componentChanged)="onComponentChange($event)" [field]="field" *ngSwitchCase="'radio-button'">
        </app-aaos-radio-button>

        <app-text-area [dynamicForm]="dynamicForm" [active]="active" [fieldsPrerequisite]="fieldsPrerequisite"
          [form]="form" (componentChanged)="onComponentChange($event)" [field]="field" *ngSwitchCase="'text-area'">
        </app-text-area>

        <app-text-input [fieldsPrerequisite]="fieldsPrerequisite" [dynamicForm]="dynamicForm" [form]="form"
          (componentChanged)="onComponentChange($event)" [field]="field" *ngSwitchCase="'text-input'">
        </app-text-input>

        <app-amp-iframe (componentChanged)="onComponentChange($event)" [fieldsPrerequisite]="fieldsPrerequisite"
          *ngSwitchCase="'iframe'">
        </app-amp-iframe>

        <app-aaos-address-list [dynamicForm]="dynamicForm" [field]="field" [formOptions]="formOptions"
          [addressDetails]="addressDetails" [fieldsPrerequisite]="fieldsPrerequisite"
          (componentChanged)="onComponentChange($event)" *ngSwitchCase="'address'">
        </app-aaos-address-list>

        <app-aaos-education-institution-list [dynamicForm]="dynamicForm" [field]="field"
          [applicationCountries]="applicationCountries" [institutionDetails]="institutionDetails"
          (componentChanged)="onComponentChange($event)" [fieldsPrerequisite]="fieldsPrerequisite"
          *ngSwitchCase="'education-institution'">
        </app-aaos-education-institution-list>

        <app-aaos-fellowship-program-list [dynamicForm]="dynamicForm" [field]="field" [formOptions]="formOptions"
          [applicationCountries]="applicationCountries" [fellowshipProgramDetails]="fellowshipProgramDetails"
          (componentChanged)="onComponentChange($event)" [fieldsPrerequisite]="fieldsPrerequisite"
          *ngSwitchCase="'fellowship-program'">
        </app-aaos-fellowship-program-list>

        <app-hospital-privilege-list [dynamicForm]="dynamicForm" [field]="field"
          [hospitalPrivilegeDetails]="hospitalPrivilegeDetails" (componentChanged)="onComponentChange($event)"
          [fieldsPrerequisite]="fieldsPrerequisite" *ngSwitchCase="'hospital-privilege'"></app-hospital-privilege-list>

        <app-aaos-residency-program-list [dynamicForm]="dynamicForm" [field]="field" [formOptions]="formOptions"
          [applicationCountries]="applicationCountries" [residencyProgramDetails]="residencyProgramDetails"
          (componentChanged)="onComponentChange($event)" [fieldsPrerequisite]="fieldsPrerequisite" 
          *ngSwitchCase="'residency-program'">
        </app-aaos-residency-program-list>

        <app-aaos-practice-history-list [dynamicForm]="dynamicForm" [field]="field"
          [fellowshipProgramDetails]="fellowshipProgramDetails" (componentChanged)="onComponentChange($event)"
          [fieldsPrerequisite]="fieldsPrerequisite" *ngSwitchCase="'practice-history'">
        </app-aaos-practice-history-list>

        <app-date (componentChanged)="onComponentChange($event)" [active]="active"
          [fieldsPrerequisite]="fieldsPrerequisite" [dynamicForm]="dynamicForm" [field]="field" *ngSwitchCase="'date'">
        </app-date>

        <app-aaos-label [field]="field" *ngSwitchCase="'label'"></app-aaos-label>

        <app-aaos-sponsors [dynamicForm]="dynamicForm" [fieldsPrerequisite]="fieldsPrerequisite"
          (componentChanged)="onComponentChange($event)" [field]="field" *ngSwitchCase="'sponsor'">
        </app-aaos-sponsors>

        <app-file-upload-info [dynamicForm]="dynamicForm" [fieldsPrerequisite]="fieldsPrerequisite" [field]="field"
          *ngSwitchCase="'file-upload-info'">
        </app-file-upload-info>

        <app-file-upload [dynamicForm]="dynamicForm" [active]="active" [fieldsPrerequisite]="fieldsPrerequisite"
          [field]="field" [fieldNumber]="i" [formFields]="group?.formFields" *ngSwitchCase="'file-upload'">
        </app-file-upload>

        <app-certification [active]="active" (componentChanged)="onComponentChange($event)" [dynamicForm]="dynamicForm"
          [applicationCountries]="applicationCountries" [fieldsPrerequisite]="fieldsPrerequisite" [field]="field"
          *ngSwitchCase="'certification'"></app-certification>

        <app-aaos-licensure-list [dynamicForm]="dynamicForm" [field]="field" [licensureDetails]="licensureDetails"
          (componentChanged)="onComponentChange($event)" [fieldsPrerequisite]="fieldsPrerequisite"
          *ngSwitchCase="'licensure'">
        </app-aaos-licensure-list>

        <app-pgy [dynamicForm]="dynamicForm" [field]="field" [fieldsPrerequisite]="fieldsPrerequisite" 
          (componentChanged)="onComponentChange($event)" *ngSwitchCase="'pgy'"></app-pgy>

        <app-aaos-medical-student-address [dynamicForm]="dynamicForm" [field]="field"
          (componentChanged)=" onComponentChange($event)" [applicationCountries]="applicationCountries" [addressDetails]="addressDetails" [fieldsPrerequisite]="fieldsPrerequisite"
          *ngSwitchCase="'student-address'">
        </app-aaos-medical-student-address>
      </div>
    </div>
  </div>
</div>
