import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { FormField } from '@shared/form-fields/models/form-field';
import { FieldsPrerequisite } from '@shared/models/data/fieldsPrerequisite';
import { PracticeHistoryDetails } from '@shared/models/data/PracticeHistoryDetails';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-aaos-practice-history-list',
  templateUrl: './practice-history-list.component.html',
  styleUrls: ['./practice-history-list.component.scss']
})
export class PracticeHistoryListComponent implements OnInit {
  @Output() componentChanged = new EventEmitter();

  @Input() dynamicForm: FormGroup;
  @Input() field: FormField;
  @Input() fieldsPrerequisite: FieldsPrerequisite;
  changeInPHList: string;
  phFormGroup: FormGroup;
  lastId = 0;
  practiceHistoryDetails: PracticeHistoryDetails[];
  selectedCurrentPractice = -1;
  allLoaded: Subject<any> = new Subject<any>();

  constructor() { }

  ngOnInit() {
    this.phFormGroup = this.dynamicForm.get(this.fieldsPrerequisite.formModuleTitle) as FormGroup;
    this.setPracticeHistoryList();
    this.validateFormGroup();
  }

  public setPracticeHistoryList() {
    this.practiceHistoryDetails = new Array<PracticeHistoryDetails>();
    if (this.field.value) {
      const parsedJson: Array<PracticeHistoryDetails> = JSON.parse(this.field.value);
      this.practiceHistoryDetails = parsedJson;

      const idList = this.practiceHistoryDetails.map(x => x.id);
      this.lastId = Math.max.apply(Math, idList.map(function (o) { return o; })) + 1;
    } else {
      this.addPracticeHistory();
    }
    this.allLoaded.next();
  }

  public addPracticeHistory() {
    const additionalPractice = new PracticeHistoryDetails();
    additionalPractice.id = this.lastId;
    this.lastId++;
    this.practiceHistoryDetails.push(additionalPractice);
    this.validateFormGroup();
  }

  deletePracticeHistory(id: number) {
    this.practiceHistoryDetails = this.practiceHistoryDetails.filter(x => x.id !== id);
    this.changeInPHList = id.toString();
    this.phFormGroup.removeControl(id.toString());
    this.validateFormGroup();
    this.onComponentChange(null);
  }

  currentPracticeSelected(event: any) {
    this.selectedCurrentPractice = event;
  }

  onComponentChange(event: any) {
    if (event !== null) {
      this.changeInPHList = event.id.toString() + event.isCurrentPractice;
      const i = this.practiceHistoryDetails.findIndex(x => x.id === event.id);
      if (i !== -1) {
        this.practiceHistoryDetails[i].name = event.name;
        this.practiceHistoryDetails[i].city = event.city;
        this.practiceHistoryDetails[i].state = event.state;
        this.practiceHistoryDetails[i].employmentSetting = event.employmentSetting;
        this.practiceHistoryDetails[i].employmentSettingOptional = event.employmentSettingOptional;
        this.practiceHistoryDetails[i].startDate = event.startDate;
        this.practiceHistoryDetails[i].endDate = event.endDate;
        this.practiceHistoryDetails[i].isCurrentPractice = event.isCurrentPractice;
      }
    }
    this.validateFormGroup();
    this.field.value = JSON.stringify(this.practiceHistoryDetails);
    this.componentChanged.emit(this.field);
  }

  validateFormGroup() {
    if (this.practiceHistoryDetails == null || this.practiceHistoryDetails.length < 1) {
      this.dynamicForm.get(this.fieldsPrerequisite.formModuleTitle).setErrors(Validators.required);
    } else {
      this.dynamicForm.get(this.fieldsPrerequisite.formModuleTitle).setErrors(null);
    }
  }
}
