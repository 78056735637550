import { Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-aaos-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {
  @Input() isLoading$: BehaviorSubject<boolean>;
  @Input() isLoading: boolean;
  @Input() displayInline: boolean;
  @Input() spinnerCaption: string;
}
