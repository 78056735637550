import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { APP_CONFIG } from '@config/main';
import { Constants } from '@shared/services/constants/constants';
import { SessionService } from '@shared/services/session.service';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate, CanActivateChild {

  constructor(
    @Inject(DOCUMENT) private document: any,
    private sessionService: SessionService,
  ) { }

  canActivateChild(r: ActivatedRouteSnapshot, s: RouterStateSnapshot) {
    return this.canActivate(r, s);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isAdmin = this.sessionService.checkAdminCookie();

    if (!isAdmin && !route.data.ignoreRedirect) {
      const pathParam = Constants.ApiParams.RedirectPath;
      let pathName = window.location.pathname;
      if (state != null && state.url != null) {
        pathName = state.url;
      }

      APP_CONFIG.subscribe(config => {
        const queryString = encodeURI(`${pathParam}=${pathName}`);
        const path = `${config.authRedirectUrl}?${queryString}`;
        window.location.href = path;
      });
    }
    return of(isAdmin);
  }
}
