import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpHeaders, HttpClient } from '@angular/common/http';
import { ServerError, Log } from '@shared/models/data/index';
import { LogLevel } from '@shared/models/enums/index';
import { LoggingService } from './logging.service';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AaosBaseService {
  constructor(protected logging: LoggingService, protected http: HttpClient) { }

  protected handleError(err: HttpErrorResponse): Observable<ServerError> {
    const message =
      err.error instanceof Error
        ? `A network or client-side error occured: ${err.error.message}`
        : `API server returned error code ${err.status}, body of error was: ${err.message}`;

    const error: ServerError = new ServerError();
    error.message = message;
    error.status = err.status;

    const log = {
      Exception: '',
      Message: message,
      LogLevel: LogLevel.Error
    } as Log;
    this.logging.addLogToQueue(log);
    return throwError(error);
  }

}
