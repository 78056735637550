import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AaosBaseService } from '@shared/services/aaos-base.service';
import { map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { FormModule } from '@shared/models/data/form-module.model';
import { Constants } from '@shared/services/constants/constants';
import { MemberApplicationType } from '@shared/models/serialization/member-application-type.model';
import { FormModuleAdapter } from '@shared/models/data/form-module.model';
import { LoggingService } from '@shared/services/logging.service';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ModuleService extends AaosBaseService {

  constructor(
    protected logging: LoggingService,
    protected http: HttpClient,
    protected formModuleAdapter: FormModuleAdapter) {
    super(logging, http);
  }

  getModule(membershipApplicationType: string, moduleTitle: string): Observable<FormModule> {
    const url = Constants.ApiUrls.GetModule + membershipApplicationType + '/' + moduleTitle;
    const memberGroupFieldResponse = this.http.get(url)
      .pipe(
        map((response: any) => {
          if ((!response)) {
            const error = response.error;
            throw new Error();
          }
          return response;
        })
      );
    return memberGroupFieldResponse;
  }

  getModuleList(applicationType: string): Observable<Array<FormModule>> {

    const moduleListResponse = this.http.get(Constants.ApiUrls.GetModuleList + applicationType)
      .pipe(
        map((response: any) => {
          if ((!response)) {
            const error = response.error;
            throw new Error();
          }
          return response;
        })
      );
    return moduleListResponse;
  }

}
