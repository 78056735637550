<header>
  <div class="container">
    <div class="row">
      <div class="col-10 aaos--row">
        <div class="navbutton-placeholder"></div>
        <a class="logo aaos-logo" href="https://www.aaos.org/" target="_blank">
          <img src="https://aaoscdndev01.azureedge.net/aaosepi-globalnav/images/aaos-logo.svg" />
        </a>
      </div>
      <div class="col-2">
        <div class="aaos--row aaos--flex-end">

          <div class="navigation--account">
            <div class="aaos--links desktop--links">
              <ul>
                <li>
                  <a [routerLink]="" (click)="this.openDialog()">Terms of Use</a>
                </li>
                <li>
                  <a href="../api/authentication/signout" class="btn btn--outline btn--small" *ngIf="isLoggedIn" style="padding-right: 15px !important">LOGOUT</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
