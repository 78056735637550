import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LicensureListComponent } from '@shared/components/licensure-list/licensure-list.component';
import { Preference } from '@shared/models/data';
import { FieldsPrerequisite } from '@shared/models/data/fieldsPrerequisite';
import { LicensureDetail } from '@shared/models/data/LicensureDetail';
import { FormValidatorService } from '@shared/services/forms/form-validator.service';
import { UserProfileService } from '@shared/services/user-profile.service';

@Component({
  selector: 'app-aaos-licensure',
  templateUrl: './licensure.component.html',
  styleUrls: ['./licensure.component.scss']
})
export class LicensureComponent implements OnInit {
  @Input() fieldsPrerequisite: FieldsPrerequisite;
  @Input() licensureDetail: LicensureDetail;
  @Input() dynamicForm: FormGroup;
  @Input() index;
  @Output() componentChanged = new EventEmitter();
  licenseIndex: string;

  updatedLicense = new LicensureDetail();
  baseFormGroup = new FormGroup({});
  loading = true;
  states: Preference[] = [];
  statesLoaded = false;

  constructor(private userProfileService: UserProfileService,
    private fellowshipProgramList: LicensureListComponent) { }

  ngOnInit() {
    this.addControlsToFormGroup();
    this.validateFormGroup();
    this.getAndSetAddress();
  }

  isDeletable() {
    return this.licensureDetail.id !== 0;
  }

  deleteLicensure(event: any) {
    this.fellowshipProgramList.deleteLicense(this.licensureDetail.id);
  }

  detectInputChange() {
    this.updatedLicense.id = this.licensureDetail.id;
    this.updatedLicense.licenseNumber = this.baseFormGroup.get('licenseNumber').value;
    this.updatedLicense.state = this.baseFormGroup.get('state').value;
    this.updatedLicense.issuedDate = this.baseFormGroup.get('issuedDate').value;
    this.updatedLicense.expireDate = this.baseFormGroup.get('expireDate').value;
    this.validateFormGroup();
    this.componentChanged.emit(this.updatedLicense);
  }

  invalidRequiredField(fieldName: string): boolean {
    if (this.baseFormGroup.get(fieldName).hasError('required')) {
      return true;
    }
    return false;
  }

  validateFormGroup() {
    if (this.baseFormGroup.invalid) {
      this.dynamicForm.get(this.fieldsPrerequisite.formModuleTitle).setErrors(Validators.required);
    } else {
      this.dynamicForm.get(this.fieldsPrerequisite.formModuleTitle).setErrors(null);
    }
  }

  invalidDateField(): boolean {
    if (!this.invalidRequiredField('issuedDate') && !this.invalidRequiredField('expireDate') && 
    this.baseFormGroup.hasError('before')) 
    {
      return true;
    }
    return false;
  }

  getAndSetAddress() {
    this.userProfileService.getStates(this.fieldsPrerequisite.countryCode).subscribe(
      latest => {
        const states = latest;
        this.states = states;
        this.statesLoaded = true;
        this.populateFormControl();
        this.validateFormGroup();
        this.loading = false;
      },
      error => {
        this.loading = false;
        alert('There was an error in loading the form. Please refresh the page.');
      });
  }

  addControlsToFormGroup() {
    this.baseFormGroup.addControl('licenseNumber', new FormControl(null, Validators.required));
    this.baseFormGroup.addControl('state', new FormControl(null, Validators.required));
    this.baseFormGroup.addControl('issuedDate', new FormControl(null, Validators.required));
    this.baseFormGroup.addControl('expireDate', new FormControl(null, Validators.required));
    this.baseFormGroup.setValidators([FormValidatorService.getDateValidator('issuedDate', 'expireDate')])

  }

  populateFormControl() {
    this.baseFormGroup.get('licenseNumber').setValue(this.licensureDetail.licenseNumber);
    this.baseFormGroup.get('state').setValue(this.licensureDetail.state);
    this.baseFormGroup.get('issuedDate').setValue(this.licensureDetail.issuedDate);
    this.baseFormGroup.get('expireDate').setValue(this.licensureDetail.expireDate);
  }
}
