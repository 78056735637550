import { Component, OnInit} from '@angular/core';
import { FormField, FormFieldConstants } from '@shared/form-fields/models/form-field';
import { NavService } from '@shared/services/nav.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private navService: NavService) {}

  account: FormField = new FormField();
  hasAccount: boolean;
  hasAnswered: boolean;

  ngOnInit(): void {
    this.account.name = 'Do you have an AAOS.org account?';
    this.account.additionalValues = FormFieldConstants.Questionnaire;
    this.hasAnswered = false;
  }

  selection(hasAccount: boolean) {
    this.hasAccount = hasAccount;
    this.hasAnswered = true;
  }

  continue() {
    if (this.hasAccount) {
      this.navService.loginToAaos();
    } else {
      this.navService.createAaosAccount();
    }
  }

}
