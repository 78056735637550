import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { FormField } from '@shared/form-fields/models/form-field';
import { Preference, ProfileFormOption } from '@shared/models/data';
import { FieldsPrerequisite } from '@shared/models/data/fieldsPrerequisite';
import { ResidencyProgramDetail } from '@shared/models/data/ResidencyProgramDetail';

@Component({
  selector: 'app-aaos-residency-program-list',
  templateUrl: './residency-program-list.component.html',
  styleUrls: ['./residency-program-list.component.scss']
})
export class ResidencyProgramListComponent implements OnInit {
  @Output() componentChanged = new EventEmitter();

  @Input() dynamicForm: FormGroup;
  @Input() field: FormField;
  @Input() fieldsPrerequisite: FieldsPrerequisite;
  @Input() applicationCountries: Preference[];
  @Input() formOptions : ProfileFormOption[];

  lastId = 0;
  residencyProgramDetails: ResidencyProgramDetail[];

  constructor() { }

  ngOnInit() {
    this.setInstitutionList();
    this.validateFormGroup();
  }

  public setInstitutionList() {
    this.residencyProgramDetails = new Array<ResidencyProgramDetail>();
    if (this.field.value) {
      const parsedJson: Array<ResidencyProgramDetail> = JSON.parse(this.field.value);
      this.residencyProgramDetails = parsedJson;

      const idList = this.residencyProgramDetails.map(x => x.id);
      this.lastId = Math.max.apply(Math, idList.map(function(o) { return o; })) + 1;
    } else {
      this.addInstitution();
    }
  }

  public addInstitution() {
    const additionalInstitution = new ResidencyProgramDetail();
    additionalInstitution.id = this.lastId;
    this.lastId++;
    this.residencyProgramDetails.push(additionalInstitution);
  }

  deleteInstitution(id: number) {
    this.residencyProgramDetails = this.residencyProgramDetails.filter(x => x.id !== id);
    this.onComponentChange(null);
    this.validateFormGroup();
  }

  onComponentChange(event: any) {
    if (event !== null) {
      const i = this.residencyProgramDetails.findIndex(x => x.id === event.id);
      if (i !== -1) {
        this.residencyProgramDetails[i].name = event.name;
        this.residencyProgramDetails[i].country = event.country;
        this.residencyProgramDetails[i].city = event.city;
        this.residencyProgramDetails[i].state = event.state;
        this.residencyProgramDetails[i].startDate = event.startDate;
        this.residencyProgramDetails[i].endDate = event.endDate;
      }
    }

    this.field.value = JSON.stringify(this.residencyProgramDetails);
    this.componentChanged.emit(this.field);
  }

  validateFormGroup() {
    if (this.residencyProgramDetails == null || this.residencyProgramDetails.length < 1) {
      this.dynamicForm.get(this.fieldsPrerequisite.formModuleTitle).setErrors(Validators.required);
    } else {
      this.dynamicForm.get(this.fieldsPrerequisite.formModuleTitle).setErrors(null);
    }
  }
}
