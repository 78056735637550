
<div class="form-group"  [formGroup]="baseFormGroup" #pgyInfo [appDisable]="field.fieldIsDisabled">
  <b> {{field.displayName}}</b>
  <div class="title title--xs">Medical School Year Begin Date</div>
  <div class="dateContainer">
    <div (click)="pgyBeginDatePicker.open()" class="" 
      [ngClass]="{'input': field.fieldIsDisabled, 'date--form-control half-width': !field.fieldIsDisabled}">
      <input [matDatepicker]="pgyBeginDatePicker" (blur)="detectInputChange($event)" formControlName="pgyBeginDate"
        required="!field.fieldIsDisabled" disabled="true" class="form-control" [ngClass]="{'half-width': field.fieldIsDisabled}">
    </div>
    <mat-datepicker-toggle [for]="pgyBeginDatePicker"></mat-datepicker-toggle>
    <mat-datepicker [disabled]="field.fieldIsDisabled" (closed)="detectInputChange($event)" #pgyBeginDatePicker ngDefaultControl>
    </mat-datepicker>
    <div class="required-text" *ngIf="invalidRequiredField('pgyBeginDate') || invalidDateField()" >Begin Date is required and must be
      precede the End Date
    </div>

    <div class="title title--xs">Medical School Year End Date</div>
    <div class="dateContainer">
      <div (click)="pgyEndDatePicker.open()" class="" 
        [ngClass]="{'input': field.fieldIsDisabled, 'date--form-control half-width': !field.fieldIsDisabled}">
        <input [matDatepicker]="pgyEndDatePicker" formControlName="pgyEndDate"
          required="!field.fieldIsDisabled" disabled="true" class="form-control"
          [ngClass]="{'half-width': field.fieldIsDisabled}">
      </div>
      <mat-datepicker-toggle [for]="pgyEndDatePicker"></mat-datepicker-toggle>
      <mat-datepicker [disabled]="field.fieldIsDisabled" #pgyEndDatePicker ngDefaultControl
        (closed)="detectInputChange($event)">
      </mat-datepicker>
      <div class="required-text" *ngIf="invalidRequiredField('pgyEndDate')">End Date is required</div>
    </div>


    <div class="title title--xs">Current year in school</div>
    <div class="half-width"> 
        <mat-select formControlName="yearInSchool"  [disabled]="field.fieldIsDisabled"  [(value)]="selectedValue" disableRipple panelClass="mat-panel" (closed)="detectInputChange($event)">
            <mat-option *ngFor="let option of schoolYearOptions" [value]="option.preferenceCode">{{ option.preferenceDescription }}
            </mat-option>
        </mat-select>
      </div>

    <div class="required-text" *ngIf="invalidRequiredField('yearInSchool') && !selectedValue && !pgyInfo.yearInSchool">
        Year in school is required
    </div>
  </div>
</div>
  <app-aaos-spinner [isLoading]="loading"></app-aaos-spinner>