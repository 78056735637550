import { Inject, Injectable } from '@angular/core';
import { Adapter } from '@shared/services/utils/adapter';
import { map } from 'rxjs/operators';
import { FormField, FormFieldAdapter } from './form-field';

export class FormFieldGroup {

    public name: string;
    public formFields: FormField[];
    public version?: number;
    public sortOrder?: number;
    public toolTipText?: string;
    public toolTipTextDetail?: string;
    public displayName: string;
    public id: string;
}

@Injectable({
  providedIn: 'root'
})
export class FormFieldGroupAdapter implements Adapter<FormFieldGroup> {

  constructor(private formAdapter: FormFieldAdapter) {
  }

  adapt(item: any): FormFieldGroup {
    const group: FormFieldGroup = {
      name: item.Name,
      formFields: item.FormFields.map((i: any) => this.formAdapter.adapt(i)),
      version: item.Version,
      sortOrder: item.SortOrder,
      toolTipText: item.ToolTipText,
      toolTipTextDetail: item.ToolTipTextDetail,
      displayName: item.DisplayName,
      id: item.Id
    };
      return group;
  }
}
