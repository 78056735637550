<div class="required-text" *ngIf="fellowshipProgramDetails.length < 1">
  At least one fellowship program is required
</div>
<div class="spacing">
  <div *ngFor="let fellowshipProgram of fellowshipProgramDetails; index as i">
    <app-aaos-fellowship-program [applicationCountries]="applicationCountries" class="spacing" [index]="i"
      [dynamicForm]="dynamicForm" [fellowshipProgramDetail]="fellowshipProgram" [formOptions]="formOptions"
      (componentChanged)="onComponentChange($event)" [fieldsPrerequisite]="fieldsPrerequisite">
    </app-aaos-fellowship-program>
  </div>
</div>
<button class="btn btn--solid" (click)="addInstitution()">ADD FELLOWSHIP</button>