import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, FormBuilder, ValidatorFn, ValidationErrors } from '@angular/forms';
import { FormField} from '@shared/form-fields/models/form-field';
import { FormFieldGroup } from '@shared/form-fields/models/form-field-group';

export class FormConfiguration {
  groups: FormFieldGroup;
  formGroup: FormGroup;
}

@Injectable({
  providedIn: 'root'
})
export class FormBuilderService {

  constructor(private formBuilder: FormBuilder) { }

  public setUpForm(data: Array<FormField>): FormConfiguration {

    const config = new FormConfiguration();
    const formGroup = new FormGroup( { } );
    if (data) {
      for (const field of data ) {
        const newControl = this.buildControl(field, formGroup);
        formGroup.addControl(field.name, newControl);
      }
    }
      config.formGroup = formGroup;
      return config;
  }

  private buildControl(field: FormField, form: FormGroup): AbstractControl {
    if (field === null || field === undefined) {
      return null;
    }
    if (field.value !== null && field.value !== undefined) {
      return new FormControl(field.value, Validators.required);
    }

    const item = {
      value: null, // do not remove this line, if value is not set in the field state for some reason it ignores the disabled flag
      disabled: false,
      field: field
    };
    return new FormControl(item, Validators.required);
  }

}

