import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PracticeHistoryListComponent } from '@shared/components/practice-history-list/practice-history-list.component';
import { FieldsPrerequisite } from '@shared/models/data/fieldsPrerequisite';
import { PracticeHistoryDetails } from '@shared/models/data/PracticeHistoryDetails';
import { Preference } from '@shared/models/data/Preference';
import { FormValidatorService } from '@shared/services/forms/form-validator.service';
import { UserProfileService } from '@shared/services/user-profile.service';
import { forkJoin, Subject } from 'rxjs';

@Component({
  selector: 'app-aaos-practice-history',
  templateUrl: './practice-history.component.html',
  styleUrls: ['./practice-history.component.scss']
})
export class PracticeHistoryComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() fieldsPrerequisite: FieldsPrerequisite;
  @Input() practiceHistoryDetail: PracticeHistoryDetails;
  @Input() phFormGroup: FormGroup;
  @Input() index;
  @Input() allLoaded: Subject<any>;
  @Input() changeInPHList: string;

  _selectedCurrentPractice: number;
  @Input() set selectedCurrentPractice(value: number) {
    this._selectedCurrentPractice = value;
    if (this._selectedCurrentPractice !== -1) {
      if (this._selectedCurrentPractice !== this.index) {
        // check no for the radio button
        if (this.baseFormGroup.get('currentPractice') !== null) {
          this.YesNoClick(false);
        }
      }
    }
  }
  get selectedCurrentPractice(): number {
    return this._selectedCurrentPractice;
  }

  @Output() componentChanged = new EventEmitter();
  @Output() currentPracticeSelected = new EventEmitter<number>();

  @ViewChildren('practiceHistoryInfo') practiceHistoryInfo: QueryList<any>;

  updatedPracticeDetail = new PracticeHistoryDetails();
  autocomplete: google.maps.places.Autocomplete;
  nameField: HTMLInputElement;
  cityField: HTMLInputElement;
  stateField: HTMLInputElement;
  baseFormGroup = new FormGroup({});
  loading = true;
  states: Preference[] = [];
  statesLoaded = false;
  practiceTypes: Preference[] = [];
  isCurrentPractice?: boolean = null;
  strIsCurrentPractice?: string = null;

  constructor(private userProfileService: UserProfileService,
    private practiceHistoryList: PracticeHistoryListComponent) { }

  ngAfterViewInit(): void {
    this.practiceHistoryInfo.changes.subscribe(t => {
      this.initAutocomplete();
    });
  }

  ngOnInit() {
    this.addControlsToFormGroup();
    this.validateFormGroup();
    this.getAndSetAddress();

    // when all are loaded, lets emit if we're selected yes/no
    this.allLoaded.subscribe(x => {
      if (this.isCurrentPractice) {
        this.currentPracticeSelected.emit(this.index);
      }
    });
  }

  isDeletable() {
    return this.practiceHistoryDetail.id !== 0;
  }

  deletePracticeHistory(event: any) {
    this.practiceHistoryList.deletePracticeHistory(this.practiceHistoryDetail.id);
  }

  detectInputChange() {
    this.updatedPracticeDetail.id = this.practiceHistoryDetail.id;
    this.updatedPracticeDetail.name = this.baseFormGroup.get('name').value;
    this.updatedPracticeDetail.city = this.baseFormGroup.get('city').value;
    this.updatedPracticeDetail.state = this.baseFormGroup.get('state').value;
    this.updatedPracticeDetail.employmentSetting = this.baseFormGroup.get('employmentSetting').value;
    this.updatedPracticeDetail.employmentSettingOptional = this.baseFormGroup.get('employmentSettingOptional').value;
    this.updatedPracticeDetail.startDate = this.baseFormGroup.get('startDate').value;
    this.updatedPracticeDetail.endDate = this.baseFormGroup.get('endDate').value;
    this.updatedPracticeDetail.isCurrentPractice = this.strIsCurrentPractice;
    this.validateFormGroup();
    this.componentChanged.emit(this.updatedPracticeDetail);
  }

  YesNoClick(val: boolean) {
    this.isCurrentPractice = val;
    if (this.isCurrentPractice) {
      this.strIsCurrentPractice = 'yes';
    } else {
      this.strIsCurrentPractice = 'no';
    }

    if (val) {
      this.currentPracticeSelected.emit(this.index);
      this.baseFormGroup.get('endDate').setValue(null);
      this.baseFormGroup.get('endDate').setErrors(null);

    } else if (!val && !this.baseFormGroup.get('endDate')?.value) {
      this.baseFormGroup.get('endDate')?.setErrors(Validators.required);
      this.baseFormGroup.get('endDate')?.setValidators(Validators.required);
      this.baseFormGroup.setValidators([FormValidatorService.getDateValidator('startDate', 'endDate')])

    }
    this.detectInputChange();
    this.baseFormGroup.updateValueAndValidity();
  }

  invalidDateField(): boolean {
    if (!this.invalidRequiredField('startDate') && !this.invalidRequiredField('endDate') && 
    this.baseFormGroup.hasError('before')) 
    {
      return true;
    }
    return false;
  }

  invalidRequiredField(fieldName: string): boolean {
    if (this.baseFormGroup.get(fieldName).hasError('required')) {
      return true;
    }
    return false;
  }

  validateFormGroup() {
    this.baseFormGroup.updateValueAndValidity();
    // some crazy stuff is happening with this yes/no. its set, but for some reason keeps throwing an error
    // so lets do a check here to ensure that its actually checked and don't throw an error
    // we might want to look into this later?
    if (this.baseFormGroup.get('currentPractice').hasError && this.isCurrentPractice !== null) {
      this.baseFormGroup.get('currentPractice').setErrors(null);
    }
  }

  getAndSetAddress() {
    forkJoin([this.userProfileService.getStates(this.fieldsPrerequisite.countryCode),
    this.userProfileService.getPracticeTypes()]).subscribe(latest => {
      const [states, practiceTypes] = latest;
      this.states = states;
      this.statesLoaded = true;
      this.practiceTypes = practiceTypes;
      this.populateFormControl();
      this.validateFormGroup();
      this.loading = false;
    });
  }

  addControlsToFormGroup() {
    this.phFormGroup.addControl(this.index, this.baseFormGroup);
    this.baseFormGroup.addControl('name', new FormControl(null, Validators.required));
    this.baseFormGroup.addControl('city', new FormControl(null, Validators.required));
    this.baseFormGroup.addControl('state', new FormControl(null, Validators.required));
    this.baseFormGroup.addControl('employmentSetting', new FormControl(null, Validators.required));
    this.baseFormGroup.addControl('employmentSettingOptional', new FormControl());
    this.baseFormGroup.addControl('startDate', new FormControl(null, Validators.required));
    this.baseFormGroup.addControl('currentPractice', new FormControl(null, Validators.required));
    this.baseFormGroup.addControl('endDate', new FormControl());
  }

  populateFormControl() {
    this.baseFormGroup.get('name').setValue(this.practiceHistoryDetail.name);
    this.baseFormGroup.get('city').setValue(this.practiceHistoryDetail.city);
    this.baseFormGroup.get('state').setValue(this.practiceHistoryDetail.state);
    this.baseFormGroup.get('employmentSetting').setValue(this.practiceHistoryDetail.employmentSetting);
    this.baseFormGroup.get('employmentSettingOptional').setValue(this.practiceHistoryDetail.employmentSettingOptional);
    this.baseFormGroup.get('startDate').setValue(this.practiceHistoryDetail.startDate);
    this.baseFormGroup.get('endDate').setValue(this.practiceHistoryDetail.endDate);
    this.baseFormGroup.get('currentPractice').setValue(this.practiceHistoryDetail.isCurrentPractice);

    if (this.practiceHistoryDetail.isCurrentPractice != null) {
      this.isCurrentPractice = this.practiceHistoryDetail.isCurrentPractice.toLowerCase() === 'yes';
    }
    this.baseFormGroup.updateValueAndValidity();
  }

  initAutocomplete() {
    this.nameField =
      document.querySelector(`#name_${this.practiceHistoryDetail.id}`) as HTMLInputElement;

    this.cityField =
      document.querySelector(`#city_${this.practiceHistoryDetail.id}`) as HTMLInputElement;

    this.stateField =
      document.querySelector(`#state_${this.practiceHistoryDetail.id}`) as HTMLInputElement;

    // Create the autocomplete object, restricting the search predictions to
    // addresses in the US and Canada.
    this.autocomplete = new google.maps.places.Autocomplete(this.nameField, {
      componentRestrictions: { country: ['us', 'ca'] },
      fields: ['address_components', 'name'],
      types: ['establishment'],
    });
    // this.address1Field.focus();

    // When the user selects an address from the drop-down, populate the
    // address fields in the form.
    google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
      this.fillInAddress();
    });
  }

  fillInAddress() {
    // Get the place details from the autocomplete object.
    const place = this.autocomplete.getPlace();
    const institutionName = place.name;
    let city = '';
    let state = '';

    // Get each component of the address from the place details,
    // and then fill-in the corresponding field on the form.
    // place.address_components are google.maps.GeocoderAddressComponent objects
    // which are documented at http://goo.gle/3l5i5Mr
    for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
      // @ts-ignore remove once typings fixed
      const componentType = component.types[0];

      switch (componentType) {
        case 'locality':
          if (component.long_name !== '') {
            city = component.long_name;
          }
          break;

        case 'sublocality_level_1':
          city = component.long_name;
          break;

        case 'administrative_area_level_1': {
          state = component.short_name;
          break;
        }
      }
    }

    this.baseFormGroup.get('name').setValue(institutionName);
    this.baseFormGroup.get('city').setValue(city);
    this.baseFormGroup.get('state').setValue(state);

    this.stateField.focus();

    this.detectInputChange();
  }

  ngOnChanges(changes: SimpleChanges) {
    // if(changes.changeInPHList.currentValue !== changes.changeInPHList.previousValue) {
    if (changes.changeInPHList?.previousValue !== undefined) {
      this.YesNoClick(this.isCurrentPractice);
      this.baseFormGroup.updateValueAndValidity();
      this.validateFormGroup();
    }
  }
}
