<app-module-header (moduleSelected)="getFormFields($event)" [membershipApplicationType]="membershipApplicationType" (previousModuleValidatorUpdate)="previousModuleValidatorUpdate($event)" (lastModule)="lastModuleRecipient($event)"></app-module-header>
<section class="content">
    <div *ngIf="!loading" class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-lg-6">
                <h1>{{moduleName}}</h1>
                <app-aaos-form-field-group [groups]="groups" [dynamicForm]="dynamicProfileFormGroup" [formOptions]="formOptions" [fieldsPrerequisite]="fieldsPrerequisite" (componentChanged)='addComponentToArray($event)'></app-aaos-form-field-group>
            </div>
        </div>
    </div>
    <app-main-navigation [onLastModule]="onLastModule" [nextEnabled]="(currentModuleFormGroup.valid || currentModuleFormGroup.disabled) && disableNextButton == false" (completeSelected)="nextModule()" (nextSelected)="nextModule()" [nextButtonText]="'Save & Continue'"[backEnabled]="true" (backSelected)="previousModule()"></app-main-navigation>
</section>
<app-aaos-spinner [isLoading]="loading"></app-aaos-spinner>
