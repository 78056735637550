import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaymentReminder } from '@shared/models/data/payment-reminder';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AaosBaseService } from '../aaos-base.service';
import { Constants } from '../constants/constants';
import { LoggingService } from '../logging.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentReminderService extends AaosBaseService {

  constructor(protected _h: HttpClient, protected _l: LoggingService) { super(_l, _h); }


  getAlreadySentEmails(): Observable<PaymentReminder[]> {
    return this.http.get<string>(`${Constants.ApiUrls.GetAlreadySentEmails}`)
      .pipe(
        map((response: any) => {

          return response as PaymentReminder[];
        })
      );
  }


  sendPaymentReminderEmail(applicationInfo: PaymentReminder[]): Observable<any> {
    return this.http.post(Constants.ApiUrls.SendPaymentReminderEmails, applicationInfo)
      .pipe(
        map((response: any) => {

          return response;
        })
      );
  }

  sendFirstTimeEmailsManually(): Observable<any> {
    return this.http.post(Constants.ApiUrls.SendPaymentReminderEmails, null)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }


}
