import { Component, Input, OnInit } from '@angular/core';
import { ApplicationParent } from '@shared/models/data/application-parent.model';
import { FormModule } from '@shared/models/data/form-module.model';
import { ApplicationService } from '@shared/services/administration/application.service';
import { FormStateService } from '@shared/services/forms/form-state.service';

@Component({
  selector: 'app-view-applicant',
  templateUrl: './view-applicant.component.html',
  styleUrls: ['./view-applicant.component.scss']
})
export class ViewApplicantComponent implements OnInit {

  parent: ApplicationParent;
  id: string;
  modules: FormModule[];
  applicationHtml: string;

  constructor(private formStateService: FormStateService,
    private applicationService: ApplicationService) { }

  ngOnInit(): void {
    this.id = history.state.id;

    this.applicationService.getApplicationHtml(this.id).subscribe(x => {
      this.applicationHtml = x;
    });
  }
}
