import { Injectable, Inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  CanActivateChild,
} from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Utilities } from '@shared/services/utils/utilities.service';
import { Constants } from '@shared/services/constants/constants';
import { APP_CONFIG } from '@config/main';
import { of } from 'rxjs';


@Injectable()
export class LoggedInGuard implements CanActivate, CanActivateChild {

  loginRedirect: string;


  constructor(
    @Inject(DOCUMENT) private document: any
  ) { }

  canActivateChild(r: ActivatedRouteSnapshot, s: RouterStateSnapshot) {
    return this.canActivate(r, s);
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let loggedIn = false;
    const cookies = this._getCookies();
    if (Utilities.isDefined(cookies)) {
      const found = cookies.find(
        _ =>
          _.toLowerCase()
            .trim()
            .split('=')[0] === 'mcidweb'
      );
      loggedIn = Utilities.isDefined(found);
    }

    if (!loggedIn && !route.data.ignoreRedirect) {
      const pathParam = Constants.ApiParams.RedirectPath;
      let pathName = window.location.pathname;
      if (state != null && state.url != null) {
        pathName = state.url;
      }

      APP_CONFIG.subscribe(config => {
        pathName = encodeURIComponent(pathName);
        const queryString = encodeURI(`${pathParam}=${pathName}`);
        const path = `${config.authRedirectUrl}?${queryString}`;
        window.location.href = path;
      });
    }
    return of(loggedIn);
  }

  private _getCookies() {
    return this.document.cookie && this.document.cookie !== Utilities.stringEmpty
      ? document.cookie.split(';')
      : null;
  }
}
