export class AddressField {

    activeFlag: boolean;
    labelFlag: boolean;
    requiredFlag: boolean;
    screenFlag: boolean;
    lineNumber: number;
    maxLength: number;
    position: number;
    caption: string;
    countryCode: string;
    delimiter: string;
    fieldName: string;
}
