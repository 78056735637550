export class PracticeInformation {
    public masterCustomerId: string;
    public subspecialty: string;
    public areasofinterest: string;
    public userDefinedProgramSubspecialty: string;
    public ageGroupTreated: string;
    public practiceType: string;
    public language: string;
    public specialtyDegree: string;
    public practiceSetting: string;
    public practiceBeginDate: number;

}
