import { Component, OnInit } from '@angular/core';
import { APP_CONFIG } from '@config/app-config.service';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit {
  referralLink: string;
  becomeAMemberLink: string;

  constructor() { }

  ngOnInit() {
    APP_CONFIG.subscribe(_ => {
      this.referralLink = _.referralLink;
      this.becomeAMemberLink = _.becomeAMemberLink;
    });
  }

  linkClick(link: string) {
    if (link === 'linkedin') {
      window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(this.becomeAMemberLink)}`, '_blank');
    } else if (link === 'facebook') {
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.becomeAMemberLink)}`, '_blank');
    } else if (link === 'email') {
      window.location.href = `mailto:?subject=Check out AAOS!&body=Become an AAOS Member at ${this.becomeAMemberLink}`;
    } else if (link === 'twitter') {
      window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(this.becomeAMemberLink)}`);
    } else if (link === 'tumblr') {
      window.open(`https://www.tumblr.com/widgets/share/tool?canonicalUrl=${encodeURIComponent(this.becomeAMemberLink)}&caption=Become an AAOS Member&posttype=link`, '_blank');
    }
  }

}
