<div class="required-text" *ngIf="practiceHistoryDetails.length < 1">
    At least one practice history is required
</div>
<div class="spacing">
    <div *ngFor="let practiceHistoryDetail of practiceHistoryDetails; index as i">
        <app-aaos-practice-history class="spacing" [index]="i" [phFormGroup]="phFormGroup"
            [practiceHistoryDetail]="practiceHistoryDetail" [changeInPHList]="changeInPHList" [selectedCurrentPractice]="selectedCurrentPractice"
            [allLoaded]="allLoaded" (currentPracticeSelected)="currentPracticeSelected($event)"
            (componentChanged)="onComponentChange($event)" [fieldsPrerequisite]="fieldsPrerequisite">
        </app-aaos-practice-history>
    </div>
</div>
<button class="btn btn--solid" (click)="addPracticeHistory()">Add Practice</button>