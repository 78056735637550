<div *ngIf="!loading">
  <div class="flex-align-center">
    <h2>{{field.displayName}}</h2>
    <div *ngIf="field.tooltipText" class="btn btn--round btn--blue info-icon" matTooltip="{{field.tooltipText}}"
      matTooltipPosition="right" matTooltipHideDelay="4000">i</div>
  </div>
  <div class="form-group" #addressInfo [formGroup]="baseFormGroup" *ngFor="let addressField of addressStructure;">
    <div *ngIf="addressField.fieldName !=='State' && addressField.fieldName !=='CountryCode'">
      <div *ngIf="addressField.fieldName !== 'Address1' &&
    addressField.fieldName !== 'Address2' &&
    addressField.fieldName !== 'Address3'" class="
    title title--xs">{{addressField.caption}}</div>
      <input class="form-control" formControlName="{{addressField.fieldName}}" id="{{addressField.fieldName}}_"
        (blur)="detectInputChange($event)" [ngClass]="{ 'half-width': addressField.fieldName === 'PostalCode' }"
        placeholder="{{ addressField.caption }}" required="{{ addressField.requiredFlag }}" type="text"
        maxlength="{{ addressField.MaxLength }}" />
    </div>

    <div *ngIf="addressField.fieldName === 'State' && (statesLoaded === true && states.length === 0) === false">
      <div class="title title--xs">{{addressField.caption}}</div>
      <mat-select (blur)="detectInputChange($event)" required="{{ addressField.requiredFlag }}"
        formControlName="{{addressField.fieldName}}" disableRipple disableOptionCentering panelClass="mat-panel">
        <mat-option *ngFor="let state of states" [value]="state.preferenceCode">{{
          state.preferenceDescription
          }}</mat-option>
      </mat-select>
    </div>
    <div *ngIf="addressField.fieldName ==='State' && statesLoaded === true && states.length === 0">
      <div class="title title--xs">{{addressField.caption}}</div>
      <input class="form-control" formControlName="{{addressField.fieldName}}" id="{{addressField.fieldName}}_"
        (blur)="detectInputChange($event)" placeholder="{{ addressField.caption }}"
        required="{{ addressField.requiredFlag }}" type="text" maxlength="{{ addressField.MaxLength }}" />
    </div>

    <div *ngIf="addressField.fieldName ==='CountryCode'">
      <div class="title title--xs">Country</div>
      <mat-select required="true" formControlName="{{addressField.fieldName}}" id="{{addressField.fieldName}}_" (blur)="
        detectInputChange($event)" (selectionChange)="resetAddressStructure($event)" disableRipple
        disableOptionCentering panelClass="mat-panel">
        <mat-option *ngFor="let country of applicationCountries" [value]="country.preferenceDescription">
          {{ country.preferenceDescription }}
        </mat-option>
      </mat-select>
    </div>

    <div class="required-text" *ngIf="invalidRequiredField(addressField.fieldName)">
      {{ addressField.caption }} is required
    </div>
    <div class=" required-text" *ngIf="invalidPostalCodeField(addressField.fieldName)">
      Please enter a valid postal code
    </div>

  </div>
</div>
<app-aaos-spinner [isLoading]="loading"></app-aaos-spinner>