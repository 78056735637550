import { Component, OnInit } from '@angular/core';
import { NavService } from '@shared/services/nav.service';

@Component({
  selector: 'app-referral',
  templateUrl: './referral.component.html',
  styleUrls: ['./referral.component.scss']
})
export class ReferralComponent implements OnInit {

  constructor(private navService: NavService) { }

  ngOnInit() {
  }

  upUpAndAway() {
    this.navService.redirectToRoot();
  }
}
