<div class="close-container">
  <mat-icon class="close-icon" (click)="dialogRef.close()"></mat-icon>
</div>
<div class="title title--lg dialog" mat-dialog-title>Automatic Save</div>
<mat-dialog-content>
  <div class="smallerText italic mat-radio-label-content mb15">
    Your application will save automatically as you navigate through each step. If you decide
    to finish your application at a later date, you will pick up where you left off.
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="btn--inline dialog-btn-container">
    <button (click)="dialogRef.close()" class="btn btn--small btn--solid btn--red">GET STARTED</button>
  </div>
</mat-dialog-actions>