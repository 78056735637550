export class Applicant {
    Id: string;
    MasterCustomerId: string;


    ToApplicantDTO(data: any): Applicant {
        const app = new Applicant();

        app.Id = data.Id;
        app.MasterCustomerId = data.MasterCustomerId;

        return app;
    }
}
