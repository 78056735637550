export abstract class RouteNames {
    // Base routes
    public static readonly CatchAll: string = '**';
    public static readonly Root: string = '';
    // Custom routes
    public static readonly Home: string = 'home';
    public static readonly Form: string = 'form';
    public static readonly Prototype: string = 'prototype';
    public static readonly Login: string = 'login';
    public static readonly Questionnaire: string = 'questionnaire';
    public static readonly Landing: string = 'landing';
    public static readonly ViewApplicant: string = 'viewApplicant';
    public static readonly Admin: string = 'administration';
    public static readonly AdminLanding: string = 'admin-selection';
    public static readonly Submit: string = 'submit';
    public static readonly Status: string = 'status';
    public static readonly Pay: string = 'pay';
    public static readonly Referral: string = 'referral';
    public static readonly ThankYou: string = 'thankyou';
    public static readonly UnchangeableMemberType: string = 'membertypeissue';
    public static readonly ApplicationConfiguration: string = 'application-configuration';
    public static readonly UnpaidNotifications: string = 'unpaid-notifications';

}
