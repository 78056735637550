import { Injectable } from '@angular/core';
import { Adapter } from '@shared/services/utils/adapter';

export class CustomerSearchProfileMini {
    masterCustomerId: string;
    labelName: string;
    companyName: string;
    city: string;
    state: string;
    country: string;
}

@Injectable({
    providedIn: 'root'
})
export class CustomerSearchProfileMiniAdapter implements Adapter<CustomerSearchProfileMini> {

    constructor() {
    }
    adapt(item: any): CustomerSearchProfileMini {
        const profileMini: CustomerSearchProfileMini = {
            masterCustomerId: item.MasterCustomerId,
            labelName: item.LabelName,
            companyName: item.CompanyName,
            city: item.City,
            state: item.State,
            country: item.Country,
        };
        return profileMini;
    }
}
