<div class="spacing">
    <div *ngIf="isDeletable() === true">
        <div id="{{this.fellowshipProgramDetail.id}}" class="delete-file-button"
            (click)="deleteInstitution($event.target.id)"></div>
    </div>
    <h3>Fellowship Program {{index + 1}} (Optional)</h3>
</div>
<div *ngIf="!loading">
    <div class="form-group" #institutionInfo [formGroup]="baseFormGroup">
        <div class="title title--xs">Name</div>
        <input class="form-control" formControlName="name" id="name_{{this.fellowshipProgramDetail.id}}"
            (blur)="detectInputChange($event)" type="text" (focus)="loadGoogle()" />
        <div class="required-text" *ngIf="invalidRequiredField('name')">Name is required</div>

        <div class="title title--xs">City</div>
        <input class="form-control" formControlName="city" id="city_{{this.fellowshipProgramDetail.id}}"
            (blur)="detectInputChange($event)" type="text" />
        <div class="required-text" *ngIf="invalidRequiredField('city')">City is required</div>

        <div class="title title--xs">State/Province</div>
        <input class="form-control" formControlName="state" id="state{{this.fellowshipProgramDetail.id}}"
            (blur)="detectInputChange($event)" type="text" />
        <div class="required-text" *ngIf="invalidRequiredField('state')">State/Province is required</div>

        <div class="title title--xs">Country of Program</div>
        <mat-select formControlName="country" id="country_{{this.fellowshipProgramDetail.id}}"
            (blur)="detectInputChange($event)" disableRipple disableOptionCentering panelClass="mat-panel">
            <mat-option *ngFor="let country of applicationCountries" [value]="country.preferenceDescription">
                {{ country.preferenceDescription }}
            </mat-option>
        </mat-select>
        <div class="required-text" *ngIf="invalidRequiredField('country')">Country is required</div>

        <div class="title title--xs">Chair/Director First Name</div>
        <input class="form-control" formControlName="directorFirstName"
            id="directorFirstName_{{this.fellowshipProgramDetail.id}}" (blur)="detectInputChange($event)" type="text" />
        <div class="required-text" *ngIf="invalidRequiredField('directorFirstName')">Chair/Director First Name is
            required
        </div>

        <div class="title title--xs">Chair/Director Last Name</div>
        <input class="form-control" formControlName="directorLastName"
            id="directorLastName_{{this.fellowshipProgramDetail.id}}" (blur)="detectInputChange($event)" type="text" />
        <div class="required-text" *ngIf="invalidRequiredField('directorLastName')">Chair/Director Last Name is required
        </div>

        <div class="title title--xs">Start Date</div>
        <div class="dateContainer">
            <div (click)="startDate.open()" class="date--form-control half-width">
                <input [matDatepicker]="startDate" formControlName="startDate" disabled="true" class="form-control">
            </div>
            <mat-datepicker-toggle [for]="startDate"></mat-datepicker-toggle>
            <mat-datepicker disabled="false" #startDate ngDefaultControl (closed)="detectInputChange($event)">
            </mat-datepicker>
        </div>
        <div class="required-text" *ngIf="invalidRequiredField('startDate') || invalidDateField()">If Start or End Date
            is
            present, the other
            is required. Start Date must precede End Date.
        </div>

        <div class="title title--xs">End Date</div>
        <div class="dateContainer">
            <div (click)="endDate.open()" class="date--form-control half-width">
                <input [matDatepicker]="endDate" formControlName="endDate" disabled="true" class="form-control">
            </div>
            <mat-datepicker-toggle [for]="endDate"></mat-datepicker-toggle>
            <mat-datepicker disabled="false" #endDate ngDefaultControl (closed)="detectInputChange($event)">
            </mat-datepicker>
        </div>
        <div class="required-text" *ngIf="invalidRequiredField('endDate') || invalidDateField()">If Start or End Date is
            present, the other is
            required.</div>
    </div>
</div>
<app-aaos-spinner [isLoading]="loading"></app-aaos-spinner>