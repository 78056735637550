import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LoggingService } from './logging.service';
import { forkJoin, Observable, Subject } from 'rxjs';
import { ProfileFormOption, Preference, UserDataDetailed } from '@shared/models/data';
import { AaosBaseService } from './aaos-base.service';
import { Constants } from '@shared/services/constants/constants';
import { map, catchError } from 'rxjs/operators';
import { Utilities } from '@shared/services/utils/utilities.service';
import { FormGroup, AbstractControl, Validators } from '@angular/forms';
import { AddressField } from '@shared/models/data/AddressField';
import { AddressDetail, AddressDetailAdapter } from '@shared/models/data/AddressDetail';
import { FormField, FormFieldConstants } from '@shared/form-fields/models/form-field';
import { FieldsPrerequisite } from '@shared/models/data/fieldsPrerequisite';
import { PracticeInformation } from '@shared/models/data/PracticeInformation';
import { BaseFieldComponentDirective } from '@shared/form-fields/base-field-component';
import { CustomerSearchProfileMini, CustomerSearchProfileMiniAdapter } from '@shared/models/data/CustomerSearchProfileMini';
import { ApplicationParent } from '@shared/models/data/application-parent.model';
import { Specialization, SpecializationAdapter } from '@shared/models/data/Specialization';
import { PreferenceBase } from '@shared/models/data/PreferenceBase';
import { CustomerProfileDetailedV2 } from '@shared/models/data/CustomerProfileDetailedV2';
import { FormModule } from '@shared/models/data/form-module.model';
import { Contact } from '@shared/models/data/Contact';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService extends AaosBaseService {

  userDataDetailed = new UserDataDetailed;
  userDataDoneLoading: Subject<any> = new Subject<any>();

  constructor(protected _h: HttpClient,
    protected _l: LoggingService,
    protected addressDetailAdapter: AddressDetailAdapter,
    protected specializationAdapter: SpecializationAdapter,
    protected customerSearchProfileMiniaAdapter: CustomerSearchProfileMiniAdapter
  ) {
    super(_l, _h);
  }

  setCountryBinding(parentModel: ApplicationParent, fieldPrerequisite: FieldsPrerequisite, options: ProfileFormOption[]) {
    let countryField;
    try {
      countryField = parentModel.modules.find(x => x.displayName === Constants.FormModuleTitle.Location)
        .formFieldGroups.find(group => group.name === Constants.FormFieldGroupTitle.LocationInformation)
        .formFields.find(field => field.displayName === FormFieldConstants.CountryOfResidenceName).value;
      //change country field from description to code if not us or ca
      if (countryField !== 'US' && countryField !== 'CA') {
        var countries = options.find(x => x.key === Constants.UserProfileFormOptions.Countries).value;
        var country = countries.find(x => x.preferenceDescription === countryField);
        countryField = country.preferenceCode;
      }
    } catch { }


    if (parentModel) {
      if (countryField) {
        fieldPrerequisite.countryCode = countryField;
      }
    }

  }

  getEditProfileFormOptions(): Observable<ProfileFormOption[]> {
    const params = new HttpParams();
    return this.http.get(`${Constants.ApiUrls.GetProfileFormOptions}`).pipe(
      map((response: any) => {
        const keys = Object.keys(response);
        const preferences: ProfileFormOption[] = keys.map((key: string) => {
          let value: Preference[] = JSON.parse(response[key]).map(j => {
            const p = new Preference();
            p.preferenceCode = j.PreferenceCode;
            p.preferenceDescription = j.PreferenceDescription;
            p.preferenceSubCode = j.PreferenceSubCode;
            p.active = true;
            p.sortBy = j.SortBy;
            return p;
          });
          value = Utilities.orderBy(value, 'PreferenceDescription');
          return { key, value };
        });
        return preferences;
      })
    );
  }

  getStates(countryCode: string) {
    return this.http.get(`${Constants.ApiUrls.GetStates}/${countryCode}`).pipe(
      map((response: any) => {
        if ((!response)) {
          throw new Error('Empty response');
        }
        return response;
      }),
      map((data: string) => {
        const json: Preference[] = JSON.parse(data).map(j => {
          const p = new Preference();
          p.preferenceCode = j.PreferenceCode;
          p.preferenceDescription = j.PreferenceDescription;
          p.preferenceSubCode = j.PreferenceSubCode;
          return p;
        });
        return json;
      },
        catchError(response => this.handleError(response))
      ));
  }

  getUserAddresses() {
    return this.http.get(`${Constants.ApiUrls.GetAddresses}`).pipe(
      map((data: string) => {
        const json: Array<AddressDetail> = JSON.parse(data);
        const addresses = (json || ({} as any)).CustomerAddresses.map((item: any) => this.addressDetailAdapter.adapt(item));

        return addresses;
      })
    );
  }

  getCountryCodeFromAddress() {
    return this.http.get(`${Constants.ApiUrls.GetAddresses}`).pipe(
      map((data: string) => {
        const json: Array<AddressDetail> = JSON.parse(data);

        const addresses = (json || ({} as any)).CustomerAddresses.map((item: any) => this.addressDetailAdapter.adapt(item));
        const countryCode = addresses.filter(address => address.isPrimaryAddress === true)[0].countryCode;
        return countryCode;
      })
    );

  }

  getAddressStructure(countryCode: string) {

    return this.http.get(`${Constants.ApiUrls.GetAddressStructure}/${countryCode}`).pipe(
      map((data: string) => {
        const json: AddressField[] = JSON.parse(data).map(j => {
          const p = new AddressField();
          p.activeFlag = j.ActiveFlag;
          p.labelFlag = j.LabelFlag;
          p.requiredFlag = j.RequiredFlag;
          p.screenFlag = j.ScreenFlag;
          p.lineNumber = j.LineNumber;
          p.maxLength = j.MaxLength;
          p.position = j.Position;
          p.caption = j.Caption;
          p.countryCode = j.CountryCode;
          p.delimiter = j.Delimiter;
          p.fieldName = j.FieldName;
          return p;
        });
        return json;
      })
    );
  }

  getAndSetUserData(
    formGroup: FormGroup, formFields: FormField[], fieldPrerequisite: FieldsPrerequisite, c: Contact) {
    switch (fieldPrerequisite.formModuleTitle) {
      case Constants.FormModuleTitle.Location: {
        this.getCountryCodeFromAddress()
          .pipe(
            catchError(response => {
              if (response.status === 401) {
                // user isn't authenticated - let's auth them
              }
              this.userDataDoneLoading.next();
              return response;
            })
          )
          .subscribe((response: any) => {
            this.userDataDetailed.primaryCountry = response;
            this.setUserData(this.userDataDetailed, formGroup, formFields, fieldPrerequisite);
            this.userDataDoneLoading.next();
          });
        break;
      }
      case Constants.FormModuleTitle.Language: {
        this.getPracticeInformation()
          .pipe(
            catchError(response => {
              if (response.status === 401) {
                // user isn't authenticated - let's auth them
              }
              this.userDataDoneLoading.next();
              return response;
            })
          )
          .subscribe((response: PracticeInformation) => {
            this.userDataDetailed.practiceInformation = new PracticeInformation;
            this.userDataDetailed.practiceInformation = response;
            this.setUserData(this.userDataDetailed, formGroup, formFields, fieldPrerequisite);
            this.userDataDoneLoading.next();
          });
        break;
      }
      case Constants.FormModuleTitle.PhoneAndEmail: {

        this.userDataDetailed.primaryEmail = c.Email;
        this.setUserData(this.userDataDetailed, formGroup, formFields, fieldPrerequisite);
        this.userDataDoneLoading.next();
        break;

      }
      case Constants.FormModuleTitle.Specialization: {

        forkJoin([this.getSpecialization(), this.getAreasOfInterest()]).subscribe(latest => {
          const [specialization, areasOfInterest] = latest;
          catchError(response => {
            if (response.status === 401) {
              // user isn't authenticated - let's auth them
            }
            this.userDataDoneLoading.next();
            return response;
          });
          this.userDataDetailed.primaryPracticeSpecialiation = specialization.practiceSpecializationPrimary;
          this.userDataDetailed.areasOfInterest = new Array<Preference>();
          this.userDataDetailed.areasOfInterest = areasOfInterest;
          this.setUserData(this.userDataDetailed, formGroup, formFields, fieldPrerequisite);
          this.userDataDoneLoading.next();
        });
        break;
      }
      case Constants.FormModuleTitle.PersonalInformation: {
        this.getProfileDetailed().subscribe(x => {
          this.userDataDetailed.suffix = x.nameCredentials.nameSuffix;
          this.userDataDetailed.credentials = x.nameCredentials.credentials;
          this.userDataDetailed.dateOfBirth = new Date(c.BirthDate).toJSON();
          this.userDataDetailed.ethnicity = x.customerProfile.ethnicity;
          this.userDataDetailed.gender = x.customerProfile.gender;
          this.userDataDetailed.sexualOrientation = x.customerProfile.identity
          this.userDataDetailed.pronouns = x.customerProfile.identityPronoun;
          this.userDataDetailed.primaryPhone = x.customerProfile.primaryPhone;
          this.setUserData(this.userDataDetailed, formGroup, formFields, fieldPrerequisite);
          this.userDataDoneLoading.next();
        })
        break;
      }
      case Constants.FormModuleTitle.StudentEmail: {
        this.userDataDetailed.secondaryEmail = c.Custom.Attribute.find(x => x.Key == "emailaddress2")?.Value;
        this.setUserData(this.userDataDetailed, formGroup, formFields, fieldPrerequisite);
        this.userDataDoneLoading.next();
        break;
      }
      default: {
        this.userDataDoneLoading.next();
        break;
      }
    }
  }

  setUserData(userDataDetailed: UserDataDetailed, abstractControl: AbstractControl,
    formFields: FormField[], fieldsPrerequisite) {
    if (abstractControl instanceof FormGroup) {
      abstractControl = abstractControl.get(fieldsPrerequisite.formModuleTitle);
      const group = abstractControl as FormGroup;
      Object.keys(group.controls).forEach(key => {
        if (key === FormFieldConstants.PrimaryEmailName) {
          this.setControlValue(key, userDataDetailed.primaryEmail,
            group, formFields);
        }
        if (key === FormFieldConstants.CountryOfResidenceName) {
          this.setControlValue(key, userDataDetailed.primaryCountry,
            group, formFields);
        }

        if (key === FormFieldConstants.PrimaryLanguageName && this.userDataDetailed.practiceInformation.language !== '') {
          this.setControlValue(key, this.userDataDetailed.practiceInformation.language.split(',')[0],
            group, formFields);
        }
        if (key === FormFieldConstants.SecondLanguageName && this.userDataDetailed.practiceInformation.language !== '') {
          this.setControlValue(key, this.userDataDetailed.practiceInformation.language.split(',')[1],
            group, formFields);
        }
        if (key === FormFieldConstants.ThirdLanguageName && this.userDataDetailed.practiceInformation.language !== '') {
          this.setControlValue(key, this.userDataDetailed.practiceInformation.language.split(',')[2],
            group, formFields);
        }
        if (key === FormFieldConstants.AreasOfInterest) {
          const control = group.controls[key];
          this.setCheckBoxValueFromPreference(key, control,
            this.userDataDetailed.areasOfInterest, Constants.UserProfileFormOptions.Subspecialty, formFields);
        }
        if (key === FormFieldConstants.PrimaryPracticeSpecialization) {
          this.setControlValue(key, this.userDataDetailed.primaryPracticeSpecialiation, group, formFields);
        }
        if (key === FormFieldConstants.SuffixName) {
          this.setControlValue(key, this.userDataDetailed.suffix, group, formFields)
          var maxLength = Validators.maxLength(10);
          group.controls[key].setValidators([maxLength]);
        }
        if (key === FormFieldConstants.CredentialsName) {
          const control = group.controls[key];
          this.setCheckBoxValueFromString(key, control,
            this.userDataDetailed.credentials, formFields);
        }
        if (key === FormFieldConstants.DateOfBirthName) {
          this.setControlValue(key, this.userDataDetailed.dateOfBirth, group, formFields)
        }
        if (key === FormFieldConstants.GenderName) {
          this.setControlValue(key, this.userDataDetailed.gender, group, formFields)
        }
        if (key === FormFieldConstants.EthnicityName) {
          this.setControlValue(key, this.userDataDetailed.ethnicity, group, formFields)
        }
        if (key === FormFieldConstants.GenderPronounsName) {
          this.setControlValue(key, this.userDataDetailed.pronouns, group, formFields)
        }
        if (key === FormFieldConstants.SexualOrientationName) {
          this.setControlValue(key, this.userDataDetailed.sexualOrientation, group, formFields)
        }
        if (key === FormFieldConstants.PhoneNumberName) {
          this.setControlValue(key, this.userDataDetailed.primaryPhone, group, formFields)
        }
        if (key === FormFieldConstants.SecondaryEmail) {
          this.setControlValue(key, this.userDataDetailed.secondaryEmail, group, formFields);
        }
      });
    }
  }

  private setControlValue(key: string, assignedValue: string,
    group: FormGroup, formFields: FormField[]) {
    const formField = formFields.filter(field => field.name === key)[0];
    if (formField.value === null) {
      formField.value = assignedValue;
      group.controls[key].setValue(assignedValue);
    }
  }

  setCheckBoxValueFromPreference(formFieldId: string, control: AbstractControl,
    preferences: Preference[], preferenceCode: string, formFields: FormField[]) {
    const formField = formFields.filter(field => field.name === formFieldId)[0];
    if (!formField.value) {
      const matchingPreferences = preferences.filter(x => x.preferenceCode.toLowerCase() === preferenceCode.toLowerCase());
      const values = new Array<string>();
      matchingPreferences.forEach(x => {
        values.push(BaseFieldComponentDirective.getPrefixForFieldAndOption(formFieldId, x));
      });
      const code = matchingPreferences.map(function (x) { return x.preferenceCode; });
      control.setValue(code);
    } else {
      control.setValue(formField.value.split(','));
    }
  }

  setCheckBoxValueFromString(formFieldId: string, control: AbstractControl,
    commaString, formFields: FormField[]) {
    const formField = formFields.filter(field => field.name === formFieldId)[0];
    if (!formField.value && commaString) {
      var codes = commaString.split(', ');
      formField.value = commaString.replace(' ', '');
      control.setValue(codes);
    } else if(formField.value){
      control.setValue(formField.value.split(','));
    } else{
      control.setValue(null);
    }
  }

  getPracticeInformation(): Observable<any> {
    return this.http.get(Constants.ApiUrls.GetPracticeInformation)
      .pipe(
        map((response: any) => {
          if ((!response)) {
            throw new Error(response.error);
          }
          return response;
        }),
        catchError(response => this.handleError(response)),
        map((response: PracticeInformation) => {
          return response;
        })
      );
  }

  getPracticeTypes(): Observable<Preference[]> {
    return this.http.get(Constants.ApiUrls.GetPracticeTypes)
      .pipe(
        map((response: any) => {
          if ((!response)) {
            throw new Error(response.error);
          }
          return response;
        }),
        catchError(response => this.handleError(response)),
        map((response) => {
          const json: Preference[] = JSON.parse(response).map(j => {
            const p = new Preference();
            p.preferenceCode = j.PreferenceCode;
            p.preferenceSubCode = j.PreferenceSubCode;
            p.preferenceDescription = j.PreferenceDescription;
            p.sortBy = j.SortBy;
            p.active = j.Active;
            return p;
          });
          return json;
        })
      );
  }


  getAreasOfInterest(): Observable<any> {
    return this.http.get(Constants.ApiUrls.GetAreasOfInterest)
      .pipe(
        map((response: any) => {
          if ((!response)) {
            throw new Error(response.error);
          }
          return response;
        }),
        catchError(response => this.handleError(response)),
        map((response: Preference[]) => {
          return response;
        })
      );
  }

  getProfileDetailed(): Observable<CustomerProfileDetailedV2> {
    return this.http.get(Constants.ApiUrls.CustomerProfileDetailed)
      .pipe(
        map((response: any) => {
          var deserialized = response as CustomerProfileDetailedV2;
          return deserialized;
        }),
        catchError(response => this.handleError(response)),
        map((response: any) => {
          return response;
        })
      );
  }


  getEmail(): Observable<any> {
    const moduleListResponse = this.http.get(Constants.ApiUrls.GetEmail)
      .pipe(
        map((response: any) => {
          if ((!response)) {
            const error = response.error;
            throw new Error();
          }
          return response;
        }),
        catchError(response => this.handleError(response)),
        map((response: any) => {
          return response;
        }));
    return moduleListResponse;
  }
  getCountryOfResidence(): Observable<any> {
    const moduleListResponse = this.http.get(Constants.ApiUrls.GetAddresses)
      .pipe(
        map((response: any) => {
          if ((!response)) {
            const error = response.error;
            throw new Error();
          }
          return response;
        }),
        catchError(response => this.handleError(response)),
        map((response: any) => {
          return response;
        })
      );
    return moduleListResponse;
  }

  searchSponsors(lastName: string, firstName?: string, maxLimit: number = -1): Observable<CustomerSearchProfileMini[]> {
    let url = `${Constants.ApiUrls.GetSponsors}?${Constants.ApiParams.LastName}=${lastName}&maxLimit=${maxLimit}`;
    if (firstName && firstName !== '') {
      url = url + `&firstName=${firstName}`;
    }
    return this.http.get(url)
      .pipe(
        map((response: any) => {
          if ((!response)) {
            const error = response.error;
            throw new Error();
          }
          return response;
        }),
        catchError(response => this.handleError(response)),
        map((response: any) => {
          const json: Array<CustomerSearchProfileMini> = JSON.parse(response);
          const sponsors = json.map(x => this.customerSearchProfileMiniaAdapter.adapt(x));
          return sponsors;
        })
      );
  }

  getDomesticMedicalSchools(): Observable<Preference[]> {
    let url = `${Constants.ApiUrls.GetDomesticMedicalSchools}`;

    return this.http.get(url)
      .pipe(
        map((response: any) => {
          if ((!response)) {
            const error = response.error;
            throw new Error();
          }
          return response;
        }),
        catchError(response => this.handleError(response)),
        map((response: any) => {
          const json: Array<CustomerSearchProfileMini> = JSON.parse(response);
          const schools = json.map(x => this.customerSearchProfileMiniaAdapter.adapt(x));
          let value: Preference[] = schools.map(j => {
            const p = new Preference();
            p.preferenceCode = j.masterCustomerId;
            p.preferenceDescription = j.labelName;
            p.active = true;
            return p;
          });
          return value;
        })
      );
  }

  getSpecialization(): Observable<any> {
    const specialization = this.http.get(Constants.ApiUrls.GetSpecialization)
      .pipe(
        map((response: any) => {
          if ((!response)) {
            const error = response.error;
            throw new Error();
          }
          return response;
        }),
        catchError(response => this.handleError(response)),
        map((response: any) => {
          const json: Specialization = JSON.parse(response);
          const customer = this.specializationAdapter.adapt(json);
          return customer;
        })
      );
    return specialization;
  }

  getIsAuthenticated(): Observable<boolean> {
    const isAuth = this.http.get(Constants.ApiUrls.IsAuthenticated)
      .pipe(
        map((response: any) => {
          if ((!response)) {
            const error = response.error;
            throw new Error();
          }
          return response;
        }),
        catchError(response => this.handleError(response)),
        map((response: boolean) => {
          return response;
        })
      );
    return isAuth;
  }

  getTransitionSubspecialties(): Observable<PreferenceBase[]> {
    return this.http.get(Constants.ApiUrls.GetTransitionSubspecialties, { responseType: 'json' })
      .pipe(
        catchError(catchResponse => this.handleError(catchResponse)),
        map((response: any) => {
          if (response !== null && response !== undefined) {
            return response as PreferenceBase[];
          } else {
            return null;
          }
        })
      );
  }

  updateProfileDetailed(formfieldsSerialized: string): Observable<any> {
    return this.http.put(Constants.ApiUrls.UpdateProfileDetailed, formfieldsSerialized)
      .pipe(
        map((response: any) => {
          if ((!response)) {
            throw new Error();
          }
          return response;
        }),
        catchError(response => this.handleError(response)),
        map((response: any) => {
          return response;
        })
      );
  }

  saveToProtech(module: FormModule) {
    var groups = module.formFieldGroups;
    var fields = groups.flatMap(x => x.formFields);
    var serializedFields = JSON.stringify(fields);
    this.updateProfileDetailed(serializedFields).subscribe();
  }

  getContact(): Observable<any> {
    return this.http.get(Constants.ApiUrls.GetContact)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(response => this.handleError(response)),
        map((response: any) => {
          return response;
        })
      );
  }
}
