<section class="content">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-8">
        <div>
          <img src="https://www.aaos.org/globalassets/aaos-global/aaos-logo-image.svg" />
        </div>
        <div class="space-top text-center">
          <input type="button" class="btn btn--solid ml-auto" value="START HERE" (click)="upUpAndAway()" />
        </div>
      </div>
    </div>
  </div>
</section>
