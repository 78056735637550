import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseFieldComponentDirective } from '../base-field-component';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss']
})
export class TextAreaComponent extends BaseFieldComponentDirective implements OnInit, OnChanges, AfterViewInit, AfterViewChecked {
  isRequired = true;
  baseFormGroup = new FormGroup({});
  @Input() dynamicForm: any;
  @Input() fieldsPrerequisite: any;
  @Input() active: boolean;

  ngAfterViewInit(): void {
    this.validateFormGroup();
  }


  constructor(    private changeDetector: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.buildFormGroup();
    this.populateModule();
    this.baseFormGroup.updateValueAndValidity();
    this.dynamicForm.updateValueAndValidity();
    this.validateFormGroup();

    if (this.field.displayName.indexOf('Optional') !== -1) {
      this.isRequired = false;
      this.baseFormGroup.get(this.field.name).setValidators([Validators.nullValidator]);
    }
  }

  populateModule() {
    this.baseFormGroup.get(this.field.name).setValue(this.field.value);
  }

  validateFormGroup() {
    if (!this.field.fieldIsDisabled) {
      if (((this.baseFormGroup.hasError('required')) || this.baseFormGroup.invalid)) {
        this.dynamicForm.get(this.fieldsPrerequisite.formModuleTitle).setErrors(Validators.required);
        this.dynamicForm.updateValueAndValidity();
      } else {
        this.baseFormGroup.setErrors(null);
        this.baseFormGroup.updateValueAndValidity();
        this.dynamicForm.get(this.fieldsPrerequisite.formModuleTitle).setErrors(null);
        this.dynamicForm.get(this.fieldsPrerequisite.formModuleTitle).get(this.field.name).setErrors(null);
        this.dynamicForm.updateValueAndValidity();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {

    this.baseFormGroup.updateValueAndValidity();
    this.dynamicForm.updateValueAndValidity();
    this.validateFormGroup();

  }

  buildFormGroup() {
    // this.baseFormGroup.addControl(this.field.name, new FormControl(null, Validators.required));
    this.baseFormGroup.addControl(this.field.name, new FormControl());
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
    this.baseFormGroup.updateValueAndValidity();
    this.dynamicForm.updateValueAndValidity();
    this.validateFormGroup();
  }


}
