import { MemberType } from './member-type.model';

export class MemberApplicationType {
    Id: string;
    Name: string;
    MemberType: MemberType;
    Version: number;

    constructor() {
        this.MemberType = new MemberType();
    }

    ToMemberApplicationTypeDto(data: any): MemberApplicationType {
        this.Id = data.id;
        this.Name = data.name;
        this.Version = data.version;
        this.MemberType = this.MemberType.ToMemberTypeDto(data.MemberType);

        return this;
    }
}
