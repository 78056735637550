<button class="btn btn--right btn--solid btn--red float--none" [disabled]="selection.isEmpty() === true" (click)="sendEmails()">
  Send Email(s)
</button>
<button class="btn btn--right btn--solid btn--red float--none padding--left--sm" (click)="sendFirstTimeEmailsManually()">
  Send First Time Email(s)
</button>

<div>
  <table mat-table mat-elevation-z8 matSort [dataSource]="dataSource">

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox color="primary"
          (change)="$event ? masterToggle() : null; updateSelection()" [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let item">
        <mat-checkbox
           (change)="$event ? selection.toggle(item) : null; updateSelection()"
          [checked]="selection.isSelected(item)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="lastName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</mat-header-cell>
      <mat-cell *matCellDef="let item">{{item.lastName}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="firstName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> First Name </mat-header-cell>
      <mat-cell *matCellDef="let item"> {{item.firstName}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="mcid">
      <mat-header-cell *matHeaderCellDef mat-sort-header> MCID </mat-header-cell>
      <mat-cell *matCellDef="let item"> {{item.mastercustomerId}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="primaryEmailAddress">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Email </mat-header-cell>
      <mat-cell *matCellDef="let item"> {{item.primaryEmailAddress}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="applicationType">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Application Type</mat-header-cell>
      <mat-cell *matCellDef="let item"> {{item.application.applicationType.name}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="sentDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Sent Date</mat-header-cell>
      <mat-cell *matCellDef="let item"> {{item.sentDate}} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
    <mat-row *matRowDef="let item; columns: columnsToDisplay;"></mat-row>
  </table>
</div>
