import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormField } from '../models/form-field';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PgyInformation } from '@shared/models/data/PgyInformation';
import { FieldsPrerequisite } from '@shared/models/data/fieldsPrerequisite';
import { Preference } from '@shared/models/data';
import { FormValidatorService } from '@shared/services/forms/form-validator.service';

@Component({
  selector: 'app-pgy',
  templateUrl: './pgy.component.html',
  styleUrls: ['./pgy.component.scss']
})
export class PgyComponent implements OnInit {

  @Input() field: FormField;
  baseFormGroup = new FormGroup({});
  pgyInfo: PgyInformation;
  @Input() dynamicForm: FormGroup;
  @Input() fieldsPrerequisite: FieldsPrerequisite;
  @Output() componentChanged = new EventEmitter();
  selectedValue: string;
  schoolYearOptions: Preference[] = [];
  loading = true;
  pgyValid = false;
  
  constructor() {}    

  ngOnInit(): void {
    this.populateDropDown();
    this.buildFormGroup();
    this.setPgyInformation();
    this.populateFormGroup();
    this.loading = false;
    this.dynamicForm.updateValueAndValidity();
    this.validateFormGroup();
  }

  setPgyInformation() {
    this.pgyInfo = new PgyInformation();
    if (this.field.value) {
      const parsedJson: PgyInformation = JSON.parse(this.field.value);
      this.pgyInfo = parsedJson;
      this.selectedValue = this.pgyInfo.yearInSchool;
    }
    
  }

  buildFormGroup(){
    this.baseFormGroup = new FormGroup({});
    this.baseFormGroup.addControl('pgyBeginDate', new FormControl());
    this.baseFormGroup.addControl('pgyEndDate', new FormControl());
    this.baseFormGroup.addControl('yearInSchool', new FormControl());    
    this.setValidators();
    this.dynamicForm.updateValueAndValidity();
    this.validateFormGroup();
  }

  setValidators(){
    this.baseFormGroup.get('pgyBeginDate').setValidators(Validators.required);
    this.baseFormGroup.get('pgyEndDate').setValidators(Validators.required);
    this.baseFormGroup.get('yearInSchool').setValidators(Validators.required);
    this.baseFormGroup.setValidators([FormValidatorService.getDateValidator('pgyBeginDate', 'pgyEndDate')])
  }

  
  validateFormGroup() {
    if (!this.field.fieldIsDisabled) {
      if (!this.field.fieldIsDisabled && ((this.baseFormGroup.hasError('required')) || this.baseFormGroup.invalid)) {
        this.dynamicForm.get(this.fieldsPrerequisite.formModuleTitle).setErrors(Validators.required);
        this.dynamicForm.updateValueAndValidity();
      } else {
        this.baseFormGroup.setErrors(null);
        this.baseFormGroup.updateValueAndValidity();
        this.dynamicForm.get(this.fieldsPrerequisite.formModuleTitle).setErrors(null);
        this.dynamicForm.updateValueAndValidity();
      }
    }
  }

  populateFormGroup() {
    this.baseFormGroup.get('pgyBeginDate').setValue(this.pgyInfo.pgyBeginDate);
    this.baseFormGroup.get('pgyEndDate').setValue(this.pgyInfo.pgyEndDate);
    this.baseFormGroup.get('yearInSchool').setValue(this.pgyInfo.yearInSchool);
  }

  invalidRequiredField(fieldName: string): boolean {
    if (this.baseFormGroup.get(fieldName).hasError('required') || 
    this.baseFormGroup.get(fieldName).hasError('before')) {
      return true;
    }
    else
    return false;
  }

  invalidDateField(): boolean {
    if (!this.invalidRequiredField('pgyBeginDate') && !this.invalidRequiredField('pgyEndDate') && 
    this.baseFormGroup.hasError('before')) 
    {
      return true;
    }
    return false;
  }

  detectInputChange(event: any) {
    this.pgyInfo.yearInSchool = this.selectedValue;
    this.pgyInfo.pgyBeginDate = this.baseFormGroup.get('pgyBeginDate').value;
    this.pgyInfo.pgyEndDate = this.baseFormGroup.get('pgyEndDate').value;
    this.dynamicForm.updateValueAndValidity();
    this.validateFormGroup();
    this.field.value = JSON.stringify(this.pgyInfo);
    this.componentChanged.emit(this.field);
  }

  populateDropDown() {
    this.schoolYearOptions = this.createNewYearOptions();
  }


  createNewYearOptions(): Preference[] {
    return [ { preferenceCode: "0",
            preferenceDescription:"",
            preferenceSubCode: "0",
            active: true,
            sortBy: 0 },
            { preferenceCode: "1",
            preferenceDescription:"1",
            preferenceSubCode: "1",
            active: true,
            sortBy: 1 },
            { preferenceCode: "2",
            preferenceDescription:"2",
            preferenceSubCode: "2",
            active: true,
            sortBy: 2 },
            { preferenceCode: "3",
            preferenceDescription:"3",
            preferenceSubCode: "3",
            active: true,
            sortBy: 3 },
            { preferenceCode: "4",
            preferenceDescription:"4",
            preferenceSubCode: "4",
            active: true,
            sortBy: 4 }, ]
  }
}
