import { FormField } from '@shared/form-fields/models/form-field';
import { FormFieldGroup, FormFieldGroupAdapter } from '@shared/form-fields/models/form-field-group';
import { Injectable, OnInit } from '@angular/core';
import { Adapter } from '@shared/services/utils/adapter';

export class FormModule {

    formFieldGroups: FormFieldGroup[] = [];
    version: number;
    name: string;
    displayName: string;
    id: string;
    sortOrder: number;
    description: string;
    completed: boolean;
    moduleTouched: boolean;
    moduleStep: number;
    allFieldsValid: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class FormModuleAdapter implements Adapter<FormModule> {

    constructor(private formGroupAdapter: FormFieldGroupAdapter) {
    }

    adapt(item: any): FormModule {
        const module: FormModule = {
            id: item.Id,
            name: item.Name,
            version: item.Version,
            displayName: item.DisplayName,
            formFieldGroups: item.FormFieldGroups.map((i: any[]) => this.formGroupAdapter.adapt(i)),
            sortOrder: item.SortOrder,
            description: item.Description,
            completed: item.Completed,
            moduleTouched: item.ModuleTouched,
            moduleStep: item.ModuleStep,
            allFieldsValid: item.AllFieldsValid
        };
        return module;
    }
}
