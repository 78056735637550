import { Component, OnInit, Input, EventEmitter, Output, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { AddressField } from '@shared/models/data/AddressField';
import { ProfileFormOption, Preference } from '@shared/models/data';
import { UserProfileService } from '@shared/services/user-profile.service';
import { FieldsPrerequisite } from '@shared/models/data/fieldsPrerequisite';
import { AddressDetail } from '@shared/models/data/AddressDetail';
import { AddressListComponent } from '@shared/components/address-list/address-list.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Constants } from '@shared/services/constants/constants';
import { forkJoin } from 'rxjs';
import { FormValidatorService } from '@shared/services/forms/form-validator.service';

@Component({
  selector: 'app-aaos-basic-address',
  templateUrl: './basic-address.component.html',
  styleUrls: ['./basic-address.component.scss']
})
export class BasicAddressComponent implements OnInit, AfterViewInit {

  @Input() formOptions: ProfileFormOption[];

  addressStructure: AddressField[];
  @Input() addressDetail: AddressDetail;
  updatedAddress = new AddressDetail();
  states: Preference[] = [];
  @Input() fieldsPrerequisite: FieldsPrerequisite;
  newAddress = false;
  @Input() dynamicForm: FormGroup;
  baseFormGroup = new FormGroup({});
  loading = true;
  @Output() componentChanged = new EventEmitter();
  @Input() addressIndex = 0;
  statesLoaded = false;

  @ViewChildren('addressInfo') addressInfo: QueryList<any>;

  autocomplete: google.maps.places.Autocomplete;
  address1Field: HTMLInputElement;
  address2Field: HTMLInputElement;
  cityField: HTMLInputElement;
  stateField: HTMLInputElement;
  postalField: HTMLInputElement;

  constructor(
    private userProfileService: UserProfileService,
    private addressListComponent: AddressListComponent
  ) {

  }
  ngAfterViewInit(): void {
    this.addressInfo.changes.subscribe(t => {
      this.initAutocomplete();
    });
  }

  ngOnInit() {
    this.getAndSetAddress();
  }

  initAutocomplete() {
    this.address1Field =
      document.querySelector(`#${Constants.AddressSubFieldNameConstants.AddressLine1}_${this.addressIndex}`) as HTMLInputElement;
    this.address2Field =
      document.querySelector(`#${Constants.AddressSubFieldNameConstants.AddressLine2}_${this.addressIndex}`) as HTMLInputElement;
    this.cityField =
      document.querySelector(`#${Constants.AddressSubFieldNameConstants.City}_${this.addressIndex}`) as HTMLInputElement;
    this.stateField =
      document.querySelector(`#${Constants.AddressSubFieldNameConstants.State}_${this.addressIndex}`) as any;
    this.postalField =
      document.querySelector(`#${Constants.AddressSubFieldNameConstants.PostalCode}_${this.addressIndex}`) as HTMLInputElement;

    // Create the autocomplete object, restricting the search predictions to
    // addresses in the US and Canada.
    this.autocomplete = new google.maps.places.Autocomplete(this.address1Field, {
      componentRestrictions: { country: ['us', 'ca'] },
      fields: ['address_components', 'geometry'],
      types: ['address'],
    });
    // this.address1Field.focus();

    // When the user selects an address from the drop-down, populate the
    // address fields in the form.
    google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
      this.fillInAddress();
    });
  }

  fillInAddress() {
    // Get the place details from the autocomplete object.
    const place = this.autocomplete.getPlace();
    let address1 = '';
    let city = '';
    let state = '';
    let postcode = '';
    let country = '';

    // Get each component of the address from the place details,
    // and then fill-in the corresponding field on the form.
    // place.address_components are google.maps.GeocoderAddressComponent objects
    // which are documented at http://goo.gle/3l5i5Mr
    for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
      // @ts-ignore remove once typings fixed
      const componentType = component.types[0];

      switch (componentType) {
        case 'street_number': {
          address1 = `${component.long_name} ${address1}`;
          break;
        }

        case 'route': {
          address1 += component.short_name;
          break;
        }

        case 'postal_code': {
          postcode = `${component.long_name}${postcode}`;
          break;
        }

        case 'postal_code_suffix': {
          postcode = `${postcode}-${component.long_name}`;
          break;
        }

        case 'locality':
          if (component.long_name !== '') {
            city = component.long_name;
          }
          break;

        case 'sublocality_level_1':
          city = component.long_name;
          break;

        case 'administrative_area_level_1': {
          state = component.short_name;
          break;
        }

        case 'country':
          country = component.long_name;
          break;
      }
    }

    this.baseFormGroup.get(Constants.AddressSubFieldNameConstants.AddressLine1).setValue(address1);
    this.baseFormGroup.get(Constants.AddressSubFieldNameConstants.City).setValue(city);
    this.baseFormGroup.get(Constants.AddressSubFieldNameConstants.State).setValue(state);
    this.baseFormGroup.get(Constants.AddressSubFieldNameConstants.PostalCode).setValue(postcode);

    this.address2Field.focus();

    this.detectInputChange();
  }

  getAndSetAddress() {

     forkJoin([this.userProfileService.getStates(this.fieldsPrerequisite.countryCode),
    this.userProfileService.getAddressStructure(this.fieldsPrerequisite.countryCode)]).subscribe(latest => {
      const [states, addressStructure] = latest;
      this.states = states;
      this.statesLoaded = true;
      this.addressStructure = addressStructure.filter(field =>
        (field.fieldName === Constants.AddressSubFieldNameConstants.AddressLine1) ||
        (field.fieldName === Constants.AddressSubFieldNameConstants.AddressLine2) ||
        (field.fieldName === Constants.AddressSubFieldNameConstants.City) ||
        (field.fieldName === Constants.AddressSubFieldNameConstants.State) ||
        (field.fieldName === Constants.AddressSubFieldNameConstants.PostalCode));
      this.sortAddressStructure();
      this.addControlsToFormGroup();
      this.populateFormControl();
      this.validateFormGroup();
      this.loading = false;
    });

  }

  sortAddressStructure() {
    this.addressStructure.sort((a, b) => a.position - b.position);
    this.addressStructure.sort((a, b) => a.lineNumber - b.lineNumber);
  }

  deleteAddress(id: string) {
    this.addressListComponent.deleteAddress(id);
  }

  invalidRequiredField(fieldName: string): boolean {
    if (this.baseFormGroup.get(fieldName).hasError('required')) {
      return true;
    }
    return false;
  }

  detectInputChange() {
    this.updatedAddress.address1 = this.baseFormGroup.get(Constants.AddressSubFieldNameConstants.AddressLine1)?.value;
    this.updatedAddress.address2 = this.baseFormGroup.get(Constants.AddressSubFieldNameConstants.AddressLine2)?.value;
    this.updatedAddress.city = this.baseFormGroup.get(Constants.AddressSubFieldNameConstants.City)?.value;
    this.updatedAddress.state = this.baseFormGroup.get(Constants.AddressSubFieldNameConstants.State)?.value;
    this.updatedAddress.postalCode = this.baseFormGroup.get(Constants.AddressSubFieldNameConstants.PostalCode)?.value;
    this.updatedAddress.countryCode = this.fieldsPrerequisite.countryCode;
    this.validateFormGroup();
    this.componentChanged.emit(JSON.stringify(this.updatedAddress));
  }

  invalidPostalCodeField(fieldName: string): boolean {
    if ((fieldName === 'PostalCode') && (this.baseFormGroup.get('PostalCode').hasError('pattern'))) {
      return true;
    }
    return false;
  }

  addControlsToFormGroup() {
    this.dynamicForm.addControl(this.fieldsPrerequisite.formModuleTitle, this.baseFormGroup);
    this.addressStructure.forEach(addressField => {
      const validators = [];
      if (addressField.requiredFlag) {
        validators.push(Validators.required);
      }
      if (addressField.fieldName.toLowerCase() === Constants.AddressSubFieldNameConstants.PostalCode.toLowerCase()
        && this.fieldsPrerequisite.countryCode === Constants.CountryConstants.US) {
        validators.push(FormValidatorService.getZipCodeValidator());
      }
      this.baseFormGroup.addControl(addressField.fieldName, new FormControl(null, { validators }));
    });
  }

  populateFormControl() {
    this.baseFormGroup.get(Constants.AddressSubFieldNameConstants.AddressLine1)?.setValue(this.addressDetail.address1);
    this.baseFormGroup.get(Constants.AddressSubFieldNameConstants.AddressLine2)?.setValue(this.addressDetail.address2);
    this.baseFormGroup.get(Constants.AddressSubFieldNameConstants.City)?.setValue(this.addressDetail.city);
    if (this.states && this.states.length !== 0 && !this.states.map(x => x.preferenceCode).includes(this.addressDetail.state)) {
      this.baseFormGroup.get(Constants.AddressSubFieldNameConstants.State)?.setValue(null);
    } else {
      this.baseFormGroup.get(Constants.AddressSubFieldNameConstants.State)?.setValue(this.addressDetail.state);
    }
    this.baseFormGroup.get(Constants.AddressSubFieldNameConstants.PostalCode)?.setValue(this.addressDetail.postalCode);

    // if something is filled out, lets state that we detect input change so that we can capture this data
    if (this.addressDetail.address1 ||
      this.addressDetail.address2 ||
      this.addressDetail.city ||
      this.addressDetail.state ||
      this.addressDetail.postalCode) {
      this.detectInputChange();
    }
  }

  isAddressPrimary() {
    if (this.addressDetail.isPrimaryAddress === true) {
      return true;
    }
    return false;
  }

  validateFormGroup() {
    if (this.baseFormGroup.invalid) {
      this.dynamicForm.get(this.fieldsPrerequisite.formModuleTitle).setErrors(Validators.required);
    } else {
      this.dynamicForm.get(this.fieldsPrerequisite.formModuleTitle).setErrors(null);
    }
  }

}
