import { Injectable } from '@angular/core';
import { Adapter } from '@shared/services/utils/adapter';

export class GenderConstants {
  public static Male = 'MALE';
  public static Female = 'FEMALE';
  public static Other = 'OTHER';
  public static WishNotToDisclose = 'DONOTDISCLOSE';
}

export class CountryOfResidence {
  public static UnitedStates = 'United States';
  public static Canada = 'Canada';
}

export class Language {
  public static English = 'English';
  public static Spanish = 'Spanish';
  public static French = 'French';
}

export class FormFieldRuleConstants {
  public static ReadOnlyIfNotBlankId = 'guid';
  public static ReadOnlyIfNotBlankName = 'ReadOnlyIfNotBlank';

  public static OptionalEditableId = 'guid';
  public static OptionalEditableName = 'OptionalEditable';

  public static DisplayOnlyId = 'guid';
  public static DisplayOnlyIdName = 'DisplayOnly';

  public static RequiredEditableId = 'guid';
  public static RequiredEditableIdName = 'RequiredEditable';

  public static RequiredIfBlankId = 'guid';
  public static RequiredIfBlankIdName = 'RequiredIfBlank';
}


export class FormField {

  public id: string;
  public name: string;
  public displayName: string;
  public displayValue: string;
  public version: number;
  public tooltipText: string;
  public sortOrder: number;
  public placeholderText: string;
  public value?: any;
  public fieldType: FieldType;
  public fieldIsDisabled?: boolean;
  public controlledFields: string[];
  public controlledType: string;
  public additionalValues: string;
}

export class FieldType {
  public id: string;
  public name: string;
}

export class FormFieldConstants {
  public static PrimaryLanguage = '0bb653d3-8b3f-4e2e-8fd8-8caee4bed4fe';
  public static SecondLanguage = '863e4dce-1cd3-4611-8cc9-a324f5b36708';
  public static ThirdLanguage = '3465fbbf-663f-42f4-8599-20734f84f78d';
  public static CountryOfResidence = '66710A01-FA8D-4457-A3CF-514065871779';
  public static CountryOfEmployment = 'cc2cd549-6698-40e9-a90e-363c19b66e1e';
  public static CountryOfCitizenship = '4bab6a20-83fc-49b9-a33d-9825a055b967';
  public static BirthPlace = '313d423f-185b-4548-90c1-c6e45703eda7';
  public static Specialty = '6EBFF360-9B5F-4DE1-8F8D-5CEF25279C8C';
  public static PracticeSpecializationsPrimary = '5B4E687D-8E6A-4ACF-86D2-DF15002919E2';
  public static PrimaryEmail = 'D65329D7-30CD-4CD3-BCDD-6AEE60BDA2F6';
  public static OrthoteamRole = '707AF556-5FD7-4880-B757-B586137487F8';
  public static DomesticMedicalSchools = '84268309-AFB8-42B7-9CBE-456898B3D50F';
  public static PrimaryEmailName = 'Primary Email';
  public static CountryOfResidenceName = 'Country of Residence';
  public static PrimaryLanguageName = 'Primary Language';
  public static SecondLanguageName = 'Additional Language0';
  public static ThirdLanguageName = 'Additional Language1';
  public static AreasOfInterest = 'Speciality Areas';
  public static PrimaryPracticeSpecialization = 'Primary Practice Specialization';
  public static Questionnaire = 'questionnaire';
  public static EthnicitiesId = '0F9C5B54-68CC-4CCC-B8C5-BF487EA46597';
  public static GenderId = '2E241606-F2AE-4746-BF6C-6B8F7DFF94E1';
  public static GenderName = 'Gender';
  public static SuffixId = 'A941FB74-2902-4575-B072-DF6D9BE29523';
  public static SuffixName = 'Suffix';
  public static CredentialsName = 'Credentials';
  public static CredentialsId = '7355E620-47DD-4250-BBA8-7297B9FB598B';
  public static EthnicityId = '0F9C5B54-68CC-4CCC-B8C5-BF487EA46597';
  public static EthnicityName = 'Race/Ethnicity';
  public static DateOfBirthName = 'Date of Birth';
  public static DateOfBirthId = 'AC7C86D3-C506-4A02-BEBE-7BEAAF9CA6F3';
  public static SexualOrientationId = '4104F573-E1B3-4E88-A649-43BCE0EE1018';
  public static SexualOrientationName = 'Sexual Orientation';
  public static GenderPronounsName = 'Gender Pronouns';
  public static GenderPronounsId = '883A31B6-D164-46F7-B0F5-632C1D8A3ED0';
  public static PhoneNumberName= 'Phone Number';
  public static SecondaryEmail = 'Secondary Email';
}

@Injectable({
  providedIn: 'root',
})
export class FormFieldAdapter implements Adapter<FormField> {
  constructor(private fieldTypeAdapter: FieldTypeAdapter) {
  }

  adapt(item: any): FormField {
    const field: FormField = {
      id: item.Id,
      name: item.Name,
      displayName: item.DisplayName,
      displayValue: item.DisplayValue,
      sortOrder: item.SortOrder,
      version: item.Version,
      tooltipText: item.TooltipText,
      placeholderText: item.PlaceHolderText,
      fieldType: this.fieldTypeAdapter.adapt(item.FieldType),
      controlledFields: item.ControlledFields,
      controlledType: item.ControlledType,
      value: item.Value,
      additionalValues: item.AdditionalValues
    };
      return field;
  }
}

@Injectable({
  providedIn: 'root',
})
export class FieldTypeAdapter implements Adapter<FieldType> {
  adapt(item: any): FieldType {
    const fieldType: FieldType = {
      id: item.Id,
      name: item.Name
    };
    return fieldType;
  }
}

