<section class="content">
  <div class="container">
    <div class="row justify-content-center">
      <div *ngIf="!loading && applicationType === ''" class="col-12 col-lg-6">
        <div class="required-text">
          If you are currently an active Member in the category that you are completing the application for, please do
          not proceed.
          If you have questions on qualifications for Membership categories, please contact join@aaos.org.
        </div>
        <h2>Select the Membership category that you are applying for.</h2>
        <mat-radio-group class="form-group" aria-label="Select an option" class="radio">
          <div *ngFor="let member of memberTypes; let i = index">
            <mat-radio-button (change)='setApplicationType(member)' value={{member.name}}>{{member.name}}
              <span class="btn btn--round btn--blue info-icon bottom--padding" matTooltip="{{member.tooltipText}}"
                matTooltipPosition="right" [matTooltipShowDelay]="100" matTooltipHideDelay="150">i</span>
            </mat-radio-button>
          </div>
        </mat-radio-group>
        <div *ngIf="!memberTypes || memberTypes.length == 0" class="required-text">
          No application available to be applied to at this time for your member type. If you think this is in error,
          please contact our customer support team.
        </div>
        <div *ngIf="memberTypes && memberTypes.length > 0" class="required-text">
          If you don't see an application type available for you to apply to, please feel free to reach our customer
          support team.
        </div>
        <div class="non-required-text m50">
          For your reference, the following categories are domestically trained Membership Categories: Active Fellow,
          Allied Member –Physician, Orthopaedic Researcher, and Orthopaedic Team For Member, Candidate Members –
          Domestic Medical Student. The following categories are for Internationally trained Membership categories:
          Candidate Member – International Medical Student, International Member- Orthopaedic Surgeon, International
          Member - Resident. Affiliate - Orthopaedic Surgeon has the option for domestically and internationally trained
          surgeons, however, must be living in the United States or Canada at the time of application.
        </div>
        <app-main-navigation [nextEnabled]="hasAnsweredMemberType" (nextSelected)="getQuestions($event)"
          [backEnabled]="true"></app-main-navigation>
      </div>
      <div class="col-12 col-lg-6" *ngIf="applicationType !== ''">
        <div class="required-text">
          If you already are a current Member of this category you are applying for, do not proceed. Contact
          join@aaos.org for more information.
        </div>
        <h2>Tell us more</h2>
        <div *ngFor="let question of questions">
          <app-yes-no [field]='question' (click)="logEvent($event, question.name)"
            (yesNoSelected)="addQualification($event, question)" [displayText]="question.name"></app-yes-no>
        </div>
        <app-main-navigation [nextEnabled]="continueToApplicationEnabled && !showMessage" [backEnabled]="true"
          (backSelected)="goToSelectMember()" (nextSelected)="navigateToForm()"></app-main-navigation>
        <div class="required-text" *ngIf="showMessage">
          You are not eligible for this membership category, please reach out to join@aaos.org to determine your
          eligibility.
        </div>
      </div>
    </div>
  </div>
</section>