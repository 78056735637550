<section class="content">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-6">
        <h1>Submit Application</h1>
        <div *ngIf="!loadingError && (showSubmitAndPayButton || showSubmitOnlyButton) && !showValidationErrors && !showSubmitErrors">
          <p>You have provided all required information and may now submit your application.</p>
          <p>I understand that AAOS will handle my membership application and its contents in a confidential manner. I
            authorize any AAOS staff to make whatever inquiries and investigations it deems appropriate to verify my
            credentials, professional standing, employment history and moral or ethical character. I hereby consent to
            the release by any hospital, educational institution or governmental agency, physician, professional
            society, or other person possessing or requiring the same, whether or not listed above, of any and all
            information in any way pertaining to my personal character, training, experience, employment or professional
            competence.</p>
          <p>I recognize that AAOS does not discriminate on the basis of race, color, gender, sexual orientation,
            religion, or national origin, or on any basis that would constitute illegal discrimination. I agree to
            comply with the AAOS <a href={{bylawsUrl}}>Bylaws</a>, <a href={{standardsOfPUrl}}>Standards of
              Professionalism</a> and all the rules and regulations adopted pursuant
            to them. I understand that the Bylaws and Standards of Professionalism are available on the AAOS website,
            <a href={{mainSiteUrl}}>American Academy of Orthopaedic Surgeons - AAOS</a>. I acknowledge that the
            application is governed by the laws
            of the State of Illinois, where the offices of AAOS are located. Should any dispute arise from this
            application process, I agree to be bound by the laws of the State of Illinois.
          </p>
          <p>I certify that the information contained in this application is correct to the best of my knowledge. I
            understand that to falsify information may be grounds for AAOS to deny my application for membership in
            AAOS. </p>
          <p>Please note that once your application has been submitted, the application cannot be changed. Click "Return
            to Application" to review or modify your application before submitting.</p>
          
          <div *ngIf="showSubmitOnlyButton"><p>Click "Submit" to submit your application.</p></div>
          <div *ngIf="showSubmitAndPayButton">
            <p>Application fees or dues must be paid before your application can be reviewed for approval.</p>
            <p>Click "Submit & Pay" to submit your application and pay application fees or dues.</p></div>
        </div>
        <div *ngIf="!loadingError && showPayButton && !showValidationErrors && !showSubmitErrors">
          <p>You have provided all required information and your application will be submitted for review as soon as
            payment has been received.</p>
          <p>Click "Pay" to pay application fees or dues.</p>
        </div>
      </div>
    </div>
    <div *ngIf="loadingError" class="row justify-content-center">
      <div class="col-12 col-md-6">
        There was an issue loading this page. Please try again or contact support to report the problem.
      </div>
    </div>
    <div *ngIf="!loadingError && !loadingData && !submittingApplication && !showSubmitAndPayButton && !showPayButton && !showSubmitOnlyButton"
      class="row justify-content-center">
      <div class="col-12 col-md-6">
        Your application status does not allow for submission or payment at this time. Please <a
          [routerLink]="['/status']">click here</a> to visit your application status.
      </div>
    </div>
    <div *ngIf="!loadingError && showValidationErrors" class="row justify-content-center">
      <div class="col-12 col-md-6">
        <p>The following information is not complete. All required information must be entered before submitting your
          application for approval.</p>
        <p>Click "Return to Application" to complete the missing information.</p>
        <div>
          <ul>
            <li *ngFor="let item of validationErrorList" class="required-text">{{item.errorMessage}}</li>
          </ul>
        </div>
      </div>
    </div>
    <div *ngIf="!loadingError && showSubmitErrors" class="row justify-content-center">
      <div class="col-12 col-md-6">
        <p>The following error(s) occurred attempting to submit your application. Please contact support to report the
          problem:</p>
        <div>
          <ul>
            <li *ngFor="let item of submitErrorList" class="required-text">{{item}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="appNavigation hfix">
    <div class="container mx-auto">
      <div class="row nav-button-row">
        <div class="col-12 col-lg-3 col-sm-5">
          <button *ngIf="showSubmitAndPayButton || showValidationErrors || showSubmitOnlyButton" class="btn btn--solid btn--left w100"
            (click)="onReturnToApplication()">Return to Application</button>
        </div>
        <div class="p-1 col"></div>
        <div class="col-12 col-lg-3 col-sm-5">
          <button *ngIf="showSubmitAndPayButton" class="btn btn--solid btn--right {{disableClass}} w100"
            (click)="onSubmit()">Submit & Pay{{orderPrice}}</button>
          <button *ngIf="showPayButton" class="btn btn--solid {{disableClass}} w100"
            (click)="onPay()">Pay{{orderPrice}}</button>
          <button *ngIf="showSubmitOnlyButton" class="btn btn--solid {{disableClass}} w100"
            (click)="onSubmit()">Submit</button>
        </div>
      </div>
    </div>
  </div>
</section>
<app-aaos-spinner [isLoading]="!loadingError && (loadingData || submittingApplication || loadingPrice)">
</app-aaos-spinner>