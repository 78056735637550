<div class="close-container">
  <mat-icon class="close-icon" (click)="dialogRef.close()"></mat-icon>
</div>
<div class="title title--lg dialog" mat-dialog-title>Payment Reminder</div>
<mat-dialog-content>
  <div class="smallerText mat-radio-label-content mb15">
    You are almost done. The final step to submit your application is to pay for your Membership dues, ${{data.orderPrice}}.
    With your Membership, you will gain access to specialty orthopaedic research, videos and other resources valued over ${{data.resourceValue}}.
    Please proceed to the next screen to complete the payment steps.
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="btn--inline dialog-btn-container">
    <button (click)="dialogRef.close()" class="btn btn--small btn--solid btn--red">OK</button>
  </div>
</mat-dialog-actions>