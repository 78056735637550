import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormField } from '@shared/form-fields/models/form-field';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { FormBuilderService } from '@shared/services/forms/form-builder.service';
import { FormGroup } from '@angular/forms';
import { FormFieldGroup } from '@shared/form-fields/models/form-field-group';
import { ProfileFormOption } from '@shared/models/data/ProfileFormOption';
import { ApplicationParent } from '@shared/models/data/application-parent.model';
import { UserProfileService } from '@shared/services/user-profile.service';
import { FormModule } from '@shared/models/data/form-module.model';
import { ModuleHeaderComponent } from '../../shared/components/moduleHeader/moduleHeader.component';
import { ApplicantService } from '@shared/services/forms/applicant.service';
import { FieldsPrerequisite } from '@shared/models/data/fieldsPrerequisite';
import { AddressDetail } from '@shared/models/data/AddressDetail';
import { ModuleManagerService } from '@shared/services/forms/module-manager.service';
import { Subject, Subscription } from 'rxjs'
import { NavService } from '@shared/services/nav.service';
import { AfterViewChecked } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AutomaticSaveDialogComponent } from '../automatic-save-dialog/automatic-save-dialog.component';
import { Constants } from '@shared/services/constants/constants';
import { FormStateService } from '@shared/services/forms/form-state.service';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit, OnDestroy, AfterViewChecked {

  @ViewChild(ModuleHeaderComponent, { static: true })
  moduleHeaderComponent: ModuleHeaderComponent;

  parentModel: ApplicationParent;
  formOptions: ProfileFormOption[];
  loading = true;
  pendingAMP = false;
  valueFields: FormField[] = [];
  formFields: FormField[];
  moduleName: string;
  moduleTitle: string;
  currentModuleFormGroup = new FormGroup({});
  currentModule = new FormModule();

  showSubmitButton = false;
  membershipApplicationType: string;
  applicationInProgress: boolean;
  applicantId: string;
  route: ActivatedRoute;
  groups: FormFieldGroup[];
  dynamicProfileFormGroup = new FormGroup({});
  fieldsPrerequisite = new FieldsPrerequisite();
  addressDetails: AddressDetail[];
  subscription: Subscription;
  onLastModule: boolean;
  ampTimeout: number;
  unsubscribe = new Subject();
  disableNextButton: boolean = false;

  constructor(
    private formBuilderService: FormBuilderService,
    private formStateService: FormStateService,
    private userProfileService: UserProfileService,
    private applicantService: ApplicantService,
    private managerService: ModuleManagerService,
    private router: Router,
    private navService: NavService,
    private changeDetector: ChangeDetectorRef,
    private dialog: MatDialog
  ) {
    this.subscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (!event.url.endsWith('submit')) {
          this.navService.refreshWindow();
        }
      }
    });
  }

  ngOnInit() {
    this.membershipApplicationType = this.applicantService.getSelectedApplicationType();
    this.applicationInProgress = this.applicantService.getIsApplicationInProgress();
    this.applicantId = this.applicantService.getApplicantId();

    this.userProfileService.getEditProfileFormOptions()
      .subscribe(options => {
        this.formOptions = (options);
        this.formOptions.find(x => x.key === Constants.UserProfileFormOptions.Countries).value
          .forEach(x => {
            var swap = x.preferenceCode;
            x.preferenceCode = x.preferenceSubCode;
            x.preferenceSubCode = swap;
          })
        if (this.applicantService.getApplicationTypeId().toLocaleLowerCase() === Constants.MemberApplicationTypes.DomesticMedicalStudentId) {
          this.userProfileService.getDomesticMedicalSchools().subscribe(x => {
            this.formOptions.push({ key: Constants.FormModuleTitle.DomesticMedicalSchool, value: x });
          });
        }


        this.managerService.getModuleList().subscribe((moduleList) => {
          if (this.applicationInProgress) {
            this.managerService.updateParentModule()
              .subscribe((response) => {
                this.parentModel = response;
                this.parentModel.buildModules();
                this.moduleHeaderComponent.updateModuleListFromParent(moduleList);
                this.parentModel.modules.forEach((module) => {
                  this.moduleHeaderComponent.updateCompletionStatus(module);
                });
                this.userProfileService.setCountryBinding(this.parentModel, this.fieldsPrerequisite, this.formOptions);
              });
          } else {
            this.parentModel = this.managerService.buildParent();
            this.moduleHeaderComponent.updateModuleListFromParent(moduleList);
          }
        });
      });

    this.userProfileService.userDataDoneLoading.subscribe(x => {
      this.loading = false;
    });

    // display dialog here
    this.openAutomaticSaveDialog();

  }

  getModalConfig() {
    return {
      panelClass: 'amp-dialog',
      disableClose: true,
      autoFocus: false,
    } as MatDialogConfig;
  }

  openAutomaticSaveDialog() {
    this.dialog.open(AutomaticSaveDialogComponent, this.getModalConfig());
  }

  private setupModuleHeaderFromSchema() {
    this.managerService.getModuleList().subscribe((response) => {
      this.moduleHeaderComponent.updateModuleListFromParent(response);
    });
  }

  public getFormFields(moduleTitle: string) {
    this.moduleTitle = moduleTitle;
    this.managerService.getModule(moduleTitle, this.parentModel)
      .subscribe((module) => {
        this.currentModule = module;
        this.moduleName = module.displayName;
        this.groups = module.formFieldGroups;
        this.formFields = this.getNestedFormFields(this.groups);
        this.populateForm(this.formFields);
        this.setDisabledFields(this.formFields);
        this.currentModuleFormGroup = this.dynamicProfileFormGroup.get(moduleTitle) as FormGroup;
      });
  }

  public getNestedFormFields(formFieldGroups): FormField[] {
    const formfields = new Array<FormField>();
    formFieldGroups.forEach(group => {
      group.formFields.forEach(formField =>
        formfields.push(formField)
      );
    });
    return formfields;
  }
  saveModule(): FormModule {

    const module = new FormModule;
    module.name = this.currentModule.name;
    module.displayName = this.currentModule.displayName;
    module.id = this.currentModule.id;
    module.sortOrder = this.currentModule.sortOrder;
    module.description = this.currentModule.description;
    module.version = this.currentModule.version;
    this.groups.forEach(item => {
      module.formFieldGroups.push(item);
    });
    module.allFieldsValid = true;
    module.moduleTouched = true;
    this.parentModel = this.managerService.saveFormState(module, this.parentModel);
    if (this.managerService.needsSavingToProtech(this.currentModule.displayName)) {
      this.userProfileService.saveToProtech(module);
    }
    this.currentModule = this.parentModel.modules.find(x => x.id === this.currentModule.id);
    this.userProfileService.setCountryBinding(this.parentModel, this.fieldsPrerequisite, this.formOptions);
    return module;
  }

  private setDisabledFields(fields: FormField[]) {
    if (fields === null || fields === undefined) {
      return;
    }

    fields.forEach(controller => {
      if (controller.controlledFields != null && controller.controlledFields.length > 0) {
        const filteredFields = fields.filter(f => controller.controlledFields.includes(f.id));
        filteredFields.forEach(ff => ff.fieldIsDisabled = true);
      }
    });
  }

  private populateForm(fields: FormField[]) {
    this.fieldsPrerequisite.formModuleTitle = this.currentModule.name;
    this.fieldsPrerequisite.formModuleId = this.currentModule.id;
    if (fields === null || fields === undefined) {
      return;
    }

    const configuration = this.formBuilderService.setUpForm(fields);
    this.dynamicProfileFormGroup.addControl(this.fieldsPrerequisite.formModuleTitle, configuration.formGroup);
    this.loading = true;
    this.userProfileService.getContact().subscribe((c => {
      this.userProfileService.getAndSetUserData(
        this.dynamicProfileFormGroup, fields, this.fieldsPrerequisite, c);
    }));
  }

  public addComponentToArray(event: FormField) {
    this.groups.forEach(group => group.formFields.forEach(function (element) {
      if (element.name === event.name) {
        element = event;
      }
    }));
  }

  lastModuleRecipient(onLastModule: boolean) {
    this.onLastModule = onLastModule;
  }

  nextModule() {
    //disable the button
    this.disableNextButton = true;
    this.loading = true;

    this.dynamicProfileFormGroup.updateValueAndValidity();
    var savedModule = this.saveModule()
    this.formStateService.saveState(savedModule).subscribe(x => {
      //enable the button
      this.disableNextButton = false;
      
      if (this.onLastModule) {
        this.navService.redirectToSubmit();
      } else {
        this.moduleHeaderComponent.updateCompletionStatus(this.currentModule);
        this.moduleHeaderComponent.nextModule(this.currentModule);
      }
    });
  }

  checkModuleValidity(): boolean {
    const moduleFormGroup = this.dynamicProfileFormGroup.get(this.currentModule.displayName);
    if (moduleFormGroup.valid) {
      return true;
    } else {
      return false;
    }
  }

  previousModule() {
    this.moduleHeaderComponent.previousModule();
  }

  previousModuleValidatorUpdate(formModuleName: string) {
    this.dynamicProfileFormGroup.removeControl(formModuleName);
  }

  onSubmit() {
    this.navService.redirectToSubmit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }
}
