import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Preference } from '@shared/models/data';
import { AddressDetail } from '@shared/models/data/AddressDetail';
import { AddressField } from '@shared/models/data/AddressField';
import { FieldsPrerequisite } from '@shared/models/data/fieldsPrerequisite';
import { UserProfileService } from '@shared/services/user-profile.service';
import { Constants } from '@shared/services/constants/constants';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { FormValidatorService } from '@shared/services/forms/form-validator.service';
import { FormField } from '@shared/form-fields/models/form-field';
import { ApplicantService } from '@shared/services/forms/applicant.service';

@Component({
  selector: 'app-aaos-medical-student-address',
  templateUrl: './medical-student-address.component.html',
  styleUrls: ['./medical-student-address.component.scss']
})
export class MedicalStudentAddressComponent implements OnInit {

  countryCode: string = 'US';
  @Input() field: FormField;
  addressStructure: AddressField[];
  @Input() addressDetails: AddressDetail[];
  addressDetail: AddressDetail;
  updatedAddress = new AddressDetail();
  states: Preference[] = [];
  @Input() fieldsPrerequisite: FieldsPrerequisite;
  @Input() dynamicForm: FormGroup;
  baseFormGroup = new FormGroup({});
  loading = true;
  @Output() componentChanged = new EventEmitter();
  statesLoaded = false;
  @Input() applicationCountries: Preference[]
  applicationType: string;
  populatedForm: boolean = false;

  @ViewChildren('addressInfo') addressInfo: QueryList<any>;

  autocomplete: google.maps.places.Autocomplete;
  address1Field: HTMLInputElement;

  constructor(
    private userProfileService: UserProfileService,
    private applicantService: ApplicantService
  ) {

  }

  ngOnInit() {
    this.applicationType = this.applicantService.getSelectedApplicationType();
    this.setDomesticOrInt();
    this.getAddress();
  }

  setDomesticOrInt() {
    if (!this.applicationCountries) {
      return;
    }
    if (this.applicationType.includes('Domestic') && this.field.name.includes('School')) {
      this.applicationCountries = this.applicationCountries.filter(x => x.preferenceCode === 'US' || x.preferenceCode === 'CA');
    }
  }

  initAutocomplete() {
    this.address1Field =
      document.querySelector(`#${Constants.AddressSubFieldNameConstants.AddressLine1}_`) as HTMLInputElement;


    // Create the autocomplete object
    this.autocomplete = new google.maps.places.Autocomplete(this.address1Field, {
      fields: ['address_components'],
      types: ['address'],
    });
    // this.address1Field.focus();

    // When the user selects an address from the drop-down, populate the
    // address fields in the form.
    google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
      this.fillInAddress();
    });
  }

  fillInAddress() {
    // Get the place details from the autocomplete object.
    const place = this.autocomplete.getPlace();
    let address1 = '';
    let city = '';
    let state = '';
    let postcode = '';
    let country = '';

    // Get each component of the address from the place details,
    // and then fill-in the corresponding field on the form.
    // place.address_components are google.maps.GeocoderAddressComponent objects
    // which are documented at http://goo.gle/3l5i5Mr
    for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
      // @ts-ignore remove once typings fixed
      const componentType = component.types[0];

      switch (componentType) {
        case 'street_number': {
          address1 = `${component.long_name} ${address1}`;
          break;
        }

        case 'route': {
          address1 += component.short_name;
          break;
        }

        case 'postal_code': {
          postcode = `${component.long_name}${postcode}`;
          break;
        }

        case 'postal_code_suffix': {
          postcode = `${postcode}-${component.long_name}`;
          break;
        }

        case 'locality':
          if (component.long_name !== '') {
            city = component.long_name;
          }
          break;

        case 'sublocality_level_1':
          city = component.long_name;
          break;

        case 'administrative_area_level_1': {
          state = component.short_name;
          break;
        }

        case 'country': {
          country = component.long_name
          break;
        }
      }
    }

    this.baseFormGroup.get(Constants.AddressSubFieldNameConstants.AddressLine1).setValue(address1);
    this.baseFormGroup.get(Constants.AddressSubFieldNameConstants.City).setValue(city);
    this.baseFormGroup.get(Constants.AddressSubFieldNameConstants.State).setValue(state);
    this.baseFormGroup.get(Constants.AddressSubFieldNameConstants.PostalCode).setValue(postcode);

    var applicationCountry = this.applicationCountries.find(x => x.preferenceDescription === country)
    if (applicationCountry.preferenceDescription === country) {
      this.baseFormGroup.get(Constants.AddressSubFieldNameConstants.CountryCode).setValue(country);
      this.countryCode = applicationCountry.preferenceCode;
      this.baseFormGroup.updateValueAndValidity();
    }
    this.getAddressStructure();


    this.detectInputChange();
  }

  getAddressStructure() {

    forkJoin([this.userProfileService.getStates(this.countryCode),
    this.userProfileService.getAddressStructure(this.countryCode)]).subscribe(latest => {
      const [states, addressStructure] = latest;
      this.states = states;
      this.statesLoaded = true;
      this.addressStructure = addressStructure;
      this.addressInfo.changes.subscribe(t => {
        this.initAutocomplete();
      });

      this.addressStructure = this.addressStructure.filter(field =>
        (field.fieldName === Constants.AddressSubFieldNameConstants.AddressLine1) ||
        (field.fieldName === Constants.AddressSubFieldNameConstants.AddressLine2) ||
        (field.fieldName === Constants.AddressSubFieldNameConstants.City) ||
        (field.fieldName === Constants.AddressSubFieldNameConstants.State) ||
        (field.fieldName === Constants.AddressSubFieldNameConstants.PostalCode ||
          (field.fieldName === Constants.AddressSubFieldNameConstants.CountryCode)));
      this.sortAddressStructure();
      if (!this.populatedForm) {
        this.addControlsToFormGroup();
        this.populateFormControl();
      }

      if (states.length !== 0 && this.baseFormGroup.get(Constants.AddressSubFieldNameConstants.State) && !this.states.find(x => x.preferenceCode === this.baseFormGroup.get(Constants.AddressSubFieldNameConstants.State).value)) {
        this.baseFormGroup.get(Constants.AddressSubFieldNameConstants.State).setValue('');
        this.baseFormGroup.setErrors(Validators.required);
      }

      this.setDomesticOrInt();
      this.validateFormGroup();
      this.loading = false;

    });

  }

  sortAddressStructure() {
    this.addressStructure.sort((a, b) => a.position - b.position);
    this.addressStructure.sort((a, b) => a.lineNumber - b.lineNumber);
  }

  invalidRequiredField(fieldName: string): boolean {
    if (this.baseFormGroup.get(fieldName).hasError('required')) {
      return true;
    }
    return false;
  }

  detectInputChange() {
    this.updatedAddress.address1 = this.baseFormGroup.get(Constants.AddressSubFieldNameConstants.AddressLine1).value;
    this.updatedAddress.address2 = this.baseFormGroup.get(Constants.AddressSubFieldNameConstants.AddressLine2).value;
    this.updatedAddress.city = this.baseFormGroup.get(Constants.AddressSubFieldNameConstants.City).value;
    this.updatedAddress.state = this.baseFormGroup.get(Constants.AddressSubFieldNameConstants.State).value;
    this.updatedAddress.postalCode = this.baseFormGroup.get(Constants.AddressSubFieldNameConstants.PostalCode).value;
    this.updatedAddress.country = this.baseFormGroup.get(Constants.AddressSubFieldNameConstants.CountryCode).value;
    this.updatedAddress.countryCode = this.applicationCountries.find(x => x.preferenceDescription == this.updatedAddress.country)?.preferenceCode;
    this.updatedAddress.isNew = this.addressDetail ? this.addressDetail.isNew : true;
    this.updatedAddress.addressId = this.addressDetail ? this.addressDetail.addressId : 0;
    this.updatedAddress.isPrimaryAddress = this.field.name.toLowerCase().includes('school');
    this.baseFormGroup.updateValueAndValidity();
    this.validateFormGroup();
    this.field.value = JSON.stringify(this.updatedAddress);
    this.componentChanged.emit(JSON.stringify(this.updatedAddress));
  }

  invalidPostalCodeField(fieldName: string): boolean {
    if ((fieldName === 'PostalCode') && (this.baseFormGroup.get('PostalCode').hasError('pattern'))) {
      return true;
    }
    return false;
  }

  addControlsToFormGroup() {
    this.dynamicForm.addControl(this.fieldsPrerequisite.formModuleTitle, this.baseFormGroup);
    this.addressStructure.forEach(addressField => {
      const validators = [];
      if (addressField.requiredFlag) {
        validators.push(Validators.required);
      }
      if (addressField.fieldName.toLowerCase() === Constants.AddressSubFieldNameConstants.PostalCode.toLowerCase()
        && this.fieldsPrerequisite.countryCode === Constants.CountryConstants.US) {
        validators.push(FormValidatorService.getZipCodeValidator());
      }
      this.baseFormGroup.addControl(addressField.fieldName, new FormControl(null, { validators }));
    });
  }

  populateFormControl() {
    if (!this.addressDetail) return;
    this.baseFormGroup.get(Constants.AddressSubFieldNameConstants.AddressLine1).setValue(this.addressDetail.address1);
    this.baseFormGroup.get(Constants.AddressSubFieldNameConstants.AddressLine2).setValue(this.addressDetail.address2);
    this.baseFormGroup.get(Constants.AddressSubFieldNameConstants.City).setValue(this.addressDetail.city);
    if (this.states && this.states.length !== 0 && !this.states.map(x => x.preferenceCode).includes(this.addressDetail.state)) {
      this.baseFormGroup.get(Constants.AddressSubFieldNameConstants.State).setValue(null);
    } else {
      this.baseFormGroup.get(Constants.AddressSubFieldNameConstants.State).setValue(this.addressDetail.state);
    }
    this.baseFormGroup.get(Constants.AddressSubFieldNameConstants.PostalCode).setValue(this.addressDetail.postalCode);
    this.baseFormGroup.get(Constants.AddressSubFieldNameConstants.CountryCode).setValue(this.addressDetail.country);
    this.populatedForm = true;

    // if something is filled out, lets state that we detect input change so that we can capture this data
    if (this.addressDetail.address1 ||
      this.addressDetail.address2 ||
      this.addressDetail.city ||
      this.addressDetail.state ||
      this.addressDetail.postalCode ||
      this.addressDetail.country) {
      this.detectInputChange();
    }
  }

  isAddressPrimary() {
    if (this.addressDetail.isPrimaryAddress === true) {
      return true;
    }
    return false;
  }

  validateFormGroup() {
    if (this.baseFormGroup.invalid || this.baseFormGroup.status === "INVALID") {
      this.dynamicForm.get(this.fieldsPrerequisite.formModuleTitle).setErrors(Validators.required);
    } else {
      this.dynamicForm.get(this.fieldsPrerequisite.formModuleTitle).setErrors(null);
    }
  }

  getAddress() {
    if (this.field.value) {
      this.addressDetail = JSON.parse(this.field.value);
      this.countryCode = this.addressDetail.countryCode;
      this.getAddressStructure();
    } else {
      this.userProfileService.getUserAddresses().subscribe(x => {
        if (this.field.name.toLowerCase().includes('school')) {
          this.addressDetail = x.find(address => address.isPrimaryAddress === true);
        } else if (this.field.name.toLowerCase().includes('home')) {
          this.addressDetail = x.find(address => address.prioritySequence === 2);
        }
        if (this.addressDetail) {
          this.countryCode = this.addressDetail.countryCode;
          this.addressDetail.isNew = false;
        }
        this.getAddressStructure();
      });
    }
  }

  resetAddressStructure(countryCode: any) {
    this.loading = true;
    this.countryCode = this.applicationCountries.find(x => x.preferenceDescription === countryCode.value).preferenceCode;
    this.getAddressStructure();
  }

}
