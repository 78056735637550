<div *ngIf="fieldsPrerequisite.multipleAddresses">
  <div *ngIf="(!isAddressPrimary())">
    <div id="{{addressDetail.addressId}}" class="delete-file-button" (click)="deleteAddress($event.target.id)"></div>
  </div>
</div>
<div *ngIf="!loading">
  <div class="form-group" #addressInfo [formGroup]="baseFormGroup" *ngFor="let addressField of addressStructure;">
    <div *ngIf="addressField.fieldName !=='State'">
      <div *ngIf="addressField.fieldName !== 'Address1' &&
    addressField.fieldName !== 'Address2' &&
    addressField.fieldName !== 'Address3'" class="
    title title--xs">{{addressField.caption}}</div>
      <input class="form-control" formControlName="{{addressField.fieldName}}" id="{{addressField.fieldName}}_{{addressIndex}}" (blur)="detectInputChange($event)"
        [ngClass]="{ 'half-width': addressField.fieldName === 'PostalCode' }" placeholder="{{ addressField.caption }}"
        required="{{ addressField.requiredFlag }}" type="text" maxlength="{{ addressField.MaxLength }}" />
    </div>


    <div *ngIf="addressField.fieldName === 'State' && (statesLoaded === true && states.length === 0) === false">
      <div class="title title--xs">{{addressField.caption}}</div>
      <mat-select (blur)="detectInputChange($event)" required="{{ addressField.requiredFlag }}" formControlName="{{addressField.fieldName}}" disableRipple disableOptionCentering
        panelClass="mat-panel">
        <mat-option *ngFor="let state of states" [value]="state.preferenceCode">{{
        state.preferenceDescription
      }}</mat-option>
      </mat-select>
    </div>
    <div *ngIf="addressField.fieldName ==='State' && statesLoaded === true && states.length === 0">
      <div class="title title--xs">{{addressField.caption}}</div>
      <input class="form-control" formControlName="{{addressField.fieldName}}" id="{{addressField.fieldName}}_{{addressIndex}}" (blur)="detectInputChange($event)"
        placeholder="{{ addressField.caption }}"
        required="{{ addressField.requiredFlag }}" type="text" maxlength="{{ addressField.MaxLength }}" />
    </div>

    
    <div class="required-text" *ngIf="invalidRequiredField(addressField.fieldName)">
      {{ addressField.caption }} is required
    </div>
    <div class=" required-text" *ngIf="invalidPostalCodeField(addressField.fieldName)">
      Please enter a valid postal code
    </div>
  </div>
</div>
<app-aaos-spinner [isLoading]="loading"></app-aaos-spinner>