import { FormModule, FormModuleAdapter } from './form-module.model';
import { ApplicantApplication } from '../serialization/applicant-application.model';

export class ApplicationParent extends ApplicantApplication {

    constructor(private moduleAdapter: FormModuleAdapter) {
        super();
     }

    modules: FormModule[] = [];

    buildModules() {
        if (this.FormState) {
            const moduleArray: Array<any> = JSON.parse(this.FormState);
            this.modules = moduleArray.map((item: any) => this.moduleAdapter.adapt(item));
        }
    }
}
