import { NgModule } from '@angular/core';
import { RouterModule, Routes, Route } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HomeComponent } from '../home/home.component';
import { RouteNames } from './route-utils';
import { PageNotFoundComponent } from '../page-not-found/page-not-found.component';
import { LoggedInGuard, ClearCookiesGuard } from '@shared/guards';
import { DynamicFormComponent } from '../forms/dynamic-form/dynamic-form.component';
import { LoginComponent } from '../login/login.component';
import { QuestionnaireFormComponent } from '../forms/questionnaire-form/questionnaire-form.component';
import { LandingPageComponent } from '../landing-page/landing-page.component';
import { AdminPageComponent } from '../admin-components/admin-page/admin-page.component';
import { ViewApplicantComponent } from '../admin-components/view-applicant/view-applicant.component';
import { AdminGuard } from '@shared/guards/admin.guard';
import { SubmitFormComponent } from '../forms/submit-form/submit-form.component';
import { ApplicationStatusComponent } from '../application-status/application-status.component';
import { PaymentFormComponent } from '../forms/payment-form/payment-form.component';
import { ThankYouComponent } from '../thank-you/thank-you.component';
import { ReferralComponent } from '../referral/referral.component';
import { UnchangeableMemberTypeComponent } from '../unchangeable-member-type/unchangeable-member-type.component';
import { ApplicationConfigurationPageComponent } from '../admin-components/application-configuration/application-configuration-page/application-configuration-page.component';
import { AdminLandingComponent } from '../admin-components/admin-landing/admin-landing.component';
import { UnpaidNotificationsPageComponent } from '../admin-components/unpaid-notifications-page/unpaid-notifications-page.component';

const pageNotFound: Route = {
    path: RouteNames.CatchAll,
    component: PageNotFoundComponent
};

const viewApplicant: Route = {
    canActivate: [LoggedInGuard, AdminGuard],
    path: RouteNames.ViewApplicant,
    component: ViewApplicantComponent
};

const main: Route = {
    canActivate: [LoggedInGuard],
    path: RouteNames.Root,
    redirectTo: RouteNames.Landing,
    pathMatch: 'full'
};

const landing: Route = {
    canActivate: [LoggedInGuard],
    path: RouteNames.Landing,
    component: LandingPageComponent
};

const form: Route = {
    canActivate: [LoggedInGuard],
    path: RouteNames.Form,
    component: DynamicFormComponent
};

const thankyou: Route = {
    canActivate: [LoggedInGuard],
    path: RouteNames.ThankYou,
    component: ThankYouComponent
};

const adminLanding: Route = {
    canActivate: [LoggedInGuard, AdminGuard],
    path: RouteNames.AdminLanding,
    component: AdminLandingComponent
};

const unchangeableMemberType: Route = {
    canActivate: [LoggedInGuard],
    path: RouteNames.UnchangeableMemberType,
    component: UnchangeableMemberTypeComponent
};

const referral: Route = {
    path: RouteNames.Referral,
    component: ReferralComponent
};

const admin: Route = {
    canActivate: [LoggedInGuard, AdminGuard],
    path: RouteNames.Admin,
    component: AdminPageComponent
};

const submit: Route = {
    canActivate: [LoggedInGuard],
    path: RouteNames.Submit,
    component: SubmitFormComponent
};

const pay: Route = {
    canActivate: [LoggedInGuard],
    path: RouteNames.Pay,
    component: PaymentFormComponent
};

const login: Route = {
    path: RouteNames.Login,
    component: LoginComponent
};

const questionnaire: Route = {
    canActivate: [LoggedInGuard],
    path: RouteNames.Questionnaire,
    component: QuestionnaireFormComponent
};

const applicantStatus: Route = {
    canActivate: [LoggedInGuard],
    path: RouteNames.Status,
    component: ApplicationStatusComponent
};

const applicationConfiguration: Route = {
    canActivate: [LoggedInGuard, AdminGuard],
    path: RouteNames.ApplicationConfiguration,
    component: ApplicationConfigurationPageComponent
};

const unpaidNotifications: Route = {
    canActivate: [LoggedInGuard, AdminGuard],
    path: RouteNames.UnpaidNotifications,
    component: UnpaidNotificationsPageComponent
};

const root: Route = {
    path: RouteNames.Root,
    children: [
        questionnaire,
        main,
        form,
        login,
        landing,
        admin,
        viewApplicant,
        submit,
        applicantStatus,
        pay,
        thankyou,
        referral,
        unchangeableMemberType,
        applicationConfiguration,
        adminLanding,
        unpaidNotifications
    ]
};

const routes: Routes = [root];

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        RouterModule.forRoot(routes),
    ],
    providers: [LoggedInGuard, ClearCookiesGuard, AdminGuard],
    exports: [RouterModule]
})
export class AppRoutingModule { }
