<script src="https://code.iconify.design/1/1.0.7/iconify.min.js"></script>
<section class="content">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-5">
        <div class="application-info">
          <h1>Thank You For Applying</h1>
        </div>
        <div class="space-top">
          <p>
            An email has been sent to your primary email address confirming your submission. It will contain a link to
            track the progress of your application.
          </p>
        </div>
        <div class="space-top">
          <h3>Refer a Colleague!</h3>
        </div>
        <div>
          <div class="row">
            <div class="col">
              <div class="card-i" (click)="linkClick('email')">
                <span class="img-layout email-icon"></span>
                Email
              </div>
            </div>
            <div class="col">
              <div class="card-i" (click)="linkClick('linkedin')">
                <span class="img-layout linkedin-icon"></span>
                LinkedIn
              </div>
            </div>
          </div>
          <div class="row tile-space-top">
            <div class="col">
              <div class="card-i" (click)="linkClick('facebook')">
                <span class="img-layout facebook-icon"></span>
                Facebook
              </div>
            </div>
            <div class="col">
              <div class="card-i" (click)="linkClick('twitter')">
                <span class="img-layout twitter-icon"></span>
                Twitter
              </div>
            </div>
          </div>
          <!-- <div class="row justify-content-center tile-space-top">
            <div class="col-12 col-sm-6">
              <div class="card-i" (click)="linkClick('tumblr')">
                <span class="img-layout tumbler-icon"></span>
                Tumblr
              </div>
            </div>
            <div class="col">
              <div class="card-i" (click)="linkClick('google')">
                <span class="img-layout google-icon"></span>
                Google+
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</section>
