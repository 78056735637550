import { Component, OnInit, Output, OnChanges, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-main-navigation',
  templateUrl: './main-navigation.component.html',
  styleUrls: ['./main-navigation.component.scss'],
})
export class MainNavigationComponent implements OnInit, OnChanges {
  nextClass = '';
  backClass = '';
  @Input() nextButtonText = 'Next';
  @Input() onLastModule = false;

  @Input() nextEnabled: boolean;
  @Input() backEnabled: boolean;
  @Output() nextSelected: EventEmitter<any> = new EventEmitter();
  @Output() backSelected: EventEmitter<any> = new EventEmitter();
  @Output() completeSelected: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {
    this.disableEnableButtons();
  }

  ngOnChanges() {
    this.disableEnableButtons();
  }

  clickNext() {
    if (this.nextEnabled) {
      this.nextSelected.emit(this);
    }
  }

  onComplete() {
    if (this.nextEnabled) {
      this.completeSelected.emit(this);
    }
  }

  clickBack() {
    if (this.backEnabled) {
      this.backSelected.emit(this);
    }
  }

  disableEnableButtons() {
    if (!this.backEnabled) {
      this.backClass = 'btn--disabled';
    } else {
      this.backClass = '';
    }

    if (!this.nextEnabled) {
      this.nextClass = 'btn--disabled';
    } else {
      this.nextClass = '';
    }
  }
}
