import { Component, OnInit } from '@angular/core';
import { ApplicantService } from '@shared/services/forms/applicant.service';
import { NavService } from '@shared/services/nav.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  constructor(private applicantService: ApplicantService,
    private navService: NavService
  ) { }

  ngOnInit(): void {

    this.applicantService.getRoles().subscribe((response: any) => {
      if (response === 'admin') {
        this.navService.adminLanding();
        return;
      } else if (response === 'staff') {
        this.navService.redirectToUnchangeableMemberType();
        return;
      } else {
        this.applicantService.getApplicant().subscribe((applicant: any) => {
          if (applicant !== null && applicant !== undefined && applicant.previousApplication === true) {
            if (applicant.applicantApplicationType.status.name.toLowerCase() !== 'incomplete') {
              this.navService.redirectToApplicantStatus();
            } else {
              this.navService.redirectToForm();
            }
          } else {
            this.navService.redirectToQuestionnaire();
          }
          return;
        });
      }
    });


  }

}
