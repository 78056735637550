import { Injectable } from '@angular/core';
import { AppConfigService } from '@config/app-config.service';
import { FormFieldConstants } from '@shared/form-fields/models/form-field';
import { ProfileFormOption, Preference } from '@shared/models/data';
import { Constants } from '@shared/services/constants/constants';

@Injectable({
  providedIn: 'root'
})
export class FormOptionsMapperService {


  constructor(private appConfigService: AppConfigService) {}

  getOptionsForField(fieldId: string, formOptions: ProfileFormOption[]): Preference[] {
    let options: Preference[];
    const lowerCaseFieldId = fieldId.toLowerCase();
    if (lowerCaseFieldId === FormFieldConstants.PrimaryLanguage.toLowerCase()) {
      options = this.searchFormOption(Constants.UserProfileFormOptions.Languages, formOptions);
    } else if (lowerCaseFieldId === FormFieldConstants.SecondLanguage.toLowerCase()) {
      options = this.searchFormOption(Constants.UserProfileFormOptions.Languages, formOptions);
    } else if (lowerCaseFieldId === FormFieldConstants.ThirdLanguage.toLowerCase()) {
      options = this.searchFormOption(Constants.UserProfileFormOptions.Languages, formOptions);
    } else if (lowerCaseFieldId === FormFieldConstants.CountryOfResidence.toLowerCase()) {
      options = this.searchFormOption(Constants.UserProfileFormOptions.Countries, formOptions);
    } else if (lowerCaseFieldId === FormFieldConstants.CountryOfEmployment.toLowerCase()) {
      options = this.searchFormOption(Constants.UserProfileFormOptions.Countries, formOptions);
    } else if (lowerCaseFieldId === FormFieldConstants.CountryOfCitizenship.toLowerCase()) {
      options = this.searchFormOption(Constants.UserProfileFormOptions.Countries, formOptions);
    } else if (lowerCaseFieldId === FormFieldConstants.BirthPlace.toLowerCase()) {
      options = this.searchFormOption(Constants.UserProfileFormOptions.Countries, formOptions);
    } else if (lowerCaseFieldId === FormFieldConstants.PracticeSpecializationsPrimary.toLowerCase()) {
      options = this.searchFormOption(Constants.UserProfileFormOptions.PracticeSpecializationsPrimary, formOptions);
    } else if (lowerCaseFieldId === FormFieldConstants.OrthoteamRole.toLocaleLowerCase()) {
      options = this.getOrthoteamRoles();
    } else if (lowerCaseFieldId === FormFieldConstants.DomesticMedicalSchools.toLocaleLowerCase()) {
      options = this.searchFormOption(Constants.UserProfileFormOptions.DomesticMedicalSchools, formOptions);
    } else if(lowerCaseFieldId == FormFieldConstants.EthnicitiesId.toLocaleLowerCase()) {
      options = this.searchFormOption(Constants.UserProfileFormOptions.Ethnicities, formOptions);
    } else if(lowerCaseFieldId == FormFieldConstants.GenderId.toLocaleLowerCase()) {
      options = this.searchFormOption(Constants.UserProfileFormOptions.Genders, formOptions);
    } else if(lowerCaseFieldId == FormFieldConstants.SexualOrientationId.toLocaleLowerCase()) {
      options = this.searchFormOption(Constants.UserProfileFormOptions.SexualOrientation, formOptions);
    } else if(lowerCaseFieldId == FormFieldConstants.GenderPronounsId.toLocaleLowerCase()) {
      options = this.searchFormOption(Constants.UserProfileFormOptions.Pronouns, formOptions);
    } else if(lowerCaseFieldId == FormFieldConstants.CredentialsId.toLocaleLowerCase()) {
      options = this.searchFormOption(Constants.UserProfileFormOptions.Credentials, formOptions);
    }
    return options;
  }
  private searchFormOption(optionName: string, formOptions: ProfileFormOption[]): Preference[] {
    const communicationTypesFiltered = formOptions.find(x => x.key.toLowerCase() === optionName.toLowerCase());
    if (communicationTypesFiltered) {
      return communicationTypesFiltered.value;
    }
    return [];
  }

  private getOrthoteamRoles(): any {
    var roles = this.appConfigService.appConfig.orthoteamRoles;
    return roles;
  }
}
