import { Injectable } from '@angular/core';
import { AaosBaseService } from '@shared/services/aaos-base.service';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Constants } from '@shared/services/constants/constants';
import { SubmitApplicationResponse } from '@shared/models/data/SubmitApplicationResponse';
import { ApplicationValidationError } from '@shared/models/data/ApplicationValidationError';
import { HttpParams } from '@angular/common/http';
import * as FileSaver from 'file-saver';
import { MemberApplicationType } from '@shared/models/data/MemberApplicationType';
import { ApplicationTypeQuestion } from '@shared/models/data/ApplicationTypeQuestion';
import { MemberApplicationTypeParent } from '@shared/models/data/MemberApplicationTypeParent';

@Injectable({
  providedIn: 'root'
})
export class ApplicantService extends AaosBaseService {

  public getRoles(): Observable<any> {
    return this.http.get(Constants.ApiUrls.GetRoles, { responseType: 'json' })
      .pipe(
        catchError(catchResponse => this.handleError(catchResponse)),
        map((response: any) => {
          return response;
        })
      );
  }

  public getOrderId(discountCode?: string): Observable<any> {
    let params;
    if (discountCode) {
      params = new HttpParams().set('discountCode', discountCode);
    }

    return this.http.get(Constants.ApiUrls.GetOrder, { params })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(err => {
          this.handleError(err);
          return of(null);
        })
      );
  }

  public validateDiscountCode(discountCode?: string): Observable<boolean> {
    let params;
    if (discountCode) {
      params = new HttpParams().set('discountCode', discountCode);
    }      
    return this.http.get(Constants.ApiUrls.ValidateDiscountCode, { params })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(err => {
          this.handleError(err);
          return of(null);
        })
      );
  }

  public saveDiscountCode(discountCode?: string): Observable<any> {

    return this.http.put(Constants.ApiUrls.SaveDiscountCode, JSON.stringify(discountCode))
      .pipe(
        map(response => {
          return response;
        }),
        catchError(err => {
          this.handleError(err);
          return throwError('Something went wrong');
        })
      );
  }

  public getOrderPrice(orderId: string): Observable<any> {
    return this.http.get(`${Constants.ApiUrls.GetOrderPrice}/${orderId}`, { responseType: 'json' })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(err => {
          this.handleError(err);
          return of(null);
        })
      );
  }

  public doesCurrentPaidOrderExist(): Observable<any> {
    return this.http.get(Constants.ApiUrls.DoesCurrentPaidOrderExist, { responseType: 'json' })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(err => {
          this.handleError(err);
          return of(null);
        })
      );
  }

  // maybe remove the responsetype as array buffer and see what the response from the server is?
  public getApplicantPdf(guids: string[]): Observable<any> {
    return this.http.post<any>(Constants.ApiUrls.GetApplicantPdf, guids, {
      observe: 'response',
      responseType: 'blob' as 'json',
    }).pipe(
      map(response => {
        const contentDisposition = response.headers.get('content-disposition');
        const fileNameStartIndex = contentDisposition.indexOf('filename=');
        const endIndex = contentDisposition.indexOf(';', fileNameStartIndex);
        const fileName = contentDisposition.substring(fileNameStartIndex + 9, endIndex);

        const blob = new Blob([response.body], { type: response.body.type });

        FileSaver.saveAs(blob, fileName);

        return response;
      })
    );
  }

  public getApplicationPdf(guids: string[]): Observable<any> {
    return this.http.post<any>(Constants.ApiUrls.GetApplicationPdf, guids, {
      observe: 'response',
      responseType: 'blob' as 'json',
    }).pipe(
      map(response => {
        const contentDisposition = response.headers.get('content-disposition');
        const fileNameStartIndex = contentDisposition.indexOf('filename=');
        const endIndex = contentDisposition.indexOf(';', fileNameStartIndex);
        const fileName = contentDisposition.substring(fileNameStartIndex + 9, endIndex);

        const blob = new Blob([response.body], { type: response.body.type });

        FileSaver.saveAs(blob, fileName);

        return response;
      })
    );
  }

  public getApplicant(): Observable<any> {
    const response = this.http.get(Constants.ApiUrls.GetApplicant, { responseType: 'json' })
      .pipe(
        map((mapResponse: any) => {
          window.sessionStorage.removeItem('applicantId');
          window.sessionStorage.removeItem('mcid');
          window.sessionStorage.removeItem('applicationInProgress');
          window.sessionStorage.removeItem('applicationTypeId');
          window.sessionStorage.removeItem('applicationTypeName');
          window.sessionStorage.removeItem('applicationVersion');
          window.sessionStorage.removeItem('applicationStatusName');
          window.sessionStorage.removeItem('applicationStatusId');
          window.sessionStorage.removeItem('applicationId');
          if ((!mapResponse)) {
            const error = mapResponse.error;
            throw new Error();
          }
          window.sessionStorage.setItem('applicantId', mapResponse.applicantId);
          window.sessionStorage.setItem('mcid', mapResponse.masterCustomerId);
          window.sessionStorage.setItem('applicationInProgress', mapResponse.previousApplication);
          if (mapResponse.previousApplication === true) {
            window.sessionStorage.setItem('applicationId', mapResponse.applicantApplicationType.id);
            window.sessionStorage.setItem('applicationTypeId', mapResponse.applicantApplicationType.applicationType.id);
            window.sessionStorage.setItem('applicationTypeName', mapResponse.applicantApplicationType.applicationType.name);
            window.sessionStorage.setItem('applicationVersion', mapResponse.applicantApplicationType.version);
            window.sessionStorage.setItem('applicationStatusName', mapResponse.applicantApplicationType.status.name);
            window.sessionStorage.setItem('applicationStatusId', mapResponse.applicantApplicationType.status.id);
            window.sessionStorage.setItem('productCode', mapResponse.productCode);
          }

          return mapResponse;
        }),
        catchError(catchResponse => this.handleError(catchResponse)),
        map((mapResponse: any) => {
          return mapResponse;
        })
      );
    return response;
  }

  public getMembershipTypes(dateTime: string = ''): Observable<MemberApplicationTypeParent[]> {
    const memberTypeList = this.http.get(`${Constants.ApiUrls.GetApplicationTypes}?currentDate=${dateTime}`, { responseType: 'json' })
      .pipe(
        map((response: any) => {
          if ((!response)) {
            const error = response.error;
            throw new Error();
          }
          return response;
        }),
        catchError(response => this.handleError(response)),
        map((response: any) => {
          return response;
        })
      );
    return memberTypeList;
  }

  public getDisabledMemberApplicationTypes(): Observable<MemberApplicationType[]> {
    return this.http.get<MemberApplicationType[]>(Constants.ApiUrls.GetDisabledMemberApplicationTypes).pipe();
  }

  public getMembershipQuestions(memberType: string): Observable<ApplicationTypeQuestion[]> {
    const questions = this.http.get(Constants.ApiUrls.GetQuestions + memberType, { responseType: 'json' })
      .pipe(
        map((response: any) => {
          if ((!response)) {
            const error = response.error;
            throw new Error();
          }
          return response;
        }),
        catchError(response => this.handleError(response)),
        map((response: any) => {
          return response;
        })
      );
    return questions;
  }

  public getApplicantId(): string {
    return window.sessionStorage.getItem('applicantId');
  }

  public getApplicationTypeId(): string {
    return window.sessionStorage.getItem('applicationTypeId');
  }

  public getApplicationId(): string {
    return window.sessionStorage.getItem('applicationId');
  }

  public getMcid(): string {
    return window.sessionStorage.getItem('mcid');
  }

  public getSelectedApplicationType(): string {
    return window.sessionStorage.getItem('applicationTypeName');
  }

  public getIsApplicationInProgress(): boolean {
    return window.sessionStorage.getItem('applicationInProgress') === 'true';
  }

  public setSelectedApplicationType(appType: string) {
    window.sessionStorage.removeItem('applicationTypeName');
    window.sessionStorage.setItem('applicationTypeName', appType);
  }

  public  async getApplicationStatusName(): Promise<string> {
    return window.sessionStorage.getItem('applicationStatusName');
  }

  public async getProductCode(): Promise<string> {
      return await window.sessionStorage.getItem('productCode');
  }

  public submitApplication(applicantId: string): Observable<SubmitApplicationResponse> {
    const postData = JSON.stringify(applicantId);

    const model = this.http.post<any>(Constants.ApiUrls.SubmitApplication, postData)
      .pipe(
        map((response: any) => {
          if ((!response)) {
            const error = response.error;
            throw new Error();
          }
          return response;
        }),
        catchError(response => this.handleError(response)),
        map((response: any) => {
          return response;
        })
      );

    return model;
  }

  public validateApplication(applicationId: string): Observable<Array<ApplicationValidationError>> {
    const postData = JSON.stringify(applicationId);

    const model = this.http.post<any>(Constants.ApiUrls.ValidateApplication, postData)
      .pipe(
        map((response: any) => {
          if ((!response)) {
            const error = response.error;
            throw new Error();
          }
          return response;
        }),
        catchError(response => this.handleError(response)),
        map((response: any) => {
          return response;
        })
      );

    return model;
  }
}
