<section id="moduleHeader">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-6">
        <div>
          <h1>{{membershipApplicationType}}</h1>
          <div>
            <mat-label>Step {{moduleCurrentStep}} of {{moduleTotalSteps}}</mat-label>
            <mat-progress-bar mode="determinate" value="{{moduleCurrentStep | percentage:moduleTotalSteps}}"></mat-progress-bar>
            <mat-select [(ngModel)]="selectedOption"  (ngModelChange)="setCurrentModule()" class="moduleSelect">
              <mat-option [value]="module" *ngFor="let module of formModules" [disabled]="!module.moduleTouched">{{module?.moduleStep}}. {{module?.displayName}} 
                <span *ngIf="module?.allFieldsValid" class="icon-checkmark"></span> 
                <span *ngIf="!module?.allFieldsValid" class="icon-flag"></span>
              </mat-option>
            </mat-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
