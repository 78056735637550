import { Component, OnChanges, OnInit } from '@angular/core';
import { BaseFieldComponentDirective } from '../base-field-component';
import { FormValidatorService } from '@shared/services/forms/form-validator.service';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss']
})
export class DateComponent extends BaseFieldComponentDirective implements OnInit, OnChanges {

  isRequired: boolean = true;

  ngOnInit(): void {
    super.ngOnInit();

    if (this.field.displayName.indexOf('Optional') !== -1) {
      this.isRequired = false;
      this.baseFormGroup.get(this.field.name).setErrors(null);
      this.baseFormGroup.get(this.field.name).setValidators(null);

    } else {
      this.baseFormGroup.setValidators([FormValidatorService.getDateFormFieldValidator()]);
    }
    this.baseFormGroup.updateValueAndValidity();
    this.validateFormGroup();
    this.ngOnChanges();
  }

  componentChange(event: any) {
    this.baseFormGroup.updateValueAndValidity();
    this.validateFormGroup();
    this.field.value = event.value;
    this.field.displayValue = new Date(event.value).toDateString();
    this.componentChanged.emit(this.field);
  }

  ngOnChanges() {
    if (this.field.fieldIsDisabled) {
      this.isRequired = false;
      this.baseFormGroup.get(this.field.name)?.setValue(null);
      this.baseFormGroup.get(this.field.name)?.disable();
    } else {
      this.baseFormGroup.get(this.field.name)?.enable();
    }
    this.validateFormGroup();
  }

  invalidDate() {
    if (this.baseFormGroup.hasError('before')) {
      return true;
    }
    return false;
  }

  validateFormGroup() {
    if (!this.baseFormGroup.invalid) {
      this.dynamicForm.get(this.fieldsPrerequisite.formModuleTitle).setErrors(null);
    }
  }
}
