import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Preference } from '@shared/models/data';
import { FieldsPrerequisite } from '@shared/models/data/fieldsPrerequisite';
import { HospitalPrivilegeDetail } from '@shared/models/data/hospital-privilege-detail';
import { UserProfileService } from '@shared/services/user-profile.service';

@Component({
  selector: 'app-hospital-privilege',
  templateUrl: './hospital-privilege.component.html',
  styleUrls: ['./hospital-privilege.component.scss']
})
export class HospitalPrivilegeComponent implements OnInit, AfterViewInit {

  @Input() fieldsPrerequisite: FieldsPrerequisite;
  @Input() hospitalPrivilegeDetail: HospitalPrivilegeDetail;
  @Input() dynamicForm: FormGroup;
  @Output() componentChanged = new EventEmitter();
  @Output() componentDeleted = new EventEmitter();
  @ViewChildren('hospitalInfo') hospitalInfo: QueryList<any>;
  autocomplete: google.maps.places.Autocomplete;
  nameField: HTMLInputElement;
  cityField: HTMLInputElement;
  stateField: HTMLInputElement;

  baseFormGroup = new FormGroup({});
  states: Preference[] = [];
  statesLoaded = false;
  loading = true;
  updatedHospitalPrivilege = new HospitalPrivilegeDetail();

  constructor(private userProfileService: UserProfileService) { }

  ngAfterViewInit(): void {
    this.hospitalInfo.changes.subscribe(t => {
      this.initAutocomplete();
    });
  }

  validateFormGroup() {
    if (this.baseFormGroup.invalid) {
      this.dynamicForm.get(this.fieldsPrerequisite.formModuleTitle).setErrors(Validators.required);
    } else {
      this.dynamicForm.get(this.fieldsPrerequisite.formModuleTitle).setErrors(null);
    }
  }

  ngOnInit() {
    this.addControlsToFormGroup();
    this.validateFormGroup();
    this.getAndSetAddress();
  }

  isDeletable() {
    return this.hospitalPrivilegeDetail.id !== 0;
  }

  deleteHospital(event: any) {
    this.componentDeleted.emit(this.hospitalPrivilegeDetail);
  }

  invalidRequiredField(fieldName: string): boolean {
    if (this.baseFormGroup.get(fieldName).hasError('required')) {
      return true;
    }
    return false;
  }

  detectInputChange() {
    this.updatedHospitalPrivilege.id = this.hospitalPrivilegeDetail.id;
    this.updatedHospitalPrivilege.name = this.baseFormGroup.get('name').value;
    this.updatedHospitalPrivilege.city = this.baseFormGroup.get('city').value;
    this.updatedHospitalPrivilege.state = this.baseFormGroup.get('state').value;
    this.updatedHospitalPrivilege.departmentName = this.baseFormGroup.get('departmentName').value;
    this.validateFormGroup();
    this.componentChanged.emit(this.updatedHospitalPrivilege);
  }


  getAndSetAddress() {
    if (this.fieldsPrerequisite.countryCode) {
      this.userProfileService.getStates(this.fieldsPrerequisite.countryCode).subscribe(latest => {
        this.states = latest;
        this.statesLoaded = true;
        this.populateFormControl();
        this.validateFormGroup();
        this.loading = false;
      },
        err => {
          this.noStatesAvailable();
        });

      // in case we have been trying for 20 seconds, lets just unblock the user. this will show them a textbox instead of dropdown
      setTimeout(() => {
        if (this.statesLoaded === false) {
          this.noStatesAvailable();
        }
      }, 20000);
    } else { // user doesn't have a country code selected for whatever reason, so lets load 0 states to show them a textbox and unblock
      this.noStatesAvailable();
    }
  }

  noStatesAvailable() {
    this.loading = false;
    this.statesLoaded = true;
    this.states = Array<Preference>();
    this.populateFormControl();
    this.validateFormGroup();
  }



  addControlsToFormGroup() {
    this.baseFormGroup.addControl('name', new FormControl(null, Validators.required));
    this.baseFormGroup.addControl('departmentName', new FormControl(null, Validators.required));
    this.baseFormGroup.addControl('city', new FormControl(null, Validators.required));
    this.baseFormGroup.addControl('state', new FormControl(null, Validators.required));
  }

  populateFormControl() {
    this.baseFormGroup.get('name').setValue(this.hospitalPrivilegeDetail.name);
    this.baseFormGroup.get('departmentName').setValue(this.hospitalPrivilegeDetail.departmentName);
    this.baseFormGroup.get('city').setValue(this.hospitalPrivilegeDetail.city);
    this.baseFormGroup.get('state').setValue(this.hospitalPrivilegeDetail.state);
  }

  initAutocomplete() {
    this.nameField =
      document.querySelector(`#name_${this.hospitalPrivilegeDetail.id}`) as HTMLInputElement;

    this.cityField =
      document.querySelector(`#city_${this.hospitalPrivilegeDetail.id}`) as HTMLInputElement;

    this.stateField =
      document.querySelector(`#state_${this.hospitalPrivilegeDetail.id}`) as HTMLInputElement;

    // Create the autocomplete object, restricting the search predictions to
    // addresses in the US and Canada.
    this.autocomplete = new google.maps.places.Autocomplete(this.nameField, {
      componentRestrictions: { country: ['us', 'ca'] },
      fields: ['address_components', 'name'],
      types: ['establishment'],
    });
    // this.address1Field.focus();

    // When the user selects an address from the drop-down, populate the
    // address fields in the form.
    google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
      this.fillInAddress();
    });
  }

  fillInAddress() {
    // Get the place details from the autocomplete object.
    const place = this.autocomplete.getPlace();
    const hospitalName = place.name;
    let city = '';
    let state = '';

    // Get each component of the address from the place details,
    // and then fill-in the corresponding field on the form.
    // place.address_components are google.maps.GeocoderAddressComponent objects
    // which are documented at http://goo.gle/3l5i5Mr
    for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
      // @ts-ignore remove once typings fixed
      const componentType = component.types[0];

      switch (componentType) {
        case 'locality':
          if (component.long_name !== '') {
            city = component.long_name;
          }
          break;

        case 'sublocality_level_1':
          city = component.long_name;
          break;

        case 'administrative_area_level_1': {
          state = component.short_name;
          break;
        }
      }
    }

    this.baseFormGroup.get('name').setValue(hospitalName);
    this.baseFormGroup.get('city').setValue(city);
    this.baseFormGroup.get('state').setValue(state);

    this.stateField.focus();

    this.detectInputChange();
  }
}
