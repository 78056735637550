import { Injectable, OnInit } from '@angular/core';
import { ApplicationParent } from '@shared/models/data/application-parent.model';
import { FormModule, FormModuleAdapter } from '@shared/models/data/form-module.model';
import { ApplicantService } from './applicant.service';
import { FormStateService } from './form-state.service';
import { ModuleService } from './module.service';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Constants } from '../constants/constants';


@Injectable({
  providedIn: 'root'
})
export class ModuleManagerService implements OnInit {

  applicationInProgress: boolean;
  membershipApplicationType: string;
  applicantId: string;

  constructor(
    private moduleService: ModuleService,
    private formStateService: FormStateService,
    private applicantService: ApplicantService,
    private moduleAdapter: FormModuleAdapter
  ) {
    this.ngOnInit();
  }

  ngOnInit(): void {
    this.membershipApplicationType = this.applicantService.getSelectedApplicationType();
    this.applicationInProgress = this.applicantService.getIsApplicationInProgress();
    this.applicantId = this.applicantService.getApplicantId();
  }

  public updateParentModule(): Observable<ApplicationParent> {

    return this.formStateService.loadFormState(this.applicantId)
      .pipe(
        map((response: any) => {
          if ((!response)) {
            const error = response.error;
            throw new Error();
          }
          return response;
        }),
        catchError(response => {
          if (response.status === 401) {
            // user isn't authenticated - let's auth them
          }
          return response;
        }),
        map((response) => {
          return response;
        })
      );


  }

  public getModuleList(): Observable<FormModule[]> {
    return this.moduleService.getModuleList(this.membershipApplicationType)
      .pipe(
        map((response: any) => {
          if ((!response)) {
            const error = response.error;
            throw new Error();
          }
          return response;
        }),
        catchError(response => {
          return response;
        }),
        map((response) => {
          return response;
        })
      );
  }

  public getModule(moduleTitle: string, parentModel: ApplicationParent): Observable<FormModule> {
    let module;
    if (parentModel !== undefined) {
      module = parentModel.modules.find(m => m.name === moduleTitle);
    }
    if (module !== undefined) {
      const observableModule = of(module);
      return observableModule;
    } else {
      module = new FormModule;
      return this.moduleService.getModule(this.membershipApplicationType, moduleTitle)
        .pipe(
          catchError(response => {
            if (response.status === 401) {
              // user isn't authenticated - let's auth them
            }
            return response;
          }),
          map((response: any) => {
            return response;
          })
        );
    }
  }

  public saveFormState(module: FormModule, parent: ApplicationParent): ApplicationParent {
    if (parent === undefined) {
      parent = this.buildParent();
    }
    let found = false;
    parent.modules = parent.modules.map(m => {
      if (m.name === module.name) {
        m.allFieldsValid = module.allFieldsValid;
        m.moduleTouched = module.moduleTouched;
        found = true;
        return m;
      } else {
        return m;
      }
    });

    if (!found) {
      parent.modules.push(module);
    }

    return parent;
  }

  public buildParent(): ApplicationParent {
    let parent = new ApplicationParent(this.moduleAdapter);
    parent = this.formStateService.buildParent(parent);
    parent.modules = new Array<FormModule>();
    return parent;
  }

  public adminRemoveFormStateForTesting(parentModel: ApplicationParent): ApplicationParent {
    parentModel.FormState = '';
    parentModel.modules = new Array<FormModule>();

    return parentModel;
  }

  public adminAddFormStateForTesting(): Observable<ApplicationParent> {
    return this.formStateService.GetApplicationForTesting()
      .pipe(
        map((response) => {
          return this.formStateService.buildParent(response);
        })
      );
  }

  needsSavingToProtech(formModule: string): boolean {
    if(formModule === Constants.FormModuleTitle.PersonalInformation ||
      formModule == Constants.FormModuleTitle.PrimaryAddress ||
      formModule == Constants.FormModuleTitle.HomeMedicalStudentAddressName ||
      formModule == Constants.FormModuleTitle.SchoolMedicalStudentAddressName ||
      formModule == Constants.FormModuleTitle.StudentEmail) {
      return true;
    }
  }
}
