import { Component, OnInit } from '@angular/core';
import { NavService } from '@shared/services/nav.service';
import { ApplicantService } from '@shared/services/forms/applicant.service';
import { ApplicationValidationError } from '@shared/models/data/ApplicationValidationError';
import { ApplicationService } from '@shared/services/administration/application.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PaymentReminderDialogComponent } from '../payment-reminder-dialog/payment-reminder-dialog.component';
import { Constants } from '@shared/services/constants/constants';
import { DiscountCodeDialogComponent } from './discount-code-dialog/discount-code-dialog.component';
import { APP_CONFIG } from '@config/main';
import { concatMap } from 'rxjs/operators';

@Component({
    selector: 'app-submit-form',
    templateUrl: './submit-form.component.html',
    styleUrls: ['./submit-form.component.scss']
})
export class SubmitFormComponent implements OnInit {
    applicationStatusName: string;
    paymentMade = false;
    showSubmitAndPayButton = false;
    showSubmitOnlyButton = false;
    showPayButton = false;
    showValidationErrors = false;
    showSubmitErrors = false;
    validationErrorList: Array<ApplicationValidationError>;
    submitErrorList: Array<string>;
    disableClass = '';
    applicationTypeId: string;
    mcid: string;
    oid: string;
    bylawsUrl: string;
    standardsOfPUrl: string;
    mainSiteUrl: string;
    productCode: string;
    isFree: boolean = false;

    orderPrice: string;
    resourceValue: string;

    loadingData = false;
    submittingApplication = false;
    loadingError = false;
    loadingPrice = false;

    constructor(
        private navService: NavService,
        private applicantService: ApplicantService,
        private applicationService: ApplicationService,
        private dialog: MatDialog
    ) { }

    ngOnInit() {
        APP_CONFIG.subscribe(_ => {
            this.bylawsUrl = _.bylawsUrl;
            this.standardsOfPUrl = _.standardsOfPUrl;
            this.mainSiteUrl = _.mainSiteUrl;
        });
        this.applicationTypeId = this.applicantService.getApplicationTypeId().toLowerCase();
        this.mcid = this.applicantService.getMcid();

        this.resourceValue = this.applicationTypeId === Constants.MemberApplicationTypes.InternationalMemberId.toLowerCase()
            ? Constants.ApplicationResourceValues.InternationalMember : this.resourceValue;
        this.resourceValue = this.applicationTypeId === Constants.MemberApplicationTypes.InternationalResidentId.toLowerCase()
            ? Constants.ApplicationResourceValues.InternationalResident : this.resourceValue;

        this.loadData();
    }

    loadDiscountCode() {
        if (this.productCode.includes('FREE') || this.productCode.includes('INTL')) {
            this.isFree = true;
        }
        if (this.productCode.includes('INTL')) {
            this.openDiscountCodeDialog();
        }
    }

    async loadData() {
        this.showValidationErrors = false;
        this.showSubmitErrors = false;
        this.loadingData = true;

        this.productCode = window.sessionStorage.getItem('productCode');

        //do one last check to get product code if it's empty - just in case
        if (this.productCode == null || (this.productCode != null && this.productCode.trim() == '')) {
            this.applicantService.getApplicant().subscribe(x => {
                this.productCode = window.sessionStorage.getItem('productCode');

                this.loadDiscountCode();
            });
        } else {
            this.loadDiscountCode();
        }

        await this.applicantService.getApplicationStatusName().then(x => {
            this.applicationStatusName = x.toUpperCase();
            if (this.isFree && this.applicationStatusName === 'INCOMPLETE') {
                this.showSubmitOnlyButton = true;
            } else {
                this.showSubmitAndPayButton = (this.applicationStatusName === 'INCOMPLETE');
                this.showPayButton = (this.applicationStatusName === 'SUBMITTED' || this.applicationStatusName === 'PREAPPROVED');
            }
        })


        this.applicantService.validateApplication(this.applicantService.getApplicantId()).subscribe(response => {
            this.loadingData = false;
            this.loadingError = false;
            if (response && response.length > 0) {
                this.validationErrorList = response;
                this.disableClass = 'btn--disabled';
                this.showValidationErrors = true;
            } else {
                this.validationErrorList = new Array<ApplicationValidationError>();
                this.disableClass = '';
            }
        });

        setTimeout(() => {
            if (this.loadingData) {
                this.loadingError = true;
            }
        }, 20000);
    }

    onPay() {
        this.loadingPrice = true;
        // check to see if the user already has a paid order.. if they do, navigate to the thank you page
        this.applicantService.getOrderId().pipe(concatMap(orderId => {
            this.oid = orderId;
            return this.applicantService.doesCurrentPaidOrderExist();
        })
        ).subscribe(orderExistsAndAlreadyPaid => {
            this.loadingPrice = false;
            if (orderExistsAndAlreadyPaid.toString() !== 'false') {
                // redirect to thank you page
                this.navService.redirectToThankYouPage();
            } else {
                this.navService.redirectToPay(this.oid);
            }
        });
        setTimeout(() => {
            if (this.submittingApplication) {
                this.loadingError = true;
            }
        }, 20000);
    }

    redirect() {
        if (this.showSubmitAndPayButton) {
            this.onPay();
        } else {
            this.navService.redirectToThankYouPage();
        }
    }

    getModalConfig(data) {
        return {
            panelClass: 'amp-dialog',
            disableClose: true,
            autoFocus: false,
            data: data
        } as MatDialogConfig;
    }

    openPaymentReminder(data: any) {
        this.dialog.open(PaymentReminderDialogComponent, this.getModalConfig(data));
    }

    openDiscountCodeDialog() {
        this.dialog.open(DiscountCodeDialogComponent, { disableClose: true });
    }

    setOrderPrice() {
        this.loadingPrice = true;
        this.applicantService.doesCurrentPaidOrderExist().subscribe(doesCurrentPaidOrderExist => {
            if (doesCurrentPaidOrderExist.toString() === 'false') {
                if (!this.oid) {
                    this.applicantService.getOrderId().subscribe(x => {

                        this.oid = x;
                        this.applicantService.getOrderPrice(this.oid).subscribe(orderPrice => {
                            if (orderPrice) {
                                this.orderPrice = ` ($${orderPrice})`;
                                const data = {
                                    orderPrice: orderPrice,
                                    resourceValue: this.resourceValue
                                };
                                this.openPaymentReminder(data);
                            }
                        });
                    });
                } else if (this.oid && this.oid !== 'Error') {

                    this.applicantService.getOrderPrice(this.oid).subscribe(orderPrice => {
                        if (orderPrice) {
                            this.orderPrice = ` ($${orderPrice})`;
                            const data = {
                                orderPrice: orderPrice,
                                resourceValue: this.resourceValue
                            };
                            this.openPaymentReminder(data);
                        }
                    });
                }
            }
            this.loadingPrice = false;
        });
    }

    onSubmit() {
        this.submittingApplication = true;
        this.applicantService.submitApplication(this.applicantService.getApplicantId()).subscribe(response => {
            this.loadingData = true;
            this.submittingApplication = false;
            this.loadingError = false;
            if (response.success) {
                // i think we were reloading the data here because we didn't have the pay component?
                // leaving this here just in case
                // this.loadData();
                this.notifyUser();
                this.redirect();
            } else if (this.validationErrorList && this.validationErrorList.length > 0) {
                this.validationErrorList = response.validationErrorList;
                this.showValidationErrors = true;
                this.showSubmitErrors = false;
            } else {
                this.showValidationErrors = false;
                this.showSubmitErrors = true;
            }
        });

        setTimeout(() => {
            if (this.submittingApplication) {
                this.loadingError = true;
            }
        }, 20000);
    }

    notifyUser() {
        this.applicationService.notifyUser(this.mcid, this.applicationTypeId).then();
    }

    onReturnToApplication() {
        this.navService.redirectToLanding();
    }

}
