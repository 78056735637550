import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormField } from '@shared/form-fields/models/form-field';
import { FieldsPrerequisite } from '@shared/models/data/fieldsPrerequisite';
import { Constants } from '@shared/services/constants/constants';
import { FormValidatorService } from '@shared/services/forms/form-validator.service';

@Component({
  selector: 'app-aaos-npi-form-field',
  templateUrl: './npiFormField.component.html',
  styleUrls: ['./npiFormField.component.scss']
})
export class NpiFormFieldComponent implements OnInit {

  @Input() fieldsPrerequisite: FieldsPrerequisite;
  @Input() field: FormField;
  @Input() displayText: string;
  @Input() form: AbstractControl;
  @Input() fieldIsDisabled: boolean;
  @Output() componentChanged = new EventEmitter();
  @Input() dynamicForm: FormGroup;
  baseFormGroup = new FormGroup({});

  display: string;

  ngOnInit() {
    this.buildFormGroup();
    this.populateModule();
    this.npiOptional();
    this.baseFormGroup.updateValueAndValidity();
    this.validateFormGroup();
  }

  npiOptional() {
    this.display = this.field.displayName;

    // just addinng false to skip this in case we ever want to bring this back, it'll be as
    // easy as removing the false
    if (false && this.fieldsPrerequisite.countryCode === Constants.CountryConstants.US) {
      this.baseFormGroup.get(this.field.name).setValidators([Validators.required, FormValidatorService.npiValidation()]);
      if (this.baseFormGroup.get(this.field.name).value == null) {
        this.baseFormGroup.get(this.field.name).setErrors(Validators.required);
      }
    } else {
      this.baseFormGroup.get(this.field.name).setValidators([FormValidatorService.npiValidation()]);
      this.display += ' (Optional)';
    }
    this.baseFormGroup.updateValueAndValidity();
  }

  buildFormGroup() {
    this.baseFormGroup = this.dynamicForm.get(this.fieldsPrerequisite.formModuleTitle) as FormGroup;
    this.baseFormGroup.addControl(this.field.name, new FormControl());
  }

  populateModule() {
    this.baseFormGroup.get(this.field.name).setValue(this.field.value);
  }

  validateFormGroup() {
    if (this.baseFormGroup.invalid) {
      this.dynamicForm.get(this.fieldsPrerequisite.formModuleTitle).setErrors(Validators.required);
    } else {
      this.dynamicForm.get(this.fieldsPrerequisite.formModuleTitle).setErrors(null);
    }
  }
  componentChange(event: any) {

    if (event.target) {
      if (event.target.value === undefined) {
        this.field.value = String(event.target.textContent).trim();
      } else {
        this.field.value = String(event.target.value).trim();
      }
    } else {
      if (event.value !== undefined) {
        this.field.value = String(event.value).trim();
      }
    }
    this.validateFormGroup();
    this.componentChanged.emit(this.field);
  }

  invalidField(fieldName: string): boolean {
    if (this.baseFormGroup.get(fieldName).errors) {
      return true;
    }
    return false;

  }
}
