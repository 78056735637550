<section class="content">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-lg-6">
                <div [ngClass]="{'d-none': this.redirected === false}">
                </div>
                <div *ngIf="this.paymentError">
                    There was an error in this part of the application. If you feel as though your payment did not go through, or you have any questions regarding this process, please contact our customer service team.
                    If you'd like to check on your application status, please <a [routerLink]="['/status']">click here</a>.
                </div>
                <div *ngIf="!this.paymentError">
                    <div *ngIf="!this.redirected">
                        Please wait, while we load the payment form.
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-aaos-spinner [isLoading]="loading"></app-aaos-spinner>