import { Injectable } from '@angular/core';
import { Adapter } from '@shared/services/utils/adapter';

export class Specialization {
    practiceSpecializationPrimary: string;
}


@Injectable({
    providedIn: 'root'
  })
  export class SpecializationAdapter implements Adapter<Specialization> {

    constructor() {
    }

    adapt(item: any): Specialization {
      const customerProfileMini: Specialization = {
          practiceSpecializationPrimary: item.PracticeSpecializationPrimary
      };
        return customerProfileMini;
    }
}
