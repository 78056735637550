import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { StatusRow } from '@shared/models/data/status.model';
import { NavService } from '@shared/services/nav.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Subject } from 'rxjs';


@Component({
    selector: 'app-status-table',
    templateUrl: './status-table.component.html',
    styleUrls: ['./status-table.component.scss']
})
export class StatusTableComponent implements AfterViewInit, OnChanges {

    dataSource: MatTableDataSource<StatusRow>;
    @Input() rows: StatusRow[];
    @ViewChild(MatSort) sort: MatSort;
    selection = new SelectionModel<StatusRow>(true, []);
    @Output() selectionEvent = new EventEmitter<StatusRow[]>();
    @Input() deselectSubject: Subject<any>;
    @Output() deleteApplication = new EventEmitter<StatusRow>();
    columnsToDisplay;
    @Input() currentStatusTab;

    constructor(private navService: NavService) {
        this.dataSource = new MatTableDataSource();

    }

    ngAfterViewInit() {
        this.modifyColumnToDisplay();
        this.dataSource.sort = this.sort;

        // this will distinct the data we're showing in the frontend by application id
        // since there seems to be duplication of data sometimes possibly due to loading slowness
        // or some weird user interaction.
        const distinctRows = this.rows.filter((row, i, arr) => {
            return arr.indexOf(arr.find(t => t.applicationId === row.applicationId)) === i;
        });

        this.dataSource.data = distinctRows;

        this.deselectSubject.subscribe(x => {
            this.selection.clear();
        });
    }

  modifyColumnToDisplay() {
    if (this.currentStatusTab !== null) {
      switch (this.currentStatusTab) {
          case 0:
          this.columnsToDisplay = ['select', 'lastName', 'firstName', 'mcid', 'primaryEmailAddress', 'createdDate', 'completion', 'paid', 'delete'];
            break;
          case 3:
          this.columnsToDisplay = ['select', 'lastName', 'firstName', 'mcid', 'primaryEmailAddress', 'submittedDate', 'completion', 'applicationStatus', 'paid', 'delete'];
            break;
          default:
          this.columnsToDisplay = ['select', 'lastName', 'firstName', 'mcid', 'primaryEmailAddress', 'submittedDate', 'completion', 'paid', 'delete'];
            break;
        }
      }
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    ngOnChanges(changes: SimpleChanges) {
        this.modifyColumnToDisplay();
      }

    masterToggle() {
        if (this.isAllSelected()) {
            this.selection.clear();
            return;
        }
        this.dataSource.data.forEach(row => this.selection.select(row));
    }

    public viewApplicant(id: string) {
        this.navService.viewApplicant(id);
    }

    updateSelection() {
        this.selectionEvent.emit(this.selection.selected);
    }

    public applicationToDelete(item: any) {
        this.deleteApplication.emit(item);
    }
}
