import { Component, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { APP_CONFIG } from '@config/app-config.service';
import { ApplicantService } from '@shared/services/forms/applicant.service';
import { NavService } from '@shared/services/nav.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss']
})
export class PaymentFormComponent implements OnInit {
  loading = true;
  redirected = false;
  paymentError = false;
  oid: string;

  constructor(
    private navService: NavService,
    private aRouter: ActivatedRoute,
    private applicantService: ApplicantService) { }

  ngOnInit() {
    this.aRouter.queryParams.subscribe(params => {
      if (params['oid']) {
        this.oid = params['oid'];
      }

      this.applicantService.doesCurrentPaidOrderExist().subscribe(doesCurrentPaidOrderExist => {
        if (doesCurrentPaidOrderExist.toString() !== 'false') {
          this.navService.redirectToThankYouPage();
        } else {
          let getOrderId$;
          if (this.oid) {
            getOrderId$ = of(this.oid);
          } else {
            getOrderId$ = this.applicantService.getOrderId();
          }
          getOrderId$.subscribe(x => {
            const oid = x;
            if (oid && oid !== 'Error') {
              this.applicantService.getApplicant().subscribe(applicant => {
                APP_CONFIG.subscribe(_ => {
                  const replacedOneClickUrl =
                    _.oneclickUrl.replace('{oid}', oid);
                    window.location.href=replacedOneClickUrl;
                });
              });
            }

            // if it takes 20 seconds to load the payment portal, we have other issues,
            // so lets unblock the user
            setTimeout(() => {
              this.loading = false;
              if (this.redirected === false) {
                this.paymentError = true;
              }
            }, 20000);
          });
        }
      });
    }
    );
  }
}


