import { SelectionModel } from '@angular/cdk/collections';
import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PaymentReminder } from '@shared/models/data/payment-reminder';
import { PaymentReminderService } from '@shared/services/administration/payment-reminder.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'aaos-payment-reminder-table',
  templateUrl: './payment-reminder-table.component.html',
  styleUrls: ['./payment-reminder-table.component.scss']
})
export class PaymentReminderTableComponent implements AfterViewInit, AfterContentChecked {

  dataSource: MatTableDataSource<PaymentReminder>;
  @Input() rows: PaymentReminder[];
  @ViewChild(MatSort) sort: MatSort;
  selection = new SelectionModel<PaymentReminder>(true, []);
  @Output() selectionEvent = new EventEmitter<PaymentReminder[]>();
  @Input() deselectSubject: Subject<any>;
  @Output() deleteApplication = new EventEmitter<PaymentReminder>();
  columnsToDisplay;

  constructor(private cdref: ChangeDetectorRef,
    private _paymentReminderService: PaymentReminderService,
    private _snackBar: MatSnackBar) {
    this.dataSource = new MatTableDataSource();

  }

  ngAfterViewInit() {
    this._paymentReminderService.getAlreadySentEmails().subscribe(x => {
      if (x !== null) {
        this.dataSource.data = x;
      }
      this.columnsToDisplay = ['select', 'lastName', 'firstName', 'mcid', 'primaryEmailAddress', 'applicationType', 'sentDate'];
      this.dataSource.sort = this.sort;
    });
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  sendEmails() {
    let message = 'Notification sent';
    if (this.selection.selected.length > 1) {
      message = 'Notifications sent';
    }
    this._paymentReminderService.sendPaymentReminderEmail(this.selection.selected).subscribe(x => {
      this.openNotificationSentSnackbar(message);
    });
  }

  sendFirstTimeEmailsManually() {
    this._paymentReminderService.sendFirstTimeEmailsManually().subscribe(x => {
      this.openNotificationSentSnackbar('Sent');
    });
  }

  openNotificationSentSnackbar(message: string) {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 5000
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.dataSource.data.forEach(row => this.selection.select(row));
  }

  updateSelection() {
    this.selectionEvent.emit(this.selection.selected);
  }

}
