import { Component, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CertificationDetail } from '@shared/models/CertificationDetail';
import { Preference } from '@shared/models/data';
import { FieldsPrerequisite } from '@shared/models/data/fieldsPrerequisite';
import { ApplicantService } from '@shared/services/forms/applicant.service';
import { UserProfileService } from '@shared/services/user-profile.service';
import { Observable } from 'rxjs';
import { BaseFieldComponentDirective } from '../base-field-component';
import { FormField } from '../models/form-field';
import { Constants } from '@shared/services/constants/constants';

@Component({
  selector: 'app-certification',
  templateUrl: './certification.component.html',
  styleUrls: ['./certification.component.scss']
})
export class CertificationComponent implements OnInit, OnChanges {

  @Input() fieldsPrerequisite: FieldsPrerequisite;
  @Input() dynamicForm: FormGroup;
  @Input() index;
  @Output() componentChanged = new EventEmitter();
  fellowshipIndex: string;
  states: Preference[];
  statesLoaded = false;
  baseFormGroup = new FormGroup({});
  @Input() field: FormField;
  @Input() events: Observable<void>;
  @Input() active: boolean;
  boards = ['ABOS', 'AOBOS', 'RCPSC'];
  isAffiliateOrtho = false;
  @Input() applicationCountries: Preference[];


  autocomplete: google.maps.places.Autocomplete;
  nameField: HTMLInputElement;
  cityField: HTMLInputElement;
  stateField: HTMLInputElement;
  loading = true;
  @Input() certificationDetail: CertificationDetail;

  constructor(private userProfileService: UserProfileService,
    private applicantService: ApplicantService) {
  }

  ngOnInit() {
    this.buildFormGroup();
    this.validateFormGroup();
    this.setCertificationDetail();
    this.populateFormGroup();
    if (this.applicantService.getApplicationTypeId().toLowerCase() === Constants.MemberApplicationTypes.AffiliateOrthoId.toLowerCase()) {
      this.isAffiliateOrtho = true;
      this.getAndSetAddress();
    } else {
      this.getAndSetAddress();
    }
  }

  public setCertificationDetail() {
    this.certificationDetail = new CertificationDetail();
    if (this.field.value) {
      const parsedJson: CertificationDetail = JSON.parse(this.field.value);
      this.certificationDetail = parsedJson;
    }
  }


  detectInputChange(event?) {
    this.certificationDetail.boardName = this.baseFormGroup.get('boardName').value;
    this.certificationDetail.city = this.baseFormGroup.get('city').value;
    this.certificationDetail.state = this.baseFormGroup.get('state').value;
    this.certificationDetail.country = this.baseFormGroup.get('country').value;
    this.certificationDetail.certificationDate = this.baseFormGroup.get('certificationDate').value;
    this.validateFormGroup();
    this.field.value = JSON.stringify(this.certificationDetail);
    this.componentChanged.emit(this.field);
  }

  invalidRequiredField(fieldName: string): boolean {
    if (this.baseFormGroup.get(fieldName).hasError('required')) {
      return true;
    }
    return false;
  }

  ngOnChanges(changes: SimpleChanges) {

    this.validateFormGroup();

  }

  validateFormGroup() {
    if (!this.field.fieldIsDisabled) {
      if (!this.field.fieldIsDisabled && ((this.baseFormGroup.hasError('required')) || this.baseFormGroup.invalid)) {
        this.dynamicForm.get(this.fieldsPrerequisite.formModuleTitle).setErrors(Validators.required);
        this.dynamicForm.updateValueAndValidity();
      } else {
        this.baseFormGroup.setErrors(null);
        this.baseFormGroup.updateValueAndValidity();
        this.dynamicForm.get(this.fieldsPrerequisite.formModuleTitle).setErrors(null);
        this.dynamicForm.updateValueAndValidity();
      }
    }
  }

  getAndSetAddress() {
    this.getStates();
  }

  updateStates($event) {
    this.baseFormGroup.get('state').setValue('');
    if (this.baseFormGroup.get('country').value !== '') {
      this.getStates();
    }
  }

  getStates() {
    let countryCode;
    if (this.isAffiliateOrtho) {
      countryCode = this.baseFormGroup.get('country').value ?? this.certificationDetail.country;
    } else {
      countryCode = this.fieldsPrerequisite.countryCode;
    }
    if (countryCode) {
      this.userProfileService.getStates(countryCode).subscribe(latest => {
        this.states = latest;
        this.loading = false;
        this.statesLoaded = true;
        this.validateFormGroup();
      });
    } else {
      this.loading = false;
      this.statesLoaded = true;
      this.states = Array<Preference>();
    }
  }

  buildFormGroup() {
    this.baseFormGroup = new FormGroup({});
    this.baseFormGroup.addControl('boardName', new FormControl());
    this.baseFormGroup.addControl('city', new FormControl());
    this.baseFormGroup.addControl('state', new FormControl());
    this.baseFormGroup.addControl('country', new FormControl());
    this.baseFormGroup.addControl('certificationDate', new FormControl());
    this.setValidators();
    this.baseFormGroup.updateValueAndValidity();
    this.validateFormGroup();
  }

  setValidators() {
    this.baseFormGroup.get('boardName').setValidators(Validators.required);
    this.baseFormGroup.get('city').setValidators(Validators.required);
    this.baseFormGroup.get('state').setValidators(Validators.required);
    if (this.isAffiliateOrtho) {
      this.baseFormGroup.get('country').setValidators(Validators.required);
    }
    this.baseFormGroup.get('certificationDate').setValidators(Validators.required);
    this.baseFormGroup.updateValueAndValidity();
  }

  enable() {
    this.setValidators();
  }

  disable() {
    this.baseFormGroup.clearValidators();
  }

  populateFormGroup() {
    this.baseFormGroup.get('boardName').setValue(this.certificationDetail.boardName);
    this.baseFormGroup.get('city').setValue(this.certificationDetail.city);
    this.baseFormGroup.get('state').setValue(this.certificationDetail.state);
    if (this.certificationDetail.country) {
      this.baseFormGroup.get('country').setValue(this.certificationDetail.country);
    }
    this.baseFormGroup.get('certificationDate').setValue(this.certificationDetail.certificationDate);
    this.baseFormGroup.updateValueAndValidity();
  }

}
