import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { APP_CONFIG } from '@config/main';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-amp-iframe',
  templateUrl: './amp-iframe.component.html',
  styleUrls: ['./amp-iframe.component.scss']
})
export class AmpIframeComponent implements OnInit {

  ampUrl: SafeResourceUrl;
  ampOrigin: string;
  constructor(public sanitizer: DomSanitizer) { }
  loading = true;
  ampLoaded = false;
  ampTimeout: number;
  @Input() fieldsPrerequisite;
  unsubscribe = new Subject();
  @Output() ampErrorEvent = new EventEmitter<boolean>();

  ngOnInit() {
    APP_CONFIG.subscribe(_ => {
      this.ampUrl = this.sanitizer.bypassSecurityTrustResourceUrl(_.ampUrl);
      this.ampOrigin = _.ampOrigin;
      this.ampTimeout = _.ampTimeout;
      timer(this.ampTimeout).pipe(
        takeUntil(this.unsubscribe), ).subscribe(x => {
          this.loading = false; this.fieldsPrerequisite.ampError = true; this.ampErrorEvent.emit(true); });

    });
    window.addEventListener('message', this.stopSpinning.bind(this));
  }

  private stopSpinning(event) {
    if ((event.origin === this.ampOrigin)) {
      if (event.data === 'ampLoaded') {
        this.ampLoaded = true;
        this.loading = false;
        this.unsubscribe.next();
      }
    }
  }
}
