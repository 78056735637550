<div class="form-group" [formGroup]="baseFormGroup" #certificationDetails [appDisable]="field.fieldIsDisabled">

  <div *ngIf="isAffiliateOrtho">
    <div class="title title--xs">Country of Education</div>
    <mat-select required="!field.fieldIsDisabled" formControlName="country" (selectionChange)="updateStates($event)"
      (blur)="detectInputChange($event)" disableRipple disableOptionCentering panelClass="mat-panel"
      [disabled]="field.fieldIsDisabled" [ngClass]="{'ng-invalid': invalidRequiredField('boardName')}">
      <mat-option *ngFor="let country of applicationCountries" [value]="country.preferenceCode">
        {{ country.preferenceDescription }}
      </mat-option>
    </mat-select>
    <div class="required-text" *ngIf="invalidRequiredField('country') && !field.fieldIsDisabled">Country is required
    </div>
  </div>

  <div class="title title--xs">Board Name</div>
  <mat-select *ngIf="!isAffiliateOrtho" required="!field.fieldIsDisabled"
    [ngClass]="{'ng-invalid': invalidRequiredField('boardName')}" formControlName="boardName" id="boardName"
    (blur)="detectInputChange($event)" disableRipple [disabled]="field.fieldIsDisabled" disableOptionCentering
    panelClass="mat-panel">
    <mat-option *ngFor="let board of boards" [value]="board">
      {{ board }}
    </mat-option>
  </mat-select>
  <input *ngIf="isAffiliateOrtho" class="form-control" formControlName="boardName" id="boardName"
    (blur)="detectInputChange($event)" required="!field.fieldIsDisabled" [disabled]="field.fieldIsDisabled"
    type="text" />
  <div class="required-text" *ngIf="invalidRequiredField('boardName') && !field.fieldIsDisabled">Board Name is required
  </div>

  <div class="title title--xs">City</div>
  <input class="form-control" formControlName="city" id="city" (blur)="detectInputChange($event)"
    required="!field.fieldIsDisabled" type="text" />
  <div class="required-text" *ngIf="invalidRequiredField('city') && !field.fieldIsDisabled">City is required</div>

  <div class="title title--xs">State/Province</div>
  <mat-select *ngIf="(statesLoaded === true && states.length === 0) === false" required="!field.fieldIsDisabled"
    formControlName="state" id="state" (blur)="detectInputChange($event)" disableRipple
    [disabled]="field.fieldIsDisabled" disableOptionCentering panelClass="mat-panel">
    <mat-option *ngFor="let state of states" [value]="state.preferenceCode">
      {{ state.preferenceDescription }}
    </mat-option>
  </mat-select>
  <input *ngIf="statesLoaded === true && states.length === 0" class="form-control" formControlName="state" id="state"
    (blur)="detectInputChange($event)" [disabled]="field.fieldIsDisabled" required="!field.fieldIsDisabled"
    type="text" />
  <div class="required-text" *ngIf="invalidRequiredField('state') && !field.fieldIsDisabled">State is required</div>

  <div class="title title--xs">Certification Date</div>
  <div class="dateContainer">
    <div (click)="certificationDatePicker.open()" class=""
      [ngClass]="{'input': field.fieldIsDisabled, 'date--form-control half-width': !field.fieldIsDisabled}">
      <input [matDatepicker]="certificationDatePicker" formControlName="certificationDate"
        required="!field.fieldIsDisabled" disabled="true" class="form-control"
        [ngClass]="{'half-width': field.fieldIsDisabled}">
    </div>
    <mat-datepicker-toggle [for]="certificationDatePicker"></mat-datepicker-toggle>
    <mat-datepicker [disabled]="field.fieldIsDisabled" #certificationDatePicker ngDefaultControl
      (closed)="detectInputChange()">
    </mat-datepicker>
  </div>
  <div class="required-text" *ngIf="invalidRequiredField('certificationDate') && !field.fieldIsDisabled">Certification
    Date is required</div>

</div>
<app-aaos-spinner [isLoading]="loading"></app-aaos-spinner>