<div *ngIf="(dynamicForm)" [formGroup]="baseFormGroup" class="form-group">
    <div *ngIf="(field)" [id]="field.id" [readonly]="field.fieldIsDisabled" [required]="isRequired">
        <app-aaos-form-field-title [field]="field" [displayText]="field.displayName"></app-aaos-form-field-title>
        <mat-select [(value)]="selectedValue" multiple disableRipple [formControlName]="field.name" panelClass="mat-panel"
            (blur)="optionChecked($event)" [readonly]="field.fieldIsDisabled" [formOptions]="formOptions">
            <mat-option *ngFor="let option of options" [value]="option.preferenceSubCode">{{
                option.preferenceDescription }}
            </mat-option>
        </mat-select>
    </div>
    <div class="required-text" *ngIf="!checkValidity()">
        {{field.displayName}} is required
    </div>
</div>
