import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Preference, ProfileFormOption } from '@shared/models/data';
import { FieldsPrerequisite } from '@shared/models/data/fieldsPrerequisite';
import { FormOptionsMapperService } from '@shared/services/forms/form-options-mapper.service';
import { BaseMultipleOptionsField } from '../base-multiple-options-field';
import { FormFieldConstants } from '../models/form-field';

@Component({
  selector: 'app-aaos-check-box',
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.scss']
})
export class CheckBoxComponent extends BaseMultipleOptionsField implements OnInit {

  @Input() formOptions: ProfileFormOption[];
  @Input() fieldsPrerequisite: FieldsPrerequisite;
  selectedValue: string;
  @Input() dynamicForm: FormGroup;
  isRequired: boolean = true;
  control : AbstractControl;
  constructor(formFieldsOptionsMapper: FormOptionsMapperService) {
    super(formFieldsOptionsMapper);
  }
  ngOnInit() {
    super.ngOnInit();
    this.buildFormGroup();
    this.populateFormGroup();
    this.setOptional();
    this.baseFormGroup.updateValueAndValidity();
    this.validateFormGroup();
    this.control = this.baseFormGroup.get(this.field.name);
    this.checkValidity();
  }

  getPrefix(option: Preference) {
    if(this.field.id === FormFieldConstants.CredentialsId.toLowerCase()) {
      return option.preferenceSubCode;
    }
    return option.preferenceDescription
  }

  optionChecked(event: any) {
    this.field.value = this.selectedValue?.toString();
    this.componentChanged.emit(this.field);
  }

  checkValidity(): boolean {
    return (this.control.value?.length > 0);
  }

  setOptional() {
    if (this.field.displayName.indexOf('Optional') === -1 || !this.field.fieldIsDisabled) {
      this.isRequired = true;
    } else {
      this.isRequired = false;
    }
  }

}
