<div class="container">
  <div class="row justify-content-center mt15">
    <div class="col"></div>
    <div class="col">
      <button class="btn btn--solid w100p" (click)="navigateToDashboard()">Administration</button>
    </div>
    <div class="col"></div>
  </div>
  <div class="row justify-content-center mt15">
    <div class="col"></div>
    <div class="col">
      <button class="btn btn--solid w100p" (click)="navigateToAppConfig()">Application Configuration</button>
    </div>
    <div class="col"></div>
  </div>
  <div class="row justify-content-center mt15">
    <div class="col"></div>
    <div class="col">
      <button class="btn btn--solid w100p" (click)="navigateToUnpaidNotifications()">Unpaid Notifications</button>
    </div>
    <div class="col"></div>
  </div>
</div>