import { Component, OnInit, Input } from '@angular/core';
import { ProfileFormOption, Preference } from '@shared/models/data';
import { BaseMultipleOptionsField } from '@shared/form-fields/base-multiple-options-field';
import { FormOptionsMapperService } from '@shared/services/forms/form-options-mapper.service';
import { FormGroup, Validators } from '@angular/forms';
import { FieldsPrerequisite } from '@shared/models/data/fieldsPrerequisite';
import { FormValidatorService } from '@shared/services/forms/form-validator.service';
import { FormFieldConstants } from '../models/form-field';


@Component({
  selector: 'app-aaos-drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss']
})
export class DropDownComponent extends BaseMultipleOptionsField implements OnInit {

  @Input() formOptions: ProfileFormOption[];
  @Input() fieldsPrerequisite: FieldsPrerequisite;
  formControlName: string;
  selectedValue: string;
  @Input() dynamicForm: FormGroup;
  isRequired = true;

  constructor(formFieldsOptionsMapper: FormOptionsMapperService,
  ) {
    super(formFieldsOptionsMapper);
  }

  ngOnInit() {
    this.buildFormGroup();
    this.populateFormGroup();
    this.baseFormGroup.updateValueAndValidity();
    this.validateFormGroup();
    if (this.field && !this.field.fieldIsDisabled) {
      super.ngOnInit();
      this.options = Object.assign([], this.options);
    }

    const constField = this.baseFormGroup.get(this.field.name);
    if (this.field.displayName.indexOf('Optional') !== -1) {
      this.options.unshift(new Preference);
      this.isRequired = false;
      constField.setValidators([Validators.nullValidator]);
    } else {
      this.baseFormGroup.controls[this.field.name].setValidators(Validators.required);
    }
    this.setValidatorForLanguage();
    if (this.field.id === FormFieldConstants.CountryOfResidence.toLowerCase()) {
      this.switchToPreferenceCode();
    }
    this.baseFormGroup.updateValueAndValidity();
    this.field.displayValue = this.options.find(x => x.preferenceCode === this.field.value)?.preferenceDescription;
  }


  switchToPreferenceCode() {
    const control = this.baseFormGroup.get(this.field.name);

    if (!control || !control.value) return;

    //because of melissa data
    if (control.value !== 'US' && control.value !== 'CA' && control.value.length > 2) {
      var country = this.options.find(option => option.preferenceDescription === this.field.value);
      control.setValue(country.preferenceCode)
    }
  }

  setValidatorForLanguage() {
    if (this.fieldsPrerequisite.formModuleTitle === 'Language') {
      this.baseFormGroup.setValidators([Validators.required, FormValidatorService.getLanguageValidator()]);
      this.baseFormGroup.updateValueAndValidity();
    }
  }

  componentChange() {
    if (this.field.id === FormFieldConstants.CountryOfResidence.toLowerCase() && (this.selectedValue !== 'US' && this.selectedValue !== 'CA')) {
      this.field.value = this.options.find(x => x.preferenceCode == this.selectedValue)?.preferenceDescription;
    } else {
      this.field.value = this.selectedValue;
    }
    this.field.displayValue = this.options.find(x => x.preferenceCode == this.selectedValue)?.preferenceDescription;
    this.validateFormGroup();
    this.componentChanged.emit(this.field);
  }
}
