import { Component, OnInit, EventEmitter, Output, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FormModule } from '@shared/models/data/form-module.model';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-module-header',
  templateUrl: './moduleHeader.component.html',
  styleUrls: ['./moduleHeader.component.scss']
})
export class ModuleHeaderComponent implements OnInit {

  formModules: FormModule[];
  moduleTotalSteps: number;
  selectedOption: FormModule;
  moduleCurrentStep: number;
  @Input() membershipApplicationType: string;
  loading: boolean;

  @Output() moduleSelected: EventEmitter<string> = new EventEmitter();
  @Output() lastModule: EventEmitter<boolean> = new EventEmitter;
  @Output() previousModuleValidatorUpdate: EventEmitter<string> = new EventEmitter();


  constructor(
  ) { }

  ngOnInit() {
    this.loading = true;
  }

  public updateModuleListFromParent(parentModules: FormModule[]): void {
    this.formModules = parentModules;
    this.formModules = this.organizeModules(this.formModules);
    this.setCurrentModule();
  }

  public updateCompletionStatus(savedModule: FormModule) {
    this.formModules.forEach((module) => {
      if (module.name === savedModule.name) {
        module.allFieldsValid = savedModule.allFieldsValid;
        module.moduleTouched = true;
      }
    });
  }

  public removeCompletionStatus() {
    this.formModules.forEach((m) => {
      m.allFieldsValid = false;
    });
  }

  public setCurrentModule() {
    if (this.selectedOption) {
      this.moduleSelected.emit(this.selectedOption.name);
      this.moduleCurrentStep = this.selectedOption.moduleStep;
      this.updateCompletionStatus(this.selectedOption);
      this.isLastModule();
    } else {
      this.moduleCurrentStep = 1;
    }
  }

  private isLastModule() {
    if (this.moduleCurrentStep === this.moduleTotalSteps) {
      this.lastModule.emit(true);
    } else {
      this.lastModule.emit(false);
    }

  }

  public nextModule(currentModule: FormModule) {
    if (this.moduleCurrentStep <= this.moduleTotalSteps) {
      var currentIndex = this.formModules.find(x => x.id == currentModule.id).moduleStep;
      this.moduleCurrentStep =  currentIndex + 1;
      this.selectedOption = this.formModules.find(x => x.moduleStep === this.moduleCurrentStep);

      this.setCurrentModule();

    }
  }

  public previousModule() {
    if (this.moduleCurrentStep > 1) {
      this.moduleCurrentStep = this.moduleCurrentStep - 1;
      this.previousModuleValidation();
      this.selectedOption = this.formModules.find(x => x.moduleStep === this.moduleCurrentStep);

      this.setCurrentModule();
    }
  }

  previousModuleValidation() {
    this.previousModuleValidatorUpdate.emit(this.selectedOption.name);
  }


  private organizeModules(items: Array<any>) {
    if (items === null || items === undefined) {
      return items;
    }

    items.sort((a, b) => a.sortOrder - b.sortOrder);
    let i = 1;
    items.map(n => {
      n['moduleStep'] = i; i++;
    });

    this.moduleTotalSteps = items.length;
    this.selectedOption = items[0];

    return items;
  }


}
