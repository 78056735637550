import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { HomeComponent } from './home/home.component';
import { AppRoutingModule } from './routing/routing.module';
import { AppConfigService } from '@config/main';
import { RootComponent } from './root/root.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { DynamicFormComponent } from './forms/dynamic-form/dynamic-form.component';
import { TextFieldComponent } from '@shared/form-fields/text-field/text-field.component';
import { FormFieldGroupComponent } from '@shared/form-fields/form-field-group/form-field-group.component';
import { CheckBoxComponent } from '@shared/form-fields/check-box/check-box.component';
import { DropDownComponent } from '@shared/form-fields/drop-down/drop-down.component';
import { RadioButtonComponent } from '@shared/form-fields/radio-button/radio-button.component';
import { MaterialModule } from '@shared/material.module';
import { FormFieldTitleComponent } from '@shared/form-fields/form-field-title/form-field-title.component';
import { ModuleHeaderComponent } from '@shared/components/moduleHeader/moduleHeader.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { YesNoComponent } from './shared/form-fields/yes-no/yes-no.component';
import { QuestionnaireFormComponent } from './forms/questionnaire-form/questionnaire-form.component';
import { DisableDirective } from '@shared/services/utils/disable.directive';
import { PercentagePipe } from '@shared/pipes/percentage.pipe';
import { AmpIframeComponent } from '@shared/form-fields/amp-iframe/amp-iframe.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { BasicAddressComponent } from './forms/basic-address/basic-address.component';
import { TextAreaComponent } from './shared/form-fields/text-area/text-area.component';
import { AddressListComponent } from '@shared/components/address-list/address-list.component';
import { DateComponent } from './shared/form-fields/date/date.component';
import { SponsorsComponent } from './forms/sponsors/sponsors.component';
import { SponsorsDialogComponent } from './forms/sponsors-dialog/sponsors-dialog.component';
import { MatNativeDateModule } from '@angular/material/core';
import { PlacesComponent } from './shared/form-fields/places/places.component';
import { SponsorItemComponent } from './forms/sponsor-item/sponsor-item.component';
import { AdminPageComponent } from './admin-components/admin-page/admin-page.component';
import { StatusTableComponent } from './admin-components/status-table/status-table.component';
import { MatTableModule } from '@angular/material/table';
import { StatusTabComponent } from './admin-components/status-tab/status-tab.component';
import { ViewApplicantComponent } from './admin-components/view-applicant/view-applicant.component';
import { TermsOfServiceComponent } from './termsofservice/terms-of-service/terms-of-service.component';
import { SubmitFormComponent } from './forms/submit-form/submit-form.component';
import { EducationInstitutionComponent } from '@shared/form-fields/education-institution/education-institution.component';
import { EducationInstitutionListComponent } from '@shared/components/education-institution-list/education-institution-list.component';
import { SpinnerComponent } from '@shared/components/spinner/spinner.component';
import { ApplicationStatusComponent } from './application-status/application-status.component';
import { ResidencyProgramComponent } from '@shared/form-fields/residency-program/residency-program.component';
import { ResidencyProgramListComponent } from '@shared/components/residency-program-list/residency-program-list.component';
import { FellowshipProgramListComponent } from '@shared/components/fellowship-program-list/fellowship-program-list.component';
import { FellowshipProgramComponent } from '@shared/form-fields/fellowship-program/fellowship-program.component';
import { PaymentFormComponent } from './forms/payment-form/payment-form.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { ReferralComponent } from './referral/referral.component';
import { UnchangeableMemberTypeComponent } from './unchangeable-member-type/unchangeable-member-type.component';
import { HospitalPrivilegeComponent } from '@shared/form-fields/hospital-privilege/hospital-privilege.component';
import { HospitalPrivilegeListComponent } from '@shared/components/hospital-privilege-list/hospital-privilege-list.component';
import { StateDropDownComponent } from '@shared/form-fields/state-drop-down/state-drop-down.component';
import { TextInputComponent } from '@shared/form-fields/text-input/text-input.component';
import { PracticeHistoryComponent } from '@shared/form-fields/practice-history/practice-history.component';
import { PracticeHistoryListComponent } from '@shared/components/practice-history-list/practice-history-list.component';
import { FileUploadComponent } from './shared/form-fields/file-upload/file-upload.component';
import { NpiFormFieldComponent } from './forms/npiFormField/npiFormField.component';
import { CertificationComponent } from '@shared/form-fields/certification/certification.component';
import { LicensureListComponent } from '@shared/components/licensure-list/licensure-list.component';
import { LicensureComponent } from '@shared/form-fields/licensure/licensure.component';
import { FileUploadInfoComponent } from '@shared/form-fields/file-upload-info/file-upload-info.component';
import { LabelComponent } from '@shared/form-fields/label/label.component';
import { AutomaticSaveDialogComponent } from './forms/automatic-save-dialog/automatic-save-dialog.component';
import { MomentDatePipe } from '@shared/pipes/moment-date.pipe';
import { ApplicationConfigurationPageComponent } from './admin-components/application-configuration/application-configuration-page/application-configuration-page.component';
import { AdminLandingComponent } from './admin-components/admin-landing/admin-landing.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { PaymentReminderDialogComponent } from './forms/payment-reminder-dialog/payment-reminder-dialog.component';
import { DiscountCodeDialogComponent } from './forms/submit-form/discount-code-dialog/discount-code-dialog.component';
import { UnpaidNotificationsPageComponent } from './admin-components/unpaid-notifications-page/unpaid-notifications-page.component';
import { PaymentReminderTableComponent } from './admin-components/payment-reminder-table/payment-reminder-table.component';
import { MedicalStudentAddressComponent } from '@shared/components/medical-student-address/medical-student-address.component';
import { PgyComponent } from './shared/form-fields/pgy/pgy.component';


export function loadConfigService(configService: AppConfigService) {
    return () => {
        return configService.load();
    };
}

@NgModule({
    declarations: [
        HomeComponent,
        RootComponent,
        PageNotFoundComponent,
        DynamicFormComponent,
        FormFieldGroupComponent,
        SponsorsComponent,
        SponsorsDialogComponent,
        TextFieldComponent,
        CheckBoxComponent,
        DropDownComponent,
        AmpIframeComponent,
        AddressListComponent,
        BasicAddressComponent,
        RadioButtonComponent,
        FormFieldTitleComponent,
        ModuleHeaderComponent,
        LoginComponent,
        YesNoComponent,
        QuestionnaireFormComponent,
        DisableDirective,
        PercentagePipe,
        LandingPageComponent,
        TextAreaComponent,
        AdminPageComponent,
        StatusTableComponent,
        StatusTabComponent,
        DateComponent,
        PlacesComponent,
        SponsorItemComponent,
        TermsOfServiceComponent,
        ViewApplicantComponent,
        SubmitFormComponent,
        EducationInstitutionComponent,
        EducationInstitutionListComponent,
        ApplicationStatusComponent,
        SpinnerComponent,
        ResidencyProgramComponent,
        ResidencyProgramListComponent,
        FellowshipProgramListComponent,
        FellowshipProgramComponent,
        PaymentFormComponent,
        ThankYouComponent,
        ReferralComponent,
        UnchangeableMemberTypeComponent,
        HospitalPrivilegeComponent,
        HospitalPrivilegeListComponent,
        StateDropDownComponent,
        TextInputComponent,
        UnchangeableMemberTypeComponent,
        PracticeHistoryComponent,
        PracticeHistoryListComponent,
        FileUploadComponent,
        NpiFormFieldComponent,
        CertificationComponent,
        LicensureListComponent,
        LicensureComponent,
        FileUploadInfoComponent,
        LabelComponent,
        AutomaticSaveDialogComponent,
        MomentDatePipe,
        ApplicationConfigurationPageComponent,
        AdminLandingComponent,
        PaymentReminderDialogComponent,
        DiscountCodeDialogComponent,
        UnpaidNotificationsPageComponent,
        PaymentReminderTableComponent,
        PgyComponent,
        MedicalStudentAddressComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        HttpClientModule,
        MaterialModule,
        FormsModule,
        AppRoutingModule,
        SharedModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        MatNativeDateModule,
        MatTableModule,
        MatSnackBarModule
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: loadConfigService,
            deps: [AppConfigService, HttpClientModule],
            multi: true
        },
        MomentDatePipe
    ],
    bootstrap: [RootComponent]
})
export class AppModule { }
