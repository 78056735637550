import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ApplicantService } from '@shared/services/forms/applicant.service';
import { FormField, FormFieldConstants } from '@shared/form-fields/models/form-field';
import { ActivatedRoute } from '@angular/router';
import { NavService } from '@shared/services/nav.service';
import { ApplicationService } from '@shared/services/administration/application.service';
import { ApplicationTypeQuestion } from '@shared/models/data/ApplicationTypeQuestion';
import { APP_CONFIG } from '@config/main';
import { MemberApplicationTypeParent } from '@shared/models/data/MemberApplicationTypeParent';


@Component({
    selector: 'app-questionnaire-form',
    templateUrl: './questionnaire-form.component.html',
    styleUrls: ['./questionnaire-form.component.scss']
})
export class QuestionnaireFormComponent implements OnInit {

    constructor(private applicantService: ApplicantService,
        private navService: NavService,
        private route: ActivatedRoute,
        public applicationService: ApplicationService) {

    }

    memberTypes: MemberApplicationTypeParent[] = [];
    applicationType = '';
    questions: FormField[] = [];
    hasQuestionsLoaded = false;
    loading = true;
    continueToApplicationEnabled = false;
    qualifications: string[] = [];
    selectedType: string;
    applicationInProgress = false;
    applicantId: string;
    hasAnsweredMemberType = false;
    @Output() memberApplicationType = new EventEmitter();
    showMessage = false;
    answeredQuestions = [];
    openningYear: string;

    ngOnInit(): void {

        APP_CONFIG.subscribe(_ => {
            this.openningYear = _.openningYear;
        });

        this.route.queryParams.subscribe(params => {
            this.applicantService.getMembershipTypes(params.currentDate).subscribe((response: MemberApplicationTypeParent[]) => {
              this.memberTypes = response.sort((a,b) => a.name.localeCompare(b.name));
                this.loading = false;
            });
        });
    }

    goToSelectMember() {
        this.applicationType = '';
        this.hasAnsweredMemberType = false;
        this.qualifications = [];
        this.answeredQuestions = [];
        this.questions = [];
    }

    getQuestions() {
        this.applicationType = this.selectedType;
        this.applicantService.setSelectedApplicationType(this.applicationType);
        this.applicantService.getMembershipQuestions(this.applicationType).subscribe((response: ApplicationTypeQuestion[]) => {
            const items = new Array<any>();
            for (const field of response) {
                const item = new FormField;
                item.name = this.formatOpenningYear(field.question);
                item.tooltipText = field.id;
                item.additionalValues = FormFieldConstants.Questionnaire;
                items.push(item);
            }
            this.questions = items;
            this.hasQuestionsLoaded = true;
        });
    }

    checkAllQualifications() {
        this.showMessage = this.answeredQuestions.some(x => x.val === false);

        for (let i = 0; i < this.questions.length; i++) {
            if (!this.qualifications.includes(this.questions[i].name)) {
                this.continueToApplicationEnabled = false;
                return;
            }
        }

        this.continueToApplicationEnabled = true;
    }

    questionAnswerCapture(val: any, question: any) {
        const i = this.answeredQuestions.findIndex(x => x.question === question);
        if (i === -1) {
            // if question isn't in the list, add to list
            this.answeredQuestions.push({
                val: val,
                question: question
            });
        } else {
            // if question is in the list, change the value to the event
            this.answeredQuestions[i].val = val;
        }
    }

    addQualification(event: any, question: any) {
        this.questionAnswerCapture(event, question);

        if (event === true) {
            if (this.qualifications.includes(question.name)) {
                this.checkAllQualifications();
                return;
            }
            this.qualifications.push(question.name);
            this.checkAllQualifications();
        } else {
            this.continueToApplicationEnabled = false;
            if (this.qualifications.includes(question.name)) {
                const index = this.qualifications.indexOf(question.name);
                this.qualifications[index] = null;
            }
            this.checkAllQualifications();
        }
    }

    navigateToForm() {
        if (this.continueToApplicationEnabled) {
            this.applicantService.setSelectedApplicationType(this.applicationType);
            this.navService.redirectToForm();
        }
    }

    setApplicationType(memType: MemberApplicationTypeParent) {
        this.selectedType = memType.name;
        window.sessionStorage.setItem('applicationTypeName', memType.name);
        window.sessionStorage.setItem('applicationTypeId', memType.id);
        window.sessionStorage.setItem('productCode', memType.productCode);
        this.hasAnsweredMemberType = true;
    }

    logEvent(event: any, additionalInfo: string = '') {
        this.applicationService.logEvent('Questionnaire', '', event, additionalInfo).subscribe();
    }

    private formatOpenningYear(question: string): string {
        if (question.includes('{{openningYear}}')) {
            question = question.replace('{{openningYear}}', this.openningYear);
        }
        return question;
    }

}
