import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ApplicationParent } from '@shared/models/data/application-parent.model';
import { ModuleManagerService } from '@shared/services/forms/module-manager.service';
import { StatusTabComponent } from '../status-tab/status-tab.component';
import { MemberApplicationType } from '@shared/models/data/MemberApplicationType';
import { ApplicationService } from '@shared/services/administration/application.service';

@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.scss']
})
export class AdminPageComponent implements OnInit {

  @ViewChild(StatusTabComponent)
  statusTabComponent: StatusTabComponent;

  memberApplicationTypes: MemberApplicationType[]
  parentModel: ApplicationParent;
  applicationInProgress: boolean;
  membershipApplicationType: string;
  applicantId: string;
  currentStatusTab: number;

  loading = true;

  constructor(
    private managerService: ModuleManagerService,
    private applicationService: ApplicationService
  ) { }

  ngOnInit(): void {

    this.applicationService.getAllMemberApplicationTypes().subscribe(memberApplicationTypes => {
      this.memberApplicationTypes = memberApplicationTypes.sort((a, b) => a.name.localeCompare(b.name))
      this.loading = false;
    })
  }

  public resetFormState() {
    if (this.parentModel !== undefined && this.parentModel !== null) {
      this.parentModel = this.managerService.adminRemoveFormStateForTesting(this.parentModel);
    }
  }

  public displayFrozenModules() {
    if (this.parentModel === undefined || this.parentModel === null) {
      this.parentModel = this.managerService.buildParent();
    }
    this.managerService.updateParentModule().subscribe((response) => {
      this.parentModel = response;
    });
  }


  onTabChanged(event: MatTabChangeEvent) {
    // https://github.com/angular/components/issues/7274#issuecomment-396293019
    window.dispatchEvent(new Event('resize'));

    this.statusTabComponent.reloadApplications();
  }
}
