<div *ngIf="field" class="form-group" [formGroup]="baseFormGroup" [appDisable]="field.fieldIsDisabled">
  <app-aaos-form-field-title [field]="field" [displayText]="field.displayName"></app-aaos-form-field-title>
  <mat-form-field style="width: 100%" type="text">
    <textarea class="form-control" [formControlName]="field.name" [required]="!field.fieldIsDisabled && isRequired"
      (blur)="validateFormGroup()" [disabled]="field.fieldIsDisabled" matInput (input)="componentChange($event)" rows="5"
      cols="40">{{field.value}}</textarea>
  </mat-form-field>
  <div class="required-text" *ngIf="!field.value && !field.fieldIsDisabled && isRequired">
    This field is required
  </div>
</div>
