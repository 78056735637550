<div class="appNavigation width-vw">
  <div class="container mx-auto">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-6">
        <div class="nav-button-row">

          <button class="btn btn--solid {{backClass}}" (click)="clickBack()">Back</button>
          <button *ngIf="onLastModule" class="btn btn--solid btn--right ml-auto {{nextClass}}" (click)="onComplete()">Complete</button>
          <button *ngIf="!onLastModule" class="btn btn--solid btn--right ml-auto {{nextClass}}" (click)="clickNext()">{{nextButtonText}}</button>

        </div>
      </div>
    </div>
  </div>
</div>
