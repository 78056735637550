import { Component, OnInit, AfterViewInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Constants } from '@shared/services/constants/constants';
import { FileUploadService } from '@shared/services/file-upload.service';
import { ApplicantService } from '@shared/services/forms/applicant.service';
import { BaseFieldComponentDirective } from '../base-field-component';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent extends BaseFieldComponentDirective implements OnInit, AfterViewInit {

  loading = false;
  file: File = null;
  uploadType: string;
  fileUploaded = false;
  completed = '';
  isRequired = true;
  @Input() dynamicForm: FormGroup;
  errorMessage = '';
  numberOfRequired: number;
  @Input() fieldNumber: number;
  @Input() formFields: any;
  fileNames: Array<string>;
  numberOfFilesAlreadyUploaded = 0;
  fileUploadFieldCount = 0;
  loadingFilesUploaded = false;

  constructor(private fileUploadService: FileUploadService, private applicantService: ApplicantService) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.baseFormGroup.get(this.field.name).setValidators(Validators.nullValidator);

    this.fileUploadFieldCount = this.formFields.filter(x => x.fieldType.id.toLowerCase() === 'f11b041d-dd1d-4475-9b23-9cf0ad5d889b').length;

    if (this.field.name.indexOf('Acceptance') !== -1) {
      this.uploadType = Constants.FileUploadTypes.AcceptanceLetter;
    } else if (this.field.name.indexOf('SponsorLetter') !== -1) {
      this.uploadType = Constants.FileUploadTypes.LetterOfRecommendation;
      this.setRequiredNumber();
    } else if (this.field.name.indexOf('Curriculum') !== -1) {
      this.uploadType = Constants.FileUploadTypes.CV;
    } else if (this.field.name.indexOf('National Practictioner Upload') !== -1) {
      this.uploadType = Constants.FileUploadTypes.NationalPractitioner;
    } else {
      this.uploadType = Constants.FileUploadTypes.AdditionalFile;
    }

    this.loadingFilesUploaded = true;
    this.fileUploadService.getFilesUploaded(this.uploadType, this.applicantService.getApplicantId()).subscribe(x => {
      if (x) {
        this.fileNames = x;
        this.numberOfFilesAlreadyUploaded = x.length;
      }
      this.loadingFilesUploaded = false;
      this.onInitValidation();
    });
  }

  ngAfterViewInit(): void {

    if (this.field.displayName.indexOf('Optional') === -1) {
    } else {
      this.isRequired = false;
    }

  }

  onChange(event) {
    this.file = event.target.files[0];
    this.fileUploaded = false;
    this.field.value = this.file.name;
    this.fileUploadService.getFilesUploaded(this.uploadType, this.applicantService.getApplicantId()).subscribe(x => {
      if (x) {
        this.fileNames = x as Array<string>;
        if (this.isFileAreadyUploaded()) {
          this.onInitValidation();
          return;
        }
      }
      this.checkFileValidity();
      this.onInitValidation();
    });
  }

  async onUpload() {
    const files: File[] = [this.file];
    if (this.file !== null) {
      this.fileUploadService.getFilesUploaded(this.uploadType, this.applicantService.getApplicantId()).subscribe(x => {
        if (x) {
          this.onInitValidation();
          this.fileNames = x as Array<string>;
          if (this.isFileAreadyUploaded()) {
            return;
          }
        }
        this.checkFileValidity();
        this.loading = true;
        this.fileUploadService.upload(files, this.uploadType, this.applicantService.getApplicantId()).subscribe(
          (event: any) => {
            if (typeof (event) === 'object') {
              this.checkIfFileUploaded();
            }
          }
        );
      });
    }
  }

  checkIfFileUploaded() {

    const getFileNames$ = this.fileUploadService.getFilesUploaded(this.uploadType, this.applicantService.getApplicantId());

    getFileNames$.subscribe(x => {
      if (x) {
        this.fileNames = null;
        this.fileNames = x as Array<string>;

        if (this.fileNames.includes(this.file.name)) {
          this.completed = 'Upload complete, Thank you';
          this.fileUploaded = true;
          this.loading = false;
          this.numberOfFilesAlreadyUploaded = this.fileNames.length;
        } else {
          this.loading = false;
        }
      } else {
        this.loading = false;
      }
      this.onInitValidation();
    });
  }

  isFileAreadyUploaded() {
    this.onInitValidation();

    if (this.fileNames && this.file && this.fileNames.includes(this.file.name)) {
      this.completed = 'File has already been uploaded';
      this.file = null;
      return true;
    } else {
      this.completed = '';
    }
    return false;
  }


  checkFileValidity() {
    if (this.file === null) {
      // if files already uploaded >= number of uploads here and the uploads are not optional
      const hasOptional = this.field.displayName.includes('(Optional)');
      if ((this.numberOfFilesAlreadyUploaded >= this.fileUploadFieldCount) && !hasOptional) {
        this.errorMessage = '';
      } else {
        this.errorMessage = 'A pdf, doc, or docx file is required';
      }
    } else if (this.file !== null && !this.checkFileTypeValidity(this.file.name)) {
      this.errorMessage = 'A file upload can only be a pdf, doc, or docx';
    } else if (this.file !== null && (this.file.size / 1024) > 10240) {
      this.errorMessage = 'The file size cannot exceed 10MB';
    } else {
      this.errorMessage = '';
    }
  }

  checkFileTypeValidity(fileName: string): boolean {
    const fileType = fileName.substring(fileName.lastIndexOf('.') + 1);
    if (fileType === 'pdf' || fileType === 'doc' || fileType === 'docx') {
      return true;
    } else {
      return false;
    }
  }

  setRequiredNumber() {
    this.numberOfRequired = this.fileUploadFieldCount;
  }

  onInitValidation() {

    if (this.numberOfRequired >= 1 && this.numberOfRequired <= this.numberOfFilesAlreadyUploaded) {
      this.baseFormGroup.setErrors(null);
    } else if ((this.numberOfRequired === undefined && this.fileNames) || this.isRequired === false) {
      this.baseFormGroup.setErrors(null);
    } else {
      this.baseFormGroup.setErrors(Validators.required);
    }
  }

}
