import { Injectable } from '@angular/core';
import { Adapter } from '@shared/services/utils/adapter';

export class AddressDetail {

    companyName: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    country: string;
    postalCode: string;
    countryCode: string;
    addressTypeCode: string;
    addressId: number;
    isPrimaryAddress: boolean;
    billToFlag: boolean;
    shipToFlag: boolean;
    prioritySequence: number;
    statusCode: string;
    jobTitle: string;
    includeInDirectoryFlag: boolean;
    isNew: boolean;
}

@Injectable({
    providedIn: 'root'
  })
  export class AddressDetailAdapter implements Adapter<AddressDetail> {


    adapt(item: any): AddressDetail {
      const addressDetail: AddressDetail = {
        companyName: item.CompanyName,
        address1: item.Address1,
        address2: item.Address2,
        city: item.City,
        state: item.State,
        country: item.Country,
        postalCode: item.PostalCode,
        countryCode: item.CountryCode,
        addressTypeCode: item.AddressTypeCode,
        addressId: item.AddressId,
        isPrimaryAddress: item.IsPrimaryAddress,
        billToFlag: item.BillToFlag,
        shipToFlag: item.ShipToFlag,
        prioritySequence: item.PrioritySequence,
        statusCode: item.StatusCode,
        jobTitle: item.JobTitle,
        includeInDirectoryFlag: item.IncludeInDirectoryFlag,
        isNew: item.IsNew
      };
        return addressDetail;
    }
  }
