import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ApplicantService } from '@shared/services/forms/applicant.service';
import { of } from 'rxjs';
import { concatMap, filter, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-discount-code-dialog',
  templateUrl: './discount-code-dialog.component.html',
  styleUrls: ['./discount-code-dialog.component.scss']
})
export class DiscountCodeDialogComponent implements OnInit {


  formGroup: FormGroup;
  discountCode = '';
  showMessage = false;
  saveSuccess: boolean;
  validDiscountCode: boolean;
  @Output() oidAction = new EventEmitter<string>();

  constructor(public dialogRef: MatDialogRef<DiscountCodeDialogComponent>, private applicantService: ApplicantService) { }

  ngOnInit() {
    this.formGroup = new FormGroup({
      discountCodeControl: new FormControl()
    });
  }

  validateCode() {
    const code = this.formGroup.get('discountCodeControl').value.trim();

    this.applicantService.validateDiscountCode(code)
      .pipe(concatMap(isValid => {
        if (isValid) {
          this.validDiscountCode = true;
          return this.applicantService.saveDiscountCode(code)
        } else {
          this.validDiscountCode = false;
          return null;
        }
      })).subscribe(response => { this.saveSuccess = true; console.log(response) }, (error) => {
        this.saveSuccess = false;
      });
    this.showMessage = true;

  }
}

