import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MembershipInterceptor } from './interceptors/http.interceptor';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HeaderComponent } from './components/header/header.component';
import { MainNavigationComponent } from '@shared/components/main-navigation/main-navigation.component';
import { RouterModule } from '@angular/router';

const entryComponents = [
];
const formFieldComponents = [
];

const components = [
  HeaderComponent,
  MainNavigationComponent
];
const pipes = [
];

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MembershipInterceptor,
      multi: true
    }
  ],
  declarations: [...components, ...entryComponents, ...pipes, ...formFieldComponents],
  entryComponents: [...entryComponents],
  exports: [...components, ...entryComponents, ...pipes, ...formFieldComponents]
})
export class SharedModule { }
