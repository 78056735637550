import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Constants } from './constants/constants';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(private cookieService: CookieService) { }

  public setAdminCookie() {
    this.cookieService.set('admin-user', Constants.Cookies.AdminCookie);
  }

  public removeAdminCookie() {
    this.cookieService.delete('admin-user');
  }

  public checkAdminCookie(): boolean {
    return this.cookieService.get('admin-user') === Constants.Cookies.AdminCookie;
  }
}
