<link rel="stylesheet" href="https://unpkg.com/bs-stepper/dist/css/bs-stepper.min.css">
<script src="https://unpkg.com/bs-stepper/dist/js/bs-stepper.min.js"></script>

<section class="content" *ngIf="applicant">
  <div class="container">
    <div class="row">
      <div class="col"></div>
      <div class="col-sm-6 col-12 text-center">
        <div id="stepper1" class="bs-stepper">
          <div class="bs-stepper-header">
            <div class="step">
              <button class="step-trigger">
                <span class="bs-stepper-circle small-circle"></span>
                <span class="bs-stepper-label"></span>
              </button>
            </div>
            <div class="line"></div>
            <div class="step">
              <button class="step-trigger">
                <span class="bs-stepper-circle pad-for-icon bigger-circle">
                  <mat-icon class="step-icon">playlist_add_check</mat-icon>
                </span>
                <span class="bs-stepper-label">{{this.applicant.applicantApplicationType.status.name}}</span>
              </button>
            </div>
            <div class="line"></div>
            <div class="step">
              <button class="step-trigger">
                <span class="bs-stepper-circle small-circle"></span>
                <span class="bs-stepper-label"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col"></div>
    </div>
    <div class="row justify-content-center">
      <div class="col">
        <div class="application-info">
          <h1>Application #{{this.applicant.applicantApplicationType.id}}</h1>
          <h2 *ngIf="doesCurrentPaidOrderExist !== 'false' ">Status: {{this.applicant.applicantApplicationType.status.name}}
          </h2>
          <h2
            *ngIf="doesCurrentPaidOrderExist === 'false' && this.applicant.applicantApplicationType.status.name === 'Submitted'">
            Status: Pending</h2>
          <h2 *ngIf="doesCurrentPaidOrderExist === 'false'">Payment: Balance Due</h2>
          <h2 *ngIf="doesCurrentPaidOrderExist === 'true'">Payment: Paid</h2>
        </div>
        <div class="thank-you">
          <h3>Hello! Thank you for submitting your application.</h3>
        </div>
        <div class="status-content">
          <div *ngIf="doesCurrentPaidOrderExist === 'false'">
            <p>
              If you submitted an application for: International Surgeon, International Resident, Candidate - Domestic
              Medical Student, or Candidate - International Medical Student, your status above indicates that your
              submission is pending
              payment.
            </p>
            <p>
              Payment of your Membership dues is required for the review of your application. Once complete, check back
              in 3 business days to learn if your application has been approved. You will receive an email confirmation
              with these updates.
              To make your payment and complete your application, click <a href={{paymentUrl}}>HERE</a>.
            </p>
            <p>
              If you submitted an application for: Active Fellow, Affiliate - Orthopaedic Surgeon, or Allied Health
              Member - Physician, Orthopaedic Researcher, or Orthopaedic Team, your status above indicates that your
              submission is pending payment of your application fee. Once
              payment is received, it will be reviewed by AAOS staff.
            </p>
            <p>
              To make your payment and complete your application, click <a href={{paymentUrl}}>HERE</a>.
            </p>
          </div>
          <div *ngIf="doesCurrentPaidOrderExist !== 'false' ">
            <p>
              If you submitted an application for: International Surgeon, International Resident, Candidate - Domestic
              Medical Student, or Candidate - International Medical Student, and your status above indicates that your
              status is submitted and where applicable, paid, it is currently under review. Please check back in 3
              business days to learn if your application has been approved, if you don't hear from us. You will receive
              an email confirmation with these updates. Thank you for applying!
            </p>
            <p>
              If you submitted an application for: Active Fellow, Affiliate - Orthopaedic Surgeon, or Allied Health
              Member - Physician, Orthopaedic Researcher, or Orthopaedic Team, and your status above indicates that your
              status is submitted, and your payment says paid, it is currently in the process of being reviewed by AAOS
              staff. All applications in these categories require additional approval from the AAOS Membership Council
              and the AAOS Board of Directors. Final decisions are announced in the fall, and you will be receiving
              periodic email correspondence with updates in the upcoming months. Thank you for applying!
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-aaos-spinner [isLoading]="loadingPaidOrder || loadingApplicant"></app-aaos-spinner>