<div class="status">
    <mat-tab-group animationDuration="0ms" (selectedTabChange)="onTabChanged($event)" selectedIndex="currentTab">
        <mat-tab label="Incomplete ({{incompletes.applicants?.length || 0}})">
            <div *ngIf="incompletes.applicants?.length > 0; else empty">
                <div class="spacing btn--inline">
                    <button class="btn btn--left btn--small btn--outline" (click)="notifyUser()">NOTIFY
                        USER</button>
                    <button class="btn btn--left btn--small btn--outline" (click)="exportExcelEMails()">Export
                        Emails</button>

                </div>
                <app-status-table [currentStatusTab]="currentTab" [rows]="incompletes.applicants"
                    (selectionEvent)="updateSelection($event)" (deleteApplication)="deleteApplication($event)"
                    [deselectSubject]="deselectSubject">
                </app-status-table>
            </div>
            <ng-template #empty>
                <p>
                    No information found
                </p>
            </ng-template>
        </mat-tab>
        <mat-tab label="Submitted ({{submitted.applicants?.length || 0}})">
            <div *ngIf="submitted.applicants?.length > 0; else empty">
                <div class="spacing btn--inline">
                    <button class="btn btn--left btn--small btn--outline"
                        [disabled]="this.selectedApplications.length === 0" (click)="notifyUser()">NOTIFY
                        USER</button>
                    <div *ngIf="!hideInReview">
                        <button class="btn btn--left btn--small btn--outline"
                            [disabled]="this.selectedApplications.length === 0" (click)="placeUnderReview()">Place Under
                            Review</button>
                    </div>
                    <div *ngIf="showPreapproved">
                        <button class="btn btn--left btn--small btn--outline"
                            [disabled]="this.selectedApplications.length === 0"
                            (click)="preapprove()">Preapprove</button>
                    </div>
                    <button class="btn btn--left btn--small btn--outline"
                        [disabled]="this.selectedApplications.length === 0" (click)="export()">Export</button>
                </div>
                <app-status-table [currentStatusTab]="currentTab" [rows]="submitted.applicants"
                    (selectionEvent)="updateSelection($event)" (deleteApplication)="deleteApplication($event)"
                    [deselectSubject]="deselectSubject"></app-status-table>
            </div>
            <ng-template #empty>
                <p>
                    No information found
                </p>
            </ng-template>
        </mat-tab>

        <mat-tab label="Preapproved ({{preapproveds.applicants?.length || 0}})" *ngIf="hideInReview">
            <div *ngIf="preapproveds.applicants?.length > 0; else empty">
                <div class="spacing btn--inline">
                    <button class="btn btn--left btn--small btn--outline"
                        [disabled]="this.selectedApplications.length === 0" (click)="notifyUser()">NOTIFY
                        USER</button>
                    <button class="btn btn--left btn--small btn--outline"
                        [disabled]="this.selectedApplications.length === 0" (click)="approve()">Final Approval
                    </button>
                    <button class="btn btn--left btn--small btn--outline"
                        [disabled]="this.selectedApplications.length === 0" (click)="export()">Export</button>
                </div>
                <app-status-table [currentStatusTab]="currentTab" [rows]="preapproveds.applicants"
                    (selectionEvent)="updateSelection($event)" (deleteApplication)="deleteApplication($event)"
                    [deselectSubject]="deselectSubject"></app-status-table>
            </div>
            <ng-template #empty>
                <p>
                    No information found
                </p>
            </ng-template>
        </mat-tab>

        <mat-tab label="Under Review ({{review.applicants?.length || 0}})" *ngIf="!hideInReview">
            <div *ngIf="review.applicants?.length > 0 else empty">
                <div class="spacing btn--inline">
                    <button class="btn btn--left btn--small btn--outline" (click)="approve()">Approve</button>
                    <button class="btn btn--left btn--small btn--outline" (click)="deny()">Deny</button>
                    <button class="btn btn--left btn--small btn--outline" (click)="defer()">Defer</button>
                    <button class="btn btn--left btn--small btn--outline" (click)="notifyUser()">Notify User</button>
                    <button class="btn btn--left btn--small btn--outline" (click)="removeFromReview()">Remove From
                        Review</button>
                    <button class="btn btn--left btn--small btn--outline"
                        [disabled]="this.selectedApplications.length === 0" (click)="export()">export</button>
                </div>
                <app-status-table [currentStatusTab]="currentTab" [rows]="review.applicants"
                    (selectionEvent)="updateSelection($event)" (deleteApplication)="deleteApplication($event)"
                    [deselectSubject]="deselectSubject"></app-status-table>
            </div>
            <ng-template #empty>
                <p>
                    No information found
                </p>
            </ng-template>
        </mat-tab>

        <mat-tab label="Archived ({{archived.applicants?.length || 0}})">
            <div *ngIf="archived.applicants?.length > 0 else empty">
                <div class="spacing btn--inline">
                    <button class="btn btn--left btn--small btn--outline"
                        [disabled]="this.selectedApplications.length === 0" (click)="export()">export</button>
                </div>
                <app-status-table [currentStatusTab]="currentTab" [rows]="archived.applicants"
                    (selectionEvent)="updateSelection($event)" (deleteApplication)="deleteApplication($event)"
                    [deselectSubject]="deselectSubject"></app-status-table>
            </div>
            <ng-template #empty>
                <p>
                    No information found
                </p>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
<app-aaos-spinner [isLoading]="loading"></app-aaos-spinner>