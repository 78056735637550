<div class="spacing">
  <div *ngIf="isDeletable() === true">
    <div id="{{this.licensureDetail.id}}" class="delete-file-button" (click)="deleteLicensure($event.target.id)"></div>
  </div>
  <h3>License {{index + 1}}</h3>
</div>

<div *ngIf="!loading">
  <div class="form-group" [formGroup]="baseFormGroup">
    <div class="title title--xs">License Number</div>
    <input class="form-control" formControlName="licenseNumber" id="name_{{this.licensureDetail.id}}"
      (blur)="detectInputChange($event)" required="true" type="text" />
    <div class="required-text" *ngIf="invalidRequiredField('licenseNumber')">License number is required</div>

    <div class="title title--xs">State/Province</div>
    <mat-select *ngIf="(statesLoaded === true && states.length === 0) === false" required="true" formControlName="state" id="state_{{this.licensureDetail.id}}"
      (blur)="detectInputChange($event)" disableRipple disableOptionCentering panelClass="mat-panel">
      <mat-option *ngFor="let state of states" [value]="state.preferenceCode">
        {{ state.preferenceDescription }}
      </mat-option>
    </mat-select>
    <input *ngIf="statesLoaded === true && states.length === 0" class="form-control" formControlName="state" id="state_{{this.licensureDetail.id}}"
      (blur)="detectInputChange($event)" required="true" type="text" />
    <div class="required-text" *ngIf="invalidRequiredField('state')">State is required</div>

    <div class="title title--xs">Issued Date</div>
    <div class="dateContainer">
      <div (click)="issuedDate.open()" class="date--form-control half-width">
        <input [matDatepicker]="issuedDate" formControlName="issuedDate" required="true" disabled="true"
          class="form-control">
      </div>
      <mat-datepicker-toggle [for]="issuedDate"></mat-datepicker-toggle>
      <mat-datepicker disabled="false" #issuedDate ngDefaultControl (closed)="detectInputChange($event)">
      </mat-datepicker>
    </div>
    <div class="required-text" *ngIf="invalidRequiredField('issuedDate') || invalidDateField()" >Begin Date is required and must be
      precede the End Date
    </div>

    <div class="title title--xs">Expire Date</div>
    <div class="dateContainer">
      <div (click)="expireDate.open()" class="date--form-control half-width">
        <input [matDatepicker]="expireDate" formControlName="expireDate" required="true" disabled="true"
          class="form-control">
      </div>
      <mat-datepicker-toggle [for]="expireDate"></mat-datepicker-toggle>
      <mat-datepicker disabled="false" #expireDate ngDefaultControl (closed)="detectInputChange($event)">
      </mat-datepicker>
    </div>
    <div class="required-text" *ngIf="invalidRequiredField('expireDate')">Expire date is required</div>
  </div>
</div>
<app-aaos-spinner [isLoading]="loading"></app-aaos-spinner>
