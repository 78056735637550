import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'momentdate'
})
export class MomentDatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) {
      return '';
    }
    let format = 'MM/DD/YYYY hh:mm A';
    if (args) {
      format = args;
    }

    const utcDate = moment.utc(value.toString()).toDate();
    const local = moment(utcDate).local().format(format);
    return local;
  }
}
