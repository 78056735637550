<div *ngIf="rows">
  
  <table mat-table mat-elevation-z8 matSort [dataSource]="dataSource">

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox color="primary"
          (change)="$event ? masterToggle() : null; updateSelection()" [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let item">
        <mat-checkbox
           (change)="$event ? selection.toggle(item) : null; updateSelection()"
          [checked]="selection.isSelected(item)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="lastName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</mat-header-cell>
      <mat-cell *matCellDef="let item" (click)="viewApplicant(item.applicantId)"><a
          href="javascript:void(0)">{{item.lastName}}</a></mat-cell>
    </ng-container>

    <ng-container matColumnDef="firstName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> First Name </mat-header-cell>
      <mat-cell *matCellDef="let item"> {{item.firstName}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="mcid">
      <mat-header-cell *matHeaderCellDef mat-sort-header> MCID </mat-header-cell>
      <mat-cell *matCellDef="let item"> {{item.mcid}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="primaryEmailAddress">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Email </mat-header-cell>
      <mat-cell *matCellDef="let item"> {{item.primaryEmailAddress}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="submittedDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Date Submitted </mat-header-cell>
      <mat-cell *matCellDef="let item"> {{item.submittedDate | momentdate:'YYYY-MM-DD'}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="createdDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Start Date </mat-header-cell>
      <mat-cell *matCellDef="let item"> {{item.createdDate | momentdate:'YYYY-MM-DD'}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="completion">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Completion </mat-header-cell>
      <mat-cell *matCellDef="let item"> {{item.completion}}% </mat-cell>
    </ng-container>

    <ng-container matColumnDef="applicationStatus">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
      <mat-cell *matCellDef="let item"> {{item.applicationStatus}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="paid">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Paid </mat-header-cell>
      <mat-cell *matCellDef="let item"> {{item.paid}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="delete">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let item">
        <button class="btn btn--left btn--small btn--solid w100 font-size-11" (click)="applicationToDelete(item)">Delete Application</button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
    <mat-row *matRowDef="let item; columns: columnsToDisplay;"></mat-row>
  </table>
</div>
