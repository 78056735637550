<div *ngIf="(dynamicForm)" [formGroup]="baseFormGroup" class="form-group">
    <div *ngIf="(field)" [id]="field.id">
        <mat-label><b> {{field.displayName}}</b></mat-label>
        <div class="text-center" style="margin-top: 1%;" *ngIf="!field.fieldIsDisabled">
            <input type="file" formControlName="{{field.name}}" class="mb20" (change)="onChange($event)">
            <div *ngIf="errorMessage && !field.fieldIsDisabled" class="required-text">
                {{errorMessage}}
            </div>
            <div *ngIf="completed" style="text-align:center">
                {{completed}}
            </div>
            <br>
            <button (click)="onUpload()" [disabled]="errorMessage || field.fieldIsDisabled" class="btn btn--solid">
                Upload
            </button>
        </div>
        <div *ngIf="field.fieldIsDisabled">
            No upload required as field is disabled.
        </div>
        <br>

    </div>
</div>
<app-aaos-spinner [isLoading]="loading || loadingFilesUploaded"></app-aaos-spinner>