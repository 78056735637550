import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-aaos-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RootComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
