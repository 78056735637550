import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FormField } from '@shared/form-fields/models/form-field';
import { isNumber, ceil } from 'lodash';
import { Constants } from '../constants/constants';
import { Utilities } from '../utils/utilities.service';


@Injectable({
  providedIn: 'root'
})
export class FormValidatorService {

  constructor() { }

  static getZipCodeValidator() {

    const validator: ValidatorFn = (control: FormControl): ValidationErrors | null => {
      if (control.value) {
        const zipCodeValidator = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
        const check = zipCodeValidator.test(control.value);
        return !check ? { pattern: true } : null;
      } else {
        return null;
      }
    };
    return validator;

  }

  public static validateNpi(npi: string): boolean {
    try {
      if (this.validateNpiLength(npi)) {
        return this.validateNpiCheckDigit(npi);
      }
      return false;
    } catch (ex) {
      console.error(ex);
      return false;
    }
  }

  public static validateNpiLength(npi: string): boolean {
    return npi.length === 10;
  }

  public static validateNpiCheckDigit(npi: string): boolean {
    const actualLast = parseInt(npi.substring(npi.length - 1));
    let small = npi
      .substring(0, npi.length - 1)
      .split('')
      .map((_, ix) => {
        const parsed = parseInt(_);
        if (!isNumber(parsed)) {
          throw new Error(`Invalid character at position ${ix}: ${_}. Expected digit 0-9`);
        }
        return parsed;
      })
      .map((_, ix) => {
        if (ix % 2 === 0) {
          return ('' + _ * 2)
            .split('')
            .map(n => parseInt(n))
            .reduce((a, b) => a + b);
        } else {
          return _;
        }
      })
      .reduce((a, b) => a + b);
    // TODO: once we start accepting international NPIs, this constant will need to be dynamic based on country
    small += Constants.UserProfile.NPIPrefixTotal_USA;
    const large = ceil(small, -1);
    const calculatedLast = large - small;
    return calculatedLast === actualLast;
  }

  static npiValidation(): ValidatorFn {
    const validator: ValidatorFn = (control: FormControl): ValidationErrors | null => {
      // always validate if they put in a value, only check required if user enters us address
      if (!Utilities.isUndefinedOrNull(control.value) && Utilities.stringEmpty !== control.value) {
        return FormValidatorService.validateNpi(control.value.toString()) ? null : { invalidNpi: true };
      }
      return null;
    };
    return validator;
  }

  static getLanguageValidator() {
    const validator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {

      const lang2 = control.get('Additional Language0')?.value;
      const lang3 = control.get('Additional Language1')?.value;
      const lang1 = control.get('Primary Language')?.value;

      if (lang1 !== null && (lang1 === lang2)) {
        return { duplicate: true };
      } else if (lang1 !== null && (lang1 === lang3)) {
        return { duplicate: true };
      } else if (lang2 != null && (lang2 === lang3)) {
        return { duplicate: true };
      } else {
        return null;
      }

    };
    return validator;
  }

  static getDateValidator(date1: string, date2: string) {
    const validator: ValidatorFn = (baseFormGroup: FormGroup): ValidationErrors | null => {
      const beginDate = new Date(baseFormGroup.get(date1).value);
      const endDate = new Date(baseFormGroup.get(date2).value);
      if (beginDate < endDate){
        return null;
      }
      else {
        return { before: true };
      }
    };
    return validator;
  }

  static getDateFormFieldValidator() {
    const validator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
      const startDate1 = control.get('Issue Date')?.value;
      const startDate2 = control.get('Start Date')?.value;
      const endDate1 = control.get('Expiration Date')?.value;
      const endDate2 = control.get('End Date')?.value;

      if (startDate1 != undefined && endDate1 != undefined){
        if (startDate1 < endDate1){
          return null;
        }
        else {
          return { before: true };
        }
      }
      else if (startDate2 != undefined && endDate2 != undefined) {
        if (startDate2 < endDate2){
          return null;
        }
        else {
          return { before: true };
        }
      }
    };
    return validator;
  }
}
