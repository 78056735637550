import { Component, OnInit } from '@angular/core';
import { APP_CONFIG } from '@config/main';

@Component({
  selector: 'app-places',
  templateUrl: './places.component.html',
  styleUrls: ['./places.component.scss']
})
export class PlacesComponent implements OnInit {

  constructor() { }
  placesApiKey: string;

  ngOnInit() {
    this.getAppConfig();
  }

  getAppConfig() {
    APP_CONFIG.subscribe(_ => {
      this.placesApiKey = _.placesApiKey;
    });
  }

}
