import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-payment-reminder-dialog',
  templateUrl: './payment-reminder-dialog.component.html',
  styleUrls: ['./payment-reminder-dialog.component.scss']
})
export class PaymentReminderDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<PaymentReminderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {

  }

}
