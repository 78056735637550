import { StatusRow } from './status.model';

export class IncompleteExport {
    firstName: string;
    lastName: string;
    mcid: string;
    primaryEmailAddress: string;
    createdDate: string;
    completion: number;
    memberTypeCode: string;
    memberTypeName: string;
    applicationStatus: string;

    constructor(incomplete: StatusRow) {
        this.firstName = incomplete.firstName,
        this.lastName = incomplete.lastName,
        this.mcid = incomplete.mcid,
        this.primaryEmailAddress = incomplete.primaryEmailAddress,
        this.createdDate = incomplete.createdDate,
        this.completion = incomplete.completion,
        this.memberTypeCode = incomplete.memberTypeCode,
        this.memberTypeName = incomplete.memberTypeName,
        this.applicationStatus = incomplete.applicationStatus;
    }
}

