import { Component, OnChanges, OnInit } from '@angular/core';
import { BaseFieldComponentDirective } from '@shared/form-fields/base-field-component';

@Component({
  selector: 'app-aaos-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss']
})
export class RadioButtonComponent extends BaseFieldComponentDirective implements OnInit, OnChanges {

  constructor() {
    super();
  }

  radioOptions: string[];

  ngOnInit() {
    super.ngOnInit();
    this.baseFormGroup.updateValueAndValidity();
    this.validateFormGroup();
    this.parseOptions();
    this.ngOnChanges();
  }

  private parseOptions() {
    if (this.field.additionalValues !== null) {
      this.radioOptions = this.field.additionalValues.split(',');
    }
  }

  ngOnChanges() {
    if (this.field.fieldIsDisabled) {
      this.baseFormGroup.get(this.field.name)?.setValue(null);
      this.baseFormGroup.get(this.field.name)?.disable();
    } else {
      this.baseFormGroup.get(this.field.name)?.enable();
    }
  }

  componentChange(event: any) {
    this.baseFormGroup.updateValueAndValidity();
    this.validateFormGroup();
    this.field.value = event.value;
    this.componentChanged.emit(this.field);
  }

}
