import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApplicantApplication } from '@shared/models/serialization/applicant-application.model';
import { AaosBaseService } from '@shared/services/aaos-base.service';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApplicationParent } from '@shared/models/data/application-parent.model';
import { Constants } from '@shared/services/constants/constants';
import { FormModule, FormModuleAdapter } from '@shared/models/data/form-module.model';
import { LoggingService } from '../logging.service';


@Injectable({
  providedIn: 'root'
})
export class FormStateService extends AaosBaseService {

  constructor(protected formModuleAdapter: FormModuleAdapter, protected logging: LoggingService, protected http: HttpClient) {
    super(logging, http);
  }

  public saveState(module: FormModule): Observable<any>{
    const postData = this.SerializeDynamicFormData(module);
    if (postData !== null) {
      return this.http.post<any>(Constants.ApiUrls.SaveFormState, postData)
        .pipe(
          map((response: any) => {
            if ((!response)) {
              throw new Error();
            }
            this.sessionSave(response);

          }),
          catchError(response => this.handleError(response)),
          map((response: any) => {
            return response;
          })
        );
    }
  }

  GetApplicationForTesting() {
    return this.http.get<string>(Constants.ApiUrls.GetApplicationForTest)
    .pipe(
      map((response: any) => {
        if ((!response)) {
          const error = response.error;
          throw new Error();
        }
        return response;
      }),
      catchError(response => this.handleError(response)),
      map((response: any) => {
        return response;
      })
    );
  }

  serializeData(data: ApplicationParent): ApplicantApplication {

    const applicantApplication = new ApplicantApplication();

    applicantApplication.ToApplicantApplicationDto(data);

    return applicantApplication;
  }

  sessionSave(data: any) {
    window.sessionStorage.removeItem('applicationId');
    window.sessionStorage.removeItem('applicationTypeId');
    window.sessionStorage.removeItem('applicationTypeName');
    window.sessionStorage.removeItem('applicationVersion');
    window.sessionStorage.removeItem('applicationStatusName');
    window.sessionStorage.removeItem('applicationStatusId');

    window.sessionStorage.setItem('applicationId', data.id);
    window.sessionStorage.setItem('applicationTypeId', data.applicationType.id);
    window.sessionStorage.setItem('applicationTypeName', data.applicationType.name);
    window.sessionStorage.setItem('applicationVersion', data.version);
    window.sessionStorage.setItem('applicationStatusName', data.status.name);
    window.sessionStorage.setItem('applicationStatusId', data.status.id);
  }

  public buildParent(data: any): ApplicationParent {
    const parent = new ApplicationParent(this.formModuleAdapter);

    parent.Status = data.status;
    parent.Id = data.id;
    parent.Status = data.status;
    parent.FormState = data.formState;
    parent.buildModules();

    return parent;
  }

  loadFormState(id): Observable<ApplicationParent> {
    const parentModel = this.http.get(Constants.ApiUrls.LoadFormState + id)
      .pipe(
        map((response: any) => {
          if ((!response)) {
            const error = response.error;
            throw new Error();
          }
          return response;
        }),
        catchError(response => this.handleError(response)),
        map((response: any) => {
          return this.buildParent(response);
        })
      );

    return parentModel;
  }

  checkFormState(id: string): Observable<ApplicationParent> {
    const parentModel = this.http.get(Constants.ApiUrls.CheckFormState + id)
      .pipe(
        map((response: any) => {
          if ((!response)) {
            const error = response.error;
            throw new Error();
          }
          return response;
        }),
        catchError(response => this.handleError(response)),
        map((response: any) => {
          return response;
        })
      );
    return parentModel;
  }

  SerializeDynamicFormData(module: FormModule): ApplicantApplication {
    let app = new ApplicantApplication();

    app = this.setSessionStorageValues(app);
    const moduleList = new Array<FormModule>();
    moduleList.push(module);
    app.FormState = JSON.stringify(moduleList);

    return app;
  }

  public setSessionStorageValues(app: ApplicantApplication): ApplicantApplication {
    const appId = window.sessionStorage.getItem('applicationId');
    if (appId !== undefined && appId !== null) {
      app.Id = appId;
    }

    const applicantId = window.sessionStorage.getItem('applicantId');
    if (applicantId !== undefined && applicantId !== null) {
      app.Applicant.Id = applicantId;
    }
    const mcid = window.sessionStorage.getItem('mcid');
    if (mcid !== undefined && mcid !== null) {
      app.Applicant.MasterCustomerId = mcid;
    }
    const appType = window.sessionStorage.getItem('applicationTypeName');
    if (appType !== undefined && appType !== null) {
      app.ApplicationType.Name = appType;
    }
    const appTypeId = window.sessionStorage.getItem('applicationTypeId');
    if (appTypeId !== undefined && appTypeId !== null) {
      app.ApplicationType.Id = appTypeId;
    }
    const appVersion = Number(window.sessionStorage.getItem('applicationVersion'));
    if (appVersion !== undefined && appVersion !== null) {
      app.Version = appVersion;
    }
    const appStatus = window.sessionStorage.getItem('applicationStatusName');
    if (appStatus !== undefined && appStatus !== null) {
      app.Status.Name = appStatus;
      app.Status.Id = window.sessionStorage.getItem('applicationStatusId');
    } else {
      app.Status.Name = Constants.AppStatus.IncompleteName;
      app.Status.Id = Constants.AppStatus.IncompleteId;
    }

    return app;
  }

  public GetApplicationList(applicationType: string): Observable<any[]> {
    return this.http.get(Constants.ApiUrls.LoadApplicationList + applicationType)
    .pipe(
      map((response: any) => {
        if ((!response)) {
          const error = response.error;
          throw new Error();
        }
        return response;
      }),
      catchError(response => this.handleError(response)),
      map((response: any[]) => {
        return response;
      })
    );
  }
}

