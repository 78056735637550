<div class="close-container">
  <mat-icon class="close-icon" (click)="dialogRef.close()"></mat-icon>
</div>
<div class="title title--lg dialog" mat-dialog-title>Search Results</div>
<div class="smallerText italic mat-radio-label-content">Location Information will not be published. It is used to assist in appropriate selection.</div>
<mat-dialog-content>
  <mat-radio-group aria-label="Select an option" class="radio" [(ngModel)]="sponsor">
    <mat-radio-button *ngFor="let item of data.results" [value]="item" >
      <div *ngIf="formatDisplayText(item)"></div>
      <span class="bold list-font rb-word-wrap">{{item.labelName}}</span>
      <div class="bold smallerText list-font">{{searchResultDisplayCity}}</div>
    </mat-radio-button>
  </mat-radio-group>
  <div class="required-text" *ngIf="data.maxLimitReached">
    Please be more specific in your search as there are more results available.
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="btn--inline dialog-btn-container">
    <button (click)="sponsorNotFound()"class="btn btn--small btn--outline hide-on-mobile ">SPONSOR NOT FOUND</button>
    <button (click)="addSponsor()" [disabled]="data.results.length < 1 || !sponsor" class="btn btn--small btn--solid btn--red">ADD SPONSOR</button>
  </div>
</mat-dialog-actions>
