<div *ngIf="(dynamicForm)" class="form-group" [formGroup]="baseFormGroup">
    <div *ngIf="(field)" [id]="field.id">
        <div class="flex-align-center">
            <app-aaos-form-field-title [field]="field" [displayText]="field.displayName"></app-aaos-form-field-title>
            <div *ngIf="field.tooltipText" class="btn btn--round btn--blue info-icon" matTooltip="{{field.tooltipText}}"
                matTooltipPosition="right" matTooltipHideDelay="4000">i</div>
        </div>

        <div *ngIf="!field.fieldIsDisabled else display">
            <mat-select [formControlName]="field.name" [disabled]="field.fieldIsDisabled" [required]="isRequired"
                [(value)]="selectedValue" disableRipple panelClass="mat-panel" [formOptions]="formOptions"
                (blur)="componentChange()">
                <mat-option *ngFor="let option of options" [disabled]="field.fieldIsDisabled" [required]="isRequired"
                    [value]="option.preferenceCode">{{ option.preferenceDescription }}
                </mat-option>
            </mat-select>
        </div>

        <div class="required-text" *ngIf="isRequired && !selectedValue && !field.value">
            {{field.displayName}} is required
        </div>
        <ng-template #display>
            {{field.value}}
        </ng-template>
    </div>
</div>