import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { APP_CONFIG } from '@config/main';
import { Constants } from '@shared/services/constants/constants';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})

export class NavService {

  createAccountUrl: string;

  constructor(private router: Router,
    private sessionService: SessionService
  ) {
    APP_CONFIG.subscribe(_ => {
      this.createAccountUrl = _.createAccountLink;
    });
  }

  public redirectToForm() {
    this.router.navigate(['form'], { queryParamsHandling: 'preserve' });
  }

  public redirectToLanding() {
    this.router.navigate(['landing']);
  }

  public redirectToApplicantStatus() {
    this.router.navigate(['status'], { queryParamsHandling: 'preserve' });
  }

  public redirectToReferralPage() {
    this.router.navigate(['referral']);
  }

  public redirectToThankYouPage() {
    this.router.navigate(['thankyou']);
  }

  public redirectToRoot() {
    this.router.navigate(['']);
  }

  public createAaosAccount() {
    window.location.href = this.createAccountUrl;
  }

  public loginToAaos() {
    window.location.href = window.location.origin + Constants.ApiUrls.SsoLogin + window.location.search;
  }

  public redirectToQuestionnaire() {
    this.router.navigate(['questionnaire'], { queryParamsHandling: 'preserve' });
  }

  public redirectToSubmit() {
    this.router.navigate(['submit']);
  }

  public redirectToPay(discountCode?: string) {
    if (discountCode) {
      this.router.navigate(['pay'], { queryParams: { 'oid': discountCode } });
    } else {
      this.router.navigate(['pay']);
    }
  }

  public redirectToUnchangeableMemberType() {
    this.router.navigate(['membertypeissue']);
  }

  public refreshWindow() {
    this.router.navigate(['landing']);
  }

  public adminView() {
    this.router.navigate(['administration']);
  }

  public adminLanding() {
    this.sessionService.setAdminCookie();
    this.router.navigate(['admin-selection']);
  }

  public applicationConfiguration() {
    this.router.navigate(['application-configuration']);
  }

  public viewApplicant(appId: string) {
    this.router.navigate(['viewApplicant'], { state: { id: appId } });
  }

  public unpaidNotifications() {
    this.router.navigate(['unpaid-notifications']);
  }
}
