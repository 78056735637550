<div *ngIf="isDeletable() === true">
  <div id="{{this.hospitalPrivilegeDetail.id}}" class="delete-file-button" (click)="deleteHospital($event.target.id)"></div>
</div>
<div *ngIf="!loading">
  <div class="form-group" #hospitalInfo [formGroup]="baseFormGroup">
    <div class="title title--xs">Name</div>
    <input class="form-control" formControlName="name" id="name_{{this.hospitalPrivilegeDetail.id}}" (blur)="detectInputChange($event)"
        required="true" type="text" />
    <div class="required-text" *ngIf="invalidRequiredField('name')">Name is required</div>

    <div class="title title--xs">Department Name</div>
    <input class="form-control" formControlName="departmentName" id="departmentName_{{this.hospitalPrivilegeDetail.id}}" (input)="detectInputChange($event)"
        required="true" type="text" />
    <div class="required-text" *ngIf="invalidRequiredField('departmentName')">Department Name is required</div>

    <div class="title title--xs">City</div>
    <input class="form-control" formControlName="city" id="city_{{this.hospitalPrivilegeDetail.id}}" (blur)="detectInputChange($event)"
      required="true" type="text" />
    <div class="required-text" *ngIf="invalidRequiredField('city')">City is required</div>

    <div class="title title--xs">State/Province</div>
    <mat-select *ngIf="(statesLoaded === true && states.length === 0) === false" required="true" formControlName="state" id="state_{{this.hospitalPrivilegeDetail.id}}" (blur)="detectInputChange($event)" disableRipple disableOptionCentering
      panelClass="mat-panel" >
      <mat-option *ngFor="let state of states" [value]="state.preferenceCode">
        {{ state.preferenceDescription }}
      </mat-option>
    </mat-select>
    <input *ngIf="statesLoaded === true && states.length === 0" class="form-control" formControlName="state" id="state_{{this.hospitalPrivilegeDetail.id}}" (blur)="detectInputChange($event)"
      required="true" type="text" />
    <div class="required-text" *ngIf="invalidRequiredField('state')">State is required</div>
  </div>
</div>
<app-aaos-spinner [isLoading]="loading"></app-aaos-spinner>
