import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MemberApplicationStatusModel } from '@shared/models/data/MemberApplicationStatusModel';
import { StatusRow } from '@shared/models/data/status.model';
import { ApplicantApplication } from '@shared/models/serialization/applicant-application.model';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AaosBaseService } from '../aaos-base.service';
import { Constants } from '../constants/constants';
import { LoggingService } from '../logging.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService extends AaosBaseService {

  constructor(protected _h: HttpClient, protected _l: LoggingService,
  ) { super(_l, _h); }

  updateApplicationStatus(applicant: StatusRow, status: string) {
    const aa = new ApplicantApplication();
    aa.Applicant.Id = applicant.applicantId;
    aa.Applicant.MasterCustomerId = applicant.mcid;
    aa.Status.Id = status;
    aa.Id = applicant.applicationId;
    return this.http.patch(`${Constants.ApiUrls.UpdateApplication}`, aa, { responseType: 'json' })
      .pipe(
        map((response: any) => {
          if ((!response)) {
            throw new Error(response.error);
          }
          return response;
        }),
        catchError(response => this.handleError(response)),
        map((response: any) => {
          return response;
        })
      );
  }

  updateApplicationStatusDbOrPersonifyStatusRow(statusRow: StatusRow, updatedStatus: string) {
    if (statusRow.boardRequiredApproval === true) {
      return this.updateApplicationStatusPersonify(statusRow, updatedStatus);
    } else {
      return this.updateApplicationStatus(statusRow, updatedStatus);
    }
  }

  updateApplicationStatusPersonify(statusRow: StatusRow, updatedStatus: string) {
    const memberAppicationStatusPersonify = new MemberApplicationStatusModel();
    memberAppicationStatusPersonify.MasterCustomerId = statusRow.mcid;
    memberAppicationStatusPersonify.MemberGroup = statusRow.memberTypeCode;
    memberAppicationStatusPersonify.ResidencyEndDate = new Date();
    memberAppicationStatusPersonify.Status = updatedStatus;
    memberAppicationStatusPersonify.ApplicationTypeId = statusRow.memberApplicationTypeId;

    return this.http.post(`${Constants.ApiUrls.UpdateApplicationPersonify}`, memberAppicationStatusPersonify, { responseType: 'json' })
      .pipe(
        map((response: any) => {
          if ((!response)) {
            throw new Error(response.error);
          }
          return response;
        }),
        catchError(response => this.handleError(response)),
        map((response: any) => {
          return response;
        })
      ).toPromise();
  }

  preapprovalWorkflow(mcids: Array<string>): Observable<string[]> {
    
    return this.http.put(`${Constants.ApiUrls.PreapprovalWorkflow}`, JSON.stringify(mcids), { responseType: 'json' })
      .pipe(
        map((response: any) => {
          if ((!response)) {
            throw new Error(response.error);
          }
          return response;
        }),
        catchError(response => this.handleError(response)),
        map((response: any) => {
          return response;
        })
      )
  }

  notifyApplicationStatus(mcid: string) {
    return this.http.patch(`${Constants.ApiUrls.NotifyApplication}`, { responseType: 'json' })
      .pipe(
        map((response: any) => {
          if ((!response)) {
            throw new Error(response.error);
          }
          return response;
        }),
        catchError(response => this.handleError(response)),
        map((response: any) => {
          return response;
        })
      );
  }
  public async notifyUser(mcid: string, applicationTypeId: string): Promise<any>{
    const params = new HttpParams()
      .set('mcid', mcid)
      .set('applicationTypeId', applicationTypeId);

    var p = this.http.get(`${Constants.ApiUrls.NotifyUser}`, { params })
      .pipe(
        map((response: any) => {
          if ((!response)) {
            throw new Error(response.error);
          }
          return response;
        }),
        catchError(response => this.handleError(response)),
        map((response: any) => {
        })
      ).toPromise();
      return p;
  }

  deleteApplication(applicationId: string): Observable<ApplicantApplication> {
    return this.http.delete<ApplicantApplication>(`${Constants.ApiUrls.DeleteApplication}/${applicationId}`)
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  getApplicationHtml(applicantId: string): Observable<string> {
    return this.http.get<string>(`${Constants.ApiUrls.GetApplicationHtml}/${applicantId}`)
      .pipe(
        map((response: any) => {
          return response.value;
        })
      );
  }

  getAllMemberApplicationTypes(): Observable<any> {
    return this.http.get(Constants.ApiUrls.GetAllMemberApplicationTypes).pipe();
  }

  getAllMemberGroupCodes(): Observable<any> {
    return this.http.get(Constants.ApiUrls.GetAllMemberGroupCodes).pipe();
  }

  getMemberApplicationMappings(): Observable<any> {
    return this.http.get(Constants.ApiUrls.GetMemberApplicationMappings).pipe();
  }

  addMemberApplicationMapping(mapping: any): Observable<any> {
    return this.http.post(Constants.ApiUrls.AddMemberApplicationMapping, mapping)
      .pipe(
        map(x => {
          return x;
        })
      );
  }

  deleteMemberApplicationMapping(id: number): Observable<any> {
    return this.http.delete(`${Constants.ApiUrls.DeleteMemberApplicationMapping}/${id}`).pipe();
  }

  modifyMemberApplicationMapping(mapping: any): Observable<any> {
    return this.http.put(Constants.ApiUrls.ModifyMemberApplicationMapping, mapping)
      .pipe(
        map(x => {
          return x;
        })
      );
  }

  logEvent(page: string, module: string, event: any, additionalInfo: string): Observable<boolean> {
    const logEvent = {
      page: page,
      module: module,
      elementId: event.target.id || '',
      elementText: event.target.innerText || '',
      elementValue: event.target.value || '',
      eventType: event.type || '',
      additionalInfo: additionalInfo || ''
    };

    return this.http.post<boolean>(`${Constants.ApiUrls.LogEvent}`, logEvent)
      .pipe(
        map(x => {
          return x;
        })
      );
  }
}
