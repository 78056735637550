import { Injectable, OnInit } from '@angular/core';
import { BaseFieldComponentDirective } from '@shared/form-fields/base-field-component';
import { FormOptionsMapperService } from '@shared/services/forms/form-options-mapper.service';
import { Preference } from '@shared/models/data';

@Injectable()
export class BaseMultipleOptionsField extends BaseFieldComponentDirective implements OnInit {

    constructor(private formFieldsOptionsMapper: FormOptionsMapperService) { super(); }

    options: Preference[] = [];

    ngOnInit() {
        this.options = this.field ? this.formFieldsOptionsMapper.getOptionsForField(this.field.id, this.formOptions) : null;
    }

}
