import { Component, Input, OnInit } from '@angular/core';
import { CustomerSearchProfileMini } from '@shared/models/data/CustomerSearchProfileMini';
import { Constants } from '@shared/services/constants/constants';
import { SponsorsComponent } from '../sponsors/sponsors.component';

@Component({
  selector: 'app-aaos-sponsor-item',
  templateUrl: './sponsor-item.component.html',
  styleUrls: ['./sponsor-item.component.scss']
})
export class SponsorItemComponent implements OnInit {

  @Input() index: number;
  @Input() sponsor: CustomerSearchProfileMini;
  searchResultDisplayCity: string;
  sponsorIndex: string;


  constructor(private sponsorComponent: SponsorsComponent) { }

  ngOnInit() {
    this.formatDisplayText();
  }

  formatDisplayText() {
    if (this.sponsor && this.sponsor.country !== Constants.CountryConstants.UnitedStates) {
      this.searchResultDisplayCity = `${this.sponsor.city}, ${this.sponsor.country}`;
    } else {
      this.searchResultDisplayCity = `${this.sponsor.city}, ${this.sponsor.state}`;
    }
  }

  deleteSponsor(id: string) {
    this.sponsorComponent.deleteSponsor(id);
  }

}
