import { Component, OnInit } from '@angular/core';
import { NavService } from '@shared/services/nav.service';

@Component({
  selector: 'app-admin-landing',
  templateUrl: './admin-landing.component.html',
  styleUrls: ['./admin-landing.component.scss']
})
export class AdminLandingComponent implements OnInit {

  constructor(private navService: NavService) { }

  ngOnInit() {
  }

  navigateToDashboard() {
    this.navService.adminView();
  }

  navigateToAppConfig() {
    this.navService.applicationConfiguration();
  }

  navigateToUnpaidNotifications() {
    this.navService.unpaidNotifications();
  }

}
