import { Component, OnInit, Input } from '@angular/core';
import { Validators } from '@angular/forms';
import { BaseFieldComponentDirective } from '@shared/form-fields/base-field-component';

@Component({
  selector: 'app-aaos-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss']
})
export class TextFieldComponent extends BaseFieldComponentDirective implements OnInit {
  isEmail = false;
  isOptional: boolean;
  @Input() placeHolder = '';
  isRequired: boolean;

  ngOnInit() {
    super.ngOnInit();

    const constField = this.baseFormGroup.get(this.field.name);

    if (this.field.name === 'Primary Email') {
      this.isEmail = true;
    }

    if (this.field.displayName.indexOf('Optional') !== -1) {
      this.isRequired = false;
      constField.setValidators([Validators.nullValidator]);
    }
  }

}
