import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { empty } from 'rxjs';
import { LogLevel } from '@shared/models/enums/log-levels';
import { Log } from '@shared/models/data/log';
import { Constants } from '@shared/services/constants/constants';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  private _logs: Log[];

  constructor(private http: HttpClient) {
    this._logs = [];
  }

  get logs() {
    return this._logs;
  }

  addLogToQueue(log: Log, suppressConsole?: boolean) {
    if (log != null && !this._logs.find(_ => _ === log)) {
      this._logs.push(log);
      if (!suppressConsole) {
        console.error(log.Message);
      }
    }
  }

  sendLogsToServer(filter: LogLevel = LogLevel.Error) {
    if (this._logs != null && this._logs.length > 0) {
      const logs = this._logs.filter(_ => _.LogLevel <= filter);
      this.http.post(Constants.ApiUrls.SendLogs, logs).subscribe();
    }
  }

  sendLogToServer(log: Log, filter: LogLevel = LogLevel.Error) {
    if (log != null) {
      this.http.post(Constants.ApiUrls.SendLog, log).subscribe();
    }
  }
}
