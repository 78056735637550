<div class="required-text" *ngIf="licensureDetails.length < 1">
  At least one License is required
</div>
<div class="spacing">
  <div *ngFor="let licensureDetail of licensureDetails; index as i">
    <app-aaos-licensure class="spacing" [index]="i" [dynamicForm]="dynamicForm"
      [licensureDetail]="licensureDetail" (componentChanged)="onComponentChange($event)"
      [fieldsPrerequisite]="fieldsPrerequisite">
      </app-aaos-licensure>
  </div>
</div>
<button class="btn btn--solid" (click)="addLicense()">ADD LICENSE</button>
