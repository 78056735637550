<div class="form-group" *ngIf="(field)" [formGroup]="baseFormGroup">
    <app-aaos-form-field-title [field]="field" [displayText]="field.displayName"></app-aaos-form-field-title>
    <mat-radio-group aria-label="Select an option" class="radio" formControlName="{{field.name}}"
        [disabled]="field.fieldIsDisabled" [required]="!field.fieldIsDisabled">
        <mat-radio-button *ngFor="let option of radioOptions" [checked]="option === field.value"
            (change)="componentChange($event)" [value]="option" [disabled]="field.fieldIsDisabled">{{option}}
        </mat-radio-button>
    </mat-radio-group>
    <div class="required-text" *ngIf="!field.value && !field.fieldIsDisabled">
        This field is required
    </div>
</div>