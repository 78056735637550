import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { IncompleteExport } from '@shared/models/data/incomplete-export';
import { StatusModel, StatusRow, StatusRowAdapter, StatusType } from '@shared/models/data/status.model';
import { ApplicationService } from '@shared/services/administration/application.service';
import { Constants } from '@shared/services/constants/constants';
import { ApplicantService } from '@shared/services/forms/applicant.service';
import { FormStateService } from '@shared/services/forms/form-state.service';
import { Subject } from 'rxjs';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-status-tab',
  templateUrl: './status-tab.component.html',
  styleUrls: ['./status-tab.component.scss']
})
export class StatusTabComponent implements OnInit {


  incompletes = new StatusType();
  submitted = new StatusType();
  review = new StatusType();
  archived = new StatusType();
  preapproveds = new StatusType();
  selectedApplications = new Array<StatusRow>();
  currentTab = 0;
  memberTypeApplications: StatusModel = {
    applicationType: '',
    applications: new Map<string, Array<StatusRow>>()
  };
  loading: boolean;
  @Input() memberTypeId: string;
  applications: StatusType[];
  deselectSubject: Subject<any> = new Subject();
  hideInReview: boolean = false;
  showPreapproved: boolean = false;

  constructor(
    private changeDetectorRefs: ChangeDetectorRef,
    private applicationService: ApplicationService,
    private formStateService: FormStateService,
    private adapter: StatusRowAdapter,
    private applicantService: ApplicantService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.getApplicationType(this.memberTypeId);
    if (this.memberTypeId.toLowerCase() == Constants.MemberApplicationTypes.InternationalMemberId.toLowerCase()
      || this.memberTypeId.toLowerCase() == Constants.MemberApplicationTypes.InternationalResidentId.toLowerCase()) {
      this.hideInReview = true;
      this.showPreapproved = true;
    }
  }

  public getApplicationType(memberTypeId): void {
    this.loading = true;
    this.formStateService.GetApplicationList(memberTypeId).subscribe((response: any[]) => {
      this.applications = new Array<StatusType>();
      response.forEach((item) => {
        const statusType = new StatusType();
        statusType.applicants = new Array<StatusRow>();
        statusType.applicationStatus = item.status;
        item.applicationStatus = item.status;
        statusType.applicants.push(this.adapter.adapt(item));
        this.applications.push(statusType);
      });
      this.parseStatus(this.applications);
      this.validateIncompleteToSubmit();
      this.changeDetectorRefs.detectChanges();
      this.loading = false;
    });
  }

  openNotificationSentSnackbar(message: string) {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 5000
    });
  }

  public parseStatus(applicants: StatusType[]) {
    if (applicants !== undefined && applicants !== null) {

      applicants.forEach((applicant) => {
        switch (applicant.applicationStatus) {
          case 'Incomplete': {
            this.incompletes = this.addToTypeArray(applicant.applicants, this.incompletes, 'Incomplete');
            break;
          }
          case 'Submitted': {
            this.submitted = this.addToTypeArray(applicant.applicants, this.submitted, 'Submitted');
            break;
          }
          case 'In Review': {
            this.review = this.addToTypeArray(applicant.applicants, this.review, 'In Review');
            break;
          }
          case 'Preapproved': {
            this.preapproveds = this.addToTypeArray(applicant.applicants, this.preapproveds, 'Preapproved');
            break;
          }
          case 'Approved': {
            this.archived = this.addToTypeArray(applicant.applicants, this.archived, '');
            break;
          }
          case 'Denied': {
            this.archived = this.addToTypeArray(applicant.applicants, this.archived, '');
            break;
          }
          case 'Deferred': {
            this.archived = this.addToTypeArray(applicant.applicants, this.archived, '');
            break;
          }
        }
      });

      // remove duplicates from the lists (also helps with correct counts)
      this.incompletes.applicants = this.incompletes.applicants?.filter((row, i, arr) => {
        return arr.indexOf(arr.find(t => t.applicationId === row.applicationId)) === i;
      });
      this.submitted.applicants = this.submitted.applicants?.filter((row, i, arr) => {
        return arr.indexOf(arr.find(t => t.applicationId === row.applicationId)) === i;
      });
      this.review.applicants = this.review.applicants?.filter((row, i, arr) => {
        return arr.indexOf(arr.find(t => t.applicationId === row.applicationId)) === i;
      });
      this.preapproveds.applicants = this.preapproveds.applicants?.filter((row, i, arr) => {
        return arr.indexOf(arr.find(t => t.applicationId === row.applicationId)) === i;
      });
      this.archived.applicants = this.archived.applicants?.filter((row, i, arr) => {
        return arr.indexOf(arr.find(t => t.applicationId === row.applicationId)) === i;
      });

      this.memberTypeApplications.applications.set(this.incompletes.applicationStatus, this.incompletes.applicants);
      this.memberTypeApplications.applications.set(this.submitted.applicationStatus, this.submitted.applicants);
      this.memberTypeApplications.applications.set(this.review.applicationStatus, this.review.applicants);
      this.memberTypeApplications.applications.set(this.preapproveds.applicationStatus, this.preapproveds.applicants);
      this.memberTypeApplications.applications.set(this.archived.applicationStatus, this.archived.applicants);
      this.loading = false;
    }
  }

  private addToTypeArray(app: StatusRow[], statusType: StatusType, applicationType: string): StatusType {
    if (statusType.applicants == null) {
      statusType.applicants = new Array<StatusRow>();
    }
    statusType.applicationStatus = applicationType;
    app.forEach((item) => {
      statusType.applicants.push(item);
    });

    return statusType;
  }

  reloadApplications() {
    this.review.applicants = Array<StatusRow>();
    this.submitted.applicants = Array<StatusRow>();
    this.incompletes.applicants = Array<StatusRow>();
    this.archived.applicants = Array<StatusRow>();
    this.preapproveds.applicants = Array<StatusRow>();
    this.getApplicationType(this.memberTypeId);
    this.changeDetectorRefs.detectChanges();
    this.loading = false;
  }

  async notifyUser() {

    for (var i = 0; i < this.selectedApplications.length; i++) {
      var response = await this.applicationService.notifyUser(this.selectedApplications[i].mcid, this.selectedApplications[i].memberApplicationTypeId).then()
        .catch(error => {
          alert("Error notifying applicant:" + this.selectedApplications[i].mcid);
        });
    }
    let message = 'Notification sent';
    if (this.selectedApplications.length > 1) {
      message = 'Notifications sent';
    }
    this.openNotificationSentSnackbar(message);

  }

  async placeUnderReview() {
    const review = async () => {
      for (var i = 0; i < this.selectedApplications.length; i++) {
        this.loading = true;
        if (this.selectedApplications[i].boardRequiredApproval) {
          await this.applicationService.updateApplicationStatusPersonify(this.selectedApplications[i], Constants.AppStatus.InReviewId).then(async x => {
            if (x) {
              var response = await this.applicationService.notifyUser(this.selectedApplications[i].mcid, this.selectedApplications[i].memberApplicationTypeId).then(success => { });
            }
          }).catch(error => {
            alert('Error on application status update: ' + this.selectedApplications[i].mcid);
          });
        } else {
          await this.applicationService.updateApplicationStatus(this.selectedApplications[i], Constants.AppStatus.InReviewId).toPromise().then(x => { })
            .catch(error => {
              alert('Error on application status update: ' + this.selectedApplications[i].mcid);
            });
        }
      }
    }
    await review()
    this.reloadApplications();
  }

  export() {
    const applicationGuids = this.selectedApplications.map(x => x.applicationId);
    this.applicantService.getApplicationPdf(applicationGuids).subscribe();
  }

  async approve() {
    for (var i = 0; i < this.selectedApplications.length; i++) {
      this.loading = true;
      await this.applicationService.updateApplicationStatusPersonify(this.selectedApplications[i], Constants.AppStatus.ApprovedId).then(async x => {
        if (x) {
          var response = await this.applicationService.notifyUser(this.selectedApplications[i].mcid, this.selectedApplications[i].memberApplicationTypeId).then(success => { });

        }
      }).catch(error => {
        alert('Error on application status update: ' + this.selectedApplications[i].mcid);
      });
    }
    this.reloadApplications();
  }

  preapprove() {
    this.loading = true;
    var mcids = this.selectedApplications.map(x => x.mcid);
    this.applicationService.preapprovalWorkflow(mcids)
      .subscribe(async x => {
        for (var mcid of x) {
          await this.applicationService.notifyUser(mcid, this.selectedApplications[0].memberApplicationTypeId).then(success => { });
        };
      },
        (error) => {
          alert('Error on application status update: ' + JSON.parse(error));
        }).add(() => { this.reloadApplications() });

  }

  async finalApproval() {
    this.loading = true;
    var selectedMcids = this.selectedApplications.map(x => x.mcid);
    for (var mcid of selectedMcids) {
      await this.applicationService.notifyUser(mcid, this.selectedApplications[0].memberApplicationTypeId).then(success => { });
    };
    this.reloadApplications();
  }

  async deny() {
    for (var i = 0; i < this.selectedApplications.length; i++) {
      this.loading = true;
      await this.applicationService.updateApplicationStatusPersonify(this.selectedApplications[i], Constants.AppStatus.DeniedId).then(async x => {
        if (x) {
          var response = await this.applicationService.notifyUser(this.selectedApplications[i].mcid, this.selectedApplications[i].memberApplicationTypeId).then(success => { });
        }
      }).catch(error => {
        alert('Error on application status update: ' + this.selectedApplications[i].mcid);
      });
    }
    this.reloadApplications();
  }

  async removeFromReview() {

    const remove = async () => {
      this.loading = true;
      for await (var app of this.selectedApplications) {
        await this.applicationService.updateApplicationStatus(app, Constants.AppStatus.SubmittedId).toPromise().then(x => {
        },
          (error) => {
            alert('Error on application status update.');
          });
      };
    }
    await remove()
    this.reloadApplications();
  }

  async defer() {
    for (var i = 0; i < this.selectedApplications.length; i++) {
      this.loading = true;
      await this.applicationService.updateApplicationStatusPersonify(this.selectedApplications[i], Constants.AppStatus.DeferredId).then(async x => {
        if (x) {
          var response = await this.applicationService.notifyUser(this.selectedApplications[i].mcid, this.selectedApplications[i].memberApplicationTypeId).then(success => { });
        }
      }).catch(error => {
        alert('Error on application status update: ' + this.selectedApplications[i].mcid);
      });
    }
    this.reloadApplications();
  }

  updateSelection(event) {
    this.selectedApplications = (event);
  }

  onTabChanged(event: MatTabChangeEvent) {
    this.selectedApplications = [];
    this.deselectSubject.next();
    this.currentTab = event.index;
  }

  public deleteApplication(application: StatusRow) {
    this.loading = true;
    this.applicationService.deleteApplication(application.applicationId).subscribe(x => {
      this.reloadApplications();
    });
  }

  private validateIncompleteToSubmit() {
    if (this.incompletes.applicants?.find(x => x.completion === 100 && x.paid !== 'false')) {
      this.incompletes.applicants.forEach(applicant => {
        if ((applicant.completion === 100) && (applicant.paid !== 'false')) {
          this.applicantService.submitApplication(applicant.applicantId).subscribe();
        }
      });
      this.reloadApplications();
    }
  }

  exportExcelEMails() {
    const incompleteExports = this.incompletes.applicants.map(x => {
      return new IncompleteExport(x);
    });
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(incompleteExports);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'IncompleteApplications.xlsx');
  }

}
