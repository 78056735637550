import { Component, OnInit } from '@angular/core';
import { PaymentReminder } from '@shared/models/data/payment-reminder';
import { PaymentReminderService } from '@shared/services/administration/payment-reminder.service';

@Component({
  selector: 'app-unpaid-notifications-page',
  templateUrl: './unpaid-notifications-page.component.html',
  styleUrls: ['./unpaid-notifications-page.component.scss']
})
export class UnpaidNotificationsPageComponent implements OnInit {


  constructor(protected _paymentReminderService: PaymentReminderService) { }

  ngOnInit() {}

}
