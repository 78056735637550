import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { EnvironmentTypes, LogLevel } from '@shared/models/enums/index';
import { Utilities } from '@shared/services/utils/utilities.service';

export class AppConfig {
    readonly apiEndpoint: string;
    readonly environmentType: EnvironmentTypes;
    readonly baseUrl: string;
    readonly logLevel: LogLevel;
    readonly apiKey: string;
    readonly dataServiceUrl: string;
    readonly membershipApiKey: string;
    readonly authRedirectUrl: string;
    readonly ampUrl: string;
    readonly ampOrigin: string;
    readonly placesApiKey: string;
    readonly ampTimeout: number;
    readonly oneclickUrl: string;
    readonly referralLink: string;
    readonly becomeAMemberLink: string;
    readonly createAccountLink: string;
    readonly paymentUrl: string;
    readonly bylawsUrl: string;
    readonly standardsOfPUrl: string;
    readonly mainSiteUrl: string;
    readonly openningYear: string;
    readonly orthoteamRoles: string;
}

const _APP_CONFIG: BehaviorSubject<AppConfig> = new BehaviorSubject<AppConfig>(null);

export let APP_CONFIG: Observable<AppConfig> = _APP_CONFIG.pipe(
    filter(_ => Utilities.isDefined(_))
);

@Injectable({
    providedIn: 'root'
})
export class AppConfigService {
    constructor(private http: HttpClient) { }

    appConfig: AppConfig;

    load() {
        return this.http.get('/assets/config/config.json').toPromise().then((envResponse: any) => {
            const config = new AppConfig();
            const descoped: any = envResponse.ng;
            descoped.environmentType = EnvironmentTypes[descoped.environmentType];
            descoped.logLevel = LogLevel[descoped.logLevel];
            _APP_CONFIG.next(Object.assign(config, descoped));
            this.appConfig = descoped;
            return true;
        });
    }

    get orthoTeamRoles(): string {
        var roles = this.appConfig.orthoteamRoles;
        return roles;
    }
}
