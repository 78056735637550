import { Applicant } from './applicant.model';
import { MemberApplicationType } from './member-application-type.model';
import { ApplicationStatus } from './application-status.model';
import { ApplicationParent } from '../data/application-parent.model';

export class ApplicantApplication {
    Id: string;
    Applicant: Applicant;
    ApplicationType: MemberApplicationType;
    Version: number;
    FormState: string;
    Status: ApplicationStatus;

    constructor() {
        this.Applicant = new Applicant();
        this.ApplicationType = new MemberApplicationType();
        this.Status = new ApplicationStatus();
    }

    ToApplicantApplicationDto(data: any): ApplicantApplication {
        this.Id = data.Id;
        this.Version = data.Version;
        this.Applicant = this.Applicant.ToApplicantDTO(data.Applicant);
        this.ApplicationType = this.ApplicationType.ToMemberApplicationTypeDto(data.ApplicationType);
        this.Status = this.Status.ToApplicationStatusDto(data.Status);
        this.FormState = data.FormState;

        return this;
    }
}
