export class ApplicationStatus {
    Id: string;
    Name: string;

    ToApplicationStatusDto(data: any): ApplicationStatus {
        this.Id = data.Id;
        this.Name = data.Name;

        return this;
    }
}
