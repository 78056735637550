<div *ngIf="field" [formGroup]="baseFormGroup" class="form-group" [required]="!field.fieldIsDisabled"
  [appDisable]="field.fieldIsDisabled">
  <app-aaos-form-field-title [field]="field" [displayText]="field.displayName"></app-aaos-form-field-title>
  <input type="text" formControlName="{{field.name}}" class="form-control" [required]="isRequired"
    (blur)="componentChange($event)">
    <div *ngIf="field.tooltipText" class="smallerText textFieldTooltipText emailTooltipText">{{field.tooltipText}}</div>
  <div class="required-text" *ngIf="!field.value && isRequired">
    {{field.displayName}} is required
  </div>
  <div class="required-text" *ngIf="this.baseFormGroup.get(this.field.name).hasError('maxlength')">Maximum 10 characters</div>
</div>
