import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { FormField } from '@shared/form-fields/models/form-field';
import { FieldsPrerequisite } from '@shared/models/data/fieldsPrerequisite';
import { LicensureDetail } from '@shared/models/data/LicensureDetail';

@Component({
  selector: 'app-aaos-licensure-list',
  templateUrl: './licensure-list.component.html',
  styleUrls: ['./licensure-list.component.scss']
})
export class LicensureListComponent implements OnInit {
  @Output() componentChanged = new EventEmitter();

  @Input() dynamicForm: FormGroup;
  @Input() field: FormField;
  @Input() fieldsPrerequisite: FieldsPrerequisite;
  licensureDetails: LicensureDetail[];

  lastId = 0;

  constructor() { }

  ngOnInit() {
    this.setLicenseList();
    this.validateFormGroup();
  }

  public setLicenseList() {
    this.licensureDetails = new Array<LicensureDetail>();
    if (this.field.value) {
      const parsedJson: Array<LicensureDetail> = JSON.parse(this.field.value);
      this.licensureDetails = parsedJson;

      const idList = this.licensureDetails.map(x => x.id);
      this.lastId = Math.max.apply(Math, idList.map(function(o) { return o; })) + 1;
    } else {
      this.addLicense();
    }
  }

  public addLicense() {
    const additionalLicensure = new LicensureDetail();
    additionalLicensure.id = this.lastId;
    this.lastId++;
    this.licensureDetails.push(additionalLicensure);
    this.validateFormGroup();
  }

  deleteLicense(id: number) {
    this.licensureDetails = this.licensureDetails.filter(x => x.id !== id);
    this.validateFormGroup();
    this.onComponentChange(null);
  }

  onComponentChange(event: any) {
    if (event !== null) {
      const i = this.licensureDetails.findIndex(x => x.id === event.id);
      if (i !== -1) {
        this.licensureDetails[i].id = event.id;
        this.licensureDetails[i].licenseNumber = event.licenseNumber;
        this.licensureDetails[i].issuedDate = event.issuedDate;
        this.licensureDetails[i].state = event.state;
        this.licensureDetails[i].expireDate = event.expireDate;
      }
    }

    this.field.value = JSON.stringify(this.licensureDetails);
    this.componentChanged.emit(this.field);
  }

  validateFormGroup() {
    if (this.licensureDetails == null || this.licensureDetails.length < 1) {
      this.dynamicForm.get(this.fieldsPrerequisite.formModuleTitle).setErrors(Validators.required);
    } else {
      this.dynamicForm.get(this.fieldsPrerequisite.formModuleTitle).setErrors(null);
    }
  }
}
