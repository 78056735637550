import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unchangeable-member-type',
  templateUrl: './unchangeable-member-type.component.html',
  styleUrls: ['./unchangeable-member-type.component.scss']
})
export class UnchangeableMemberTypeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
