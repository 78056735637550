import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MemberFormListRequirement } from '@shared/models/data/MemberFormListRequirement';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Constants } from '../constants/constants';

@Injectable({
  providedIn: 'root'
})
export class MemberFormListRequirementService {

  constructor(private http: HttpClient) { }

  // call endpoint to grab for this module/member
  getMemberFormListRequirement(formModuleId: string, applicantId: string): Observable<MemberFormListRequirement> {
    return this.http.get<MemberFormListRequirement>
    (`${Constants.ApiUrls.GetMemberFormListRequirement}/${formModuleId}/${applicantId}`).pipe();
  }

}
