import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormField } from '@shared/form-fields/models/form-field';
import { AddressDetail } from '@shared/models/data/AddressDetail';
import { FieldsPrerequisite } from '@shared/models/data/fieldsPrerequisite';
import { UserProfileService } from '@shared/services/user-profile.service';

@Component({
  selector: 'app-aaos-address-list',
  templateUrl: './address-list.component.html',
  styleUrls: ['./address-list.component.scss']
})
export class AddressListComponent implements OnInit {

  @Input() dynamicForm: FormGroup;
  @Input() field: FormField;
  @Input() fieldsPrerequisite: FieldsPrerequisite;
  addressDetails: AddressDetail[];
  multipleAddress = false;
  addressListFormGroup: FormGroup;
  @Output() componentChanged = new EventEmitter();
  isLoading = true;


  constructor(
    private userProfileService: UserProfileService,
  ) { }

  ngOnInit() {
    this.setAddressList();
    this.fieldsPrerequisite.multipleAddresses = false;

  }

  public setAddressList() {
    return this.userProfileService.getUserAddresses().subscribe(x => {
      this.isLoading = false;
      if (this.field.value) {
        const parsedJson: AddressDetail = JSON.parse(this.field.value);
        this.addressDetails = new Array<AddressDetail>();
        this.addressDetails.push(parsedJson);
      } 
      else if (x.filter(address => address.isPrimaryAddress === true).length > 0) {
        this.addressDetails = x.filter(address => address.isPrimaryAddress === true);
      } else {
        this.addressDetails = new Array<AddressDetail>();
        this.addressDetails.push(new AddressDetail());
      }
    });
  }

  onComponentChange(event: any) {
    this.field.value = event;
    this.componentChanged.emit(this.field);
  }

  setAddressListFormControl() {
  }

  public addAddress() {
    const additionalAddress = new AddressDetail();
    additionalAddress.addressId = this.addressDetails.length + 1;
    this.addressDetails.push(additionalAddress);
  }

  public deleteAddress(id: string) {
    this.addressDetails = this.addressDetails.filter(x => x.addressId.toString() !== id);
  }

}
