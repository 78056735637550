<div *ngIf="isLoading$" class="loading-root">
    <div [ngClass]="{ 'full-page-loading': !displayInline }" *ngIf="isLoading$ | async">
      <div class="loading">
        <div class="loading-spinner">
          <div class="loading-inner"></div>
        </div>
      </div>
      <div class="spinner-caption" *ngIf="spinnerCaption">
        <h1>{{ spinnerCaption }}</h1>
      </div>
    </div>
  </div>
  <div *ngIf="isLoading !== null" class="loading-root">
    <div [ngClass]="{ 'full-page-loading': !displayInline }" *ngIf="isLoading">
      <div class="loading">
        <div class="loading-spinner">
          <div class="loading-inner"></div>
        </div>
      </div>
    </div>
  </div>
  