<div *ngIf="(dynamicForm) && formGroup" class="form-group" [formGroup]="formGroup">
    <div *ngIf="(field)" [id]="field.id">
        <app-aaos-form-field-title [field]="field" [displayText]="field.displayName"></app-aaos-form-field-title>
        <div *ngIf="!field.fieldIsDisabled else display">
            <mat-select *ngIf="(statesLoaded === true && states.length === 0) === false" [formControlName]="field.name" [disabled]="field.fieldIsDisabled" [required]="isRequired" [(value)]="selectedValue" disableRipple panelClass="mat-panel" (blur)="componentChange($event)">
                <mat-option *ngFor="let option of states" [disabled]="field.fieldIsDisabled" [value]="option.preferenceCode">{{ option.preferenceDescription }}
                </mat-option>
            </mat-select>
            <input *ngIf="statesLoaded === true && states.length === 0" class="form-control" [formControlName]="field.name"
                (blur)="detectInputChange($event)" [required]="isRequired" type="text" />
        </div>

        <div class="required-text" *ngIf="isRequired && !selectedValue && !field.value">
            {{field.displayName}} is required
        </div>
        <ng-template #display>
            {{field.value}}
        </ng-template>
    </div>
</div>
<app-aaos-spinner [isLoading]="loading"></app-aaos-spinner>