import { OnInit, Input, Directive, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { FormField, FormFieldConstants } from '@shared/form-fields/models/form-field';
import { Preference, ProfileFormOption } from '@shared/models/data';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { FieldsPrerequisite } from '@shared/models/data/fieldsPrerequisite';

@Directive()
export abstract class BaseFieldComponentDirective implements OnInit, OnChanges {
  @Input()
  field: FormField;
  @Input()
  displayText: string;
  @Input()
  form: AbstractControl;
  @Input()
  formOptions: ProfileFormOption[] = [];
  @Input()
  editMode: boolean;
  @Input()
  refreshEditState$: Subject<void>;
  fieldIsDisabled: boolean;
  isReadonly: boolean;
  @Input()
  initialFocusField;
  @Output() componentChanged = new EventEmitter();
  baseFormGroup = new FormGroup({});
  @Input() fieldsPrerequisite: FieldsPrerequisite;
  @Input() dynamicForm: FormGroup;
  @Input() active: boolean;

  public static getPrefixForFieldAndOption(fieldId: string, option: Preference) {
    return `{{${fieldId}}}{{${option.preferenceCode}}}{{${option.preferenceSubCode}}}`;
  }
  public static parsePrefixForFieldAndOption(s: string): any {
    const splitStrings = s.split('}}');

    return {
      fieldId: splitStrings[0].replace('{{', ''),
      PreferenceCode: splitStrings[1].replace('{{', ''),
      PreferenceSubCode: splitStrings[2].replace('{{', '')
    };
  }

  public static mapFromMultiSelectToPreference(formValue: string[], sourcePreferences: Preference[]): Preference[] {
    return formValue
      .map(value => {
        const substrings = value.split('}}');
        const preferenceCode = substrings[1].replace('{{', '');
        const preferenceSubCode = substrings[2].replace('{{', '');

        return sourcePreferences.find(
          _ => _.preferenceCode.toLowerCase() === preferenceCode.toLowerCase() && _.preferenceSubCode === preferenceSubCode
        );
      })
      .filter(_ => !!_);
  }

  buildFormGroup() {
    if (this.dynamicForm) {
      this.baseFormGroup = this.dynamicForm.get(this.fieldsPrerequisite.formModuleTitle) as FormGroup;
      this.baseFormGroup.addControl(this.field.name, new FormControl());
    } else if (this.field.additionalValues?.toString() === FormFieldConstants.Questionnaire) {
      this.baseFormGroup.addControl(this.field.name, new FormControl());
    }
  }

  ngOnChanges(changes: SimpleChanges) {

    this.baseFormGroup.updateValueAndValidity();
    this.validateFormGroup();

  }


  validateFormGroup() {
    if (!this.field.fieldIsDisabled) {
      if (this.baseFormGroup && this.dynamicForm && this.fieldsPrerequisite) {
        if (this.baseFormGroup.invalid) {
          this.dynamicForm.get(this.fieldsPrerequisite.formModuleTitle).setErrors(Validators.required);
        } else {
          this.dynamicForm.get(this.fieldsPrerequisite.formModuleTitle).setErrors(null);
        }
      }
    } else if (this.baseFormGroup.contains(this.field.name)) {
      this.baseFormGroup.get(this.field.name).setErrors(null);
      this.baseFormGroup.get(this.field.name).setValidators(null);
      this.baseFormGroup.updateValueAndValidity();
    }
  }

  componentChange(event: any) {

    if (event.target) {
      if (event.target.value === undefined) {
        this.field.value = String(event.target.textContent).trim();
      } else {
        this.field.value = String(event.target.value).trim();
      }
    } else {
      if (event.value !== undefined) {
        this.field.value = String(event.value).trim();
      }
    }
    this.validateFormGroup();
    this.componentChanged.emit(this.field);
  }

  populateFormGroup() {
    const control = this.baseFormGroup.get(this.field.name);
    if(this.field.value == null) {
      control.setValue(null);
    }
  }


  ngOnInit() {
    this.buildFormGroup();
    this.populateFormGroup();
    this.baseFormGroup.updateValueAndValidity();
    this.validateFormGroup();
  }

}
