<div class="close-container">
  <mat-icon class="close-icon" (click)="dialogRef.close()"></mat-icon>
</div>
<div class="title title--lg dialog" mat-dialog-title>Terms of Use and Privacy Policies</div>
<mat-dialog-content>
  <mat-tab-group class="expand-height">
    <mat-tab label="Terms of Use">
      <p>
        AAOS's <a href="https://www.aaos.org/about/bylaws-policies/organizational-policies/website-disclaimer/terms-of-use/" target="_blank">Terms of Use</a>.
      </p>
    </mat-tab>
    <mat-tab label="Google's Terms of Service / Privacy">
      <!-- https://developers.google.com/maps/documentation/places/web-service/policies -->
      <p>
        By using this application, you as a user are bound by <a href="https://policies.google.com/terms?hl=en" target="_blank">Google's Terms of Service</a>.
      </p>
      <p>
        This application is utilizing Google Maps API(s). Click the link to <a href="https://policies.google.com/privacy" target="_blank">view Google's Privacy Policy</a>.
      </p>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="btn--inline dialog-btn-container">
    <!-- <button class="btn btn--small btn--outline hide-on-mobile ">SPONSOR NOT FOUND</button>
    <button class="btn btn--small btn--solid btn--red">ADD SPONSOR</button> -->
    <button (click)="dialogRef.close()" class="btn btn--small btn--solid btn--red">CLOSE</button>
  </div>
</mat-dialog-actions>
