import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ApplicationService } from '@shared/services/administration/application.service';

@Component({
  selector: 'app-application-configuration-page',
  templateUrl: './application-configuration-page.component.html',
  styleUrls: ['./application-configuration-page.component.scss']
})
export class ApplicationConfigurationPageComponent implements OnInit {
  data = [];
  columnsToDisplay = ['memberType', 'canApplyTo', 'fromDate', 'toDate', 'delete', 'edit'];
  editingRow = null;

  addMemberType = new FormControl('', Validators.required);
  addCanApplyTo = new FormControl('', Validators.required);
  fromDate = null;
  toDate = null;

  editMemberType = new FormControl('');
  editCanApplyTo = new FormControl('');
  editFromDate = null;
  editToDate = null;

  allMemberTypes = ['*', 'A', 'B', 'C', 'D', 'E'];
  applicationMemberTypes = [];
  filteredApplicationMemberTypes = [];

  loadingMemberTypes = true;
  loadingMappings = true;
  loadingAllMemberGroupCodes = true;
  loadingAction = false;

  constructor(private applicationService: ApplicationService) { }

  ngOnInit() {
    this.applicationService.getAllMemberApplicationTypes().subscribe(x => {
      this.applicationMemberTypes = x.map(y => y.memberType.code);
      this.applicationMemberTypes.sort();

      // give the option to select all
      this.applicationMemberTypes.unshift('*');

      this.loadingMemberTypes = false;
    });

    this.applicationService.getAllMemberGroupCodes().subscribe(memGodes => {
      this.allMemberTypes = memGodes.map(x => x.preferenceCode);
      this.allMemberTypes.sort();

      // give the option to select all
      this.allMemberTypes.unshift('*');

      this.loadingAllMemberGroupCodes = false;
    });

    this.applicationService.getMemberApplicationMappings().subscribe(x => {
      this.data = x;
      this.loadingMappings = false;
    });
  }


  FilterCanApplyTo() {
    // //remove the same selections from availability
    // let filteredData = this.data.filter(x => x.memberCode === this.addMemberType.value);
    // let sameToRemove = filteredData.map(x => x.canSeeApplyTo);

    // filteredData = this.data.filter(x => x.memberCode === '*');
    // let wildsToRemove = filteredData.map(x => x.canSeeApplyTo);

    // const removeList = sameToRemove.concat(wildsToRemove);

    // // if (removeList.includes('*')) {
    // //   this.filteredApplicationMemberTypes = [];
    // // } else {
    //   this.filteredApplicationMemberTypes = this.applicationMemberTypes.filter(x => removeList.includes(x) === false);
    // //}
  }

  EditRow(item: any) {
    this.editFromDate = item.availableFromDate;
    this.editToDate = item.availableToDate;
    this.editMemberType.setValue(item.memberCode);
    this.editCanApplyTo.setValue(item.canSeeApplyTo);
    this.editingRow = item.id;

    this.FilterCanApplyTo();
  }

  SaveRowChanges(item: any) {
    this.loadingAction = true;

    const mapping = {
      id: item.id,
      memberCode: this.editMemberType.value,
      canSeeApplyTo: this.editCanApplyTo.value,
      availableFromDate: this.editFromDate,
      availableToDate: this.editToDate
    };

    this.applicationService.modifyMemberApplicationMapping(mapping).subscribe(x => {
      this.applicationService.getMemberApplicationMappings().subscribe(memAppMappings => {
        this.data = memAppMappings;

        this.loadingAction = false;
      });
    });

    this.editingRow = null;

    this.FilterCanApplyTo();
  }

  ClearRowFromDate() {
    this.fromDate = null;
  }

  ClearRowToDate() {
    this.toDate = null;
  }

  ClearEditFromDate() {
    this.editFromDate = null;
  }

  ClearEditToDate() {
    this.editToDate = null;
  }

  AddRow() {
    this.loadingAction = true;

    // send info over to the server
    const mapping = {
      memberCode: this.addMemberType.value,
      canSeeApplyTo: this.addCanApplyTo.value,
      availableFromDate: this.fromDate,
      availableToDate: this.toDate
    };

    this.applicationService.addMemberApplicationMapping(mapping).subscribe(x => {
      this.applicationService.getMemberApplicationMappings().subscribe(memAppMappings => {
        this.data = memAppMappings;

        this.addMemberType.setValue(null);
        this.addCanApplyTo.setValue(null);
        this.ClearRowFromDate();
        this.ClearRowToDate();
        this.FilterCanApplyTo();

        this.loadingAction = false;
      });
    });
  }

  DeleteRow(item: any) {
    this.loadingAction = true;

    this.applicationService.deleteMemberApplicationMapping(item.id).subscribe(x => {
      this.applicationService.getMemberApplicationMappings().subscribe(memAppMappings => {
        this.data = memAppMappings;
        this.FilterCanApplyTo();
        this.loadingAction = false;
      });
    });
  }
}
