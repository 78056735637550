import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentage'
})
export class PercentagePipe implements PipeTransform {

  transform(value: number, totalValue?: number): number {
    const result = ((value / totalValue) * 100);
    return Math.round(result);
  }

}
