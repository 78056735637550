import { Injectable } from '@angular/core';
import { Adapter } from '@shared/services/utils/adapter';

export class StatusModel {

  applicationType: string;
  applications?: Map<string, Array<StatusRow>>;
}

export class StatusType {

  applicationStatus: string;
  applicants?: StatusRow[];
}

@Injectable({
  providedIn: 'root'
})

export class StatusRow {

  firstName: string;
  lastName: string;
  mcid: string;
  primaryEmailAddress: string;
  submittedDate: string;
  createdDate: string;
  completion: number;
  applicantId: string;
  applicationId: string;
  memberApplicationTypeId: string;
  memberTypeCode: string;
  memberTypeName: string;
  residencyEndDate: Date;
  applicationStatus: string;
  boardRequiredApproval;
  paid: string;
}

@Injectable({
  providedIn: 'root'
})
export class StatusRowAdapter implements Adapter<StatusRow> {

  adapt(item: any): StatusRow {
    const row: StatusRow = {
      mcid: item.masterCustomerId,
      firstName: item.firstName,
      lastName: item.lastName,
      primaryEmailAddress: item.primaryEmailAddress,
      completion: item.completion,
      applicantId: item.applicantId,
      applicationId: item.applicationId,
      memberApplicationTypeId: item.memberApplicationTypeId,
      memberTypeCode: item.memberTypeCode,
      memberTypeName: item.memberTypeName,
      residencyEndDate: item.residencyEndDate,
      applicationStatus: item.applicationStatus,
      boardRequiredApproval: item.boardRequiredApproval,
      paid: item.paid,
      createdDate: item.createdDate,
      submittedDate: item.submittedDate,
    };
    return row;
  }
}
