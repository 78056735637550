import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ResidencyProgramListComponent } from '@shared/components/residency-program-list/residency-program-list.component';
import { Preference, ProfileFormOption } from '@shared/models/data';
import { FieldsPrerequisite } from '@shared/models/data/fieldsPrerequisite';
import { ResidencyProgramDetail } from '@shared/models/data/ResidencyProgramDetail';
import { FormValidatorService } from '@shared/services/forms/form-validator.service';

@Component({
  selector: 'app-aaos-residency-program',
  templateUrl: './residency-program.component.html',
  styleUrls: ['./residency-program.component.scss']
})
export class ResidencyProgramComponent implements OnInit, AfterViewInit {

  @Input() fieldsPrerequisite: FieldsPrerequisite;
  @Input() residencyProgramDetail: ResidencyProgramDetail;
  @Input() applicationCountries: Preference[];
  @Input() dynamicForm: FormGroup;
  @Input() formOptions: ProfileFormOption[];

  @Output() componentChanged = new EventEmitter();

  @ViewChildren('institutionInfo') institutionInfo: QueryList<any>;

  updatedInstitution = new ResidencyProgramDetail();
  autocomplete: google.maps.places.Autocomplete;
  nameField: HTMLInputElement;
  cityField: HTMLInputElement;
  stateField: HTMLInputElement;
  countryField: HTMLInputElement;
  baseFormGroup = new FormGroup({});
  loading = true;
  states: Preference[] = [];
  degrees = [
    {
      code: 'BA',
      description: 'Bachelor of Arts'
    },
    {
      code: 'BS',
      description: 'Bachelor of Science'
    },
    {
      code: 'MBA',
      description: 'MBA'
    },
    {
      code: 'MD',
      description: 'Doctor of Medicine'
    },
    {
      code: 'MS',
      description: 'Masters of Science'
    },
    {
      code: 'PHD',
      description: 'Doctor of Philosophy'
    },
    {
      code: 'RP',
      description: 'Residency Program'
    },
    {
      code: 'FP',
      description: 'Fellowship Program'
    },
    {
      code: 'PA',
      description: 'Physician Assistant/Orthopaedic PA'
    }
  ];

  constructor(
    private residencyProgramList: ResidencyProgramListComponent) { }

  ngAfterViewInit(): void {
  }

  loadGoogle() {
      this.initAutocomplete();
  }

  ngOnInit() {
    this.addControlsToFormGroup();
    this.populateFormControl();
    this.loading = false;
    this.validateFormGroup();
  }

  isDeletable() {
    return this.residencyProgramDetail.id !== 0;
  }

  deleteInstitution(event: any) {
    this.residencyProgramList.deleteInstitution(this.residencyProgramDetail.id);
  }

  detectInputChange() {
    this.updatedInstitution.id = this.residencyProgramDetail.id;
    this.updatedInstitution.name = this.baseFormGroup.get('name').value;
    this.updatedInstitution.country = this.baseFormGroup.get('country').value;
    this.updatedInstitution.city = this.baseFormGroup.get('city').value;
    this.updatedInstitution.state = this.baseFormGroup.get('state').value;
    this.updatedInstitution.startDate = this.baseFormGroup.get('startDate').value;
    this.updatedInstitution.endDate = this.baseFormGroup.get('endDate').value;
    this.validateFormGroup();
    this.componentChanged.emit(this.updatedInstitution);
  }

  invalidRequiredField(fieldName: string): boolean {
    if (this.baseFormGroup.get(fieldName).hasError('required')) {
      return true;
    }
    return false;
  }

  invalidDateField(): boolean {
    if (!this.invalidRequiredField('startDate') && !this.invalidRequiredField('endDate') &&
      this.baseFormGroup.hasError('before')) {
      return true;
    }
    return false;
  }

  validateFormGroup() {
    if (this.baseFormGroup.invalid) {
      this.dynamicForm.get(this.fieldsPrerequisite.formModuleTitle).setErrors(Validators.required);
    } else {
      this.dynamicForm.get(this.fieldsPrerequisite.formModuleTitle).setErrors(null);
    }
  }

  addControlsToFormGroup() {
    // this.dynamicForm.addControl(this.fieldsPrerequisite.formModuleTitle, this.baseFormGroup);
    this.baseFormGroup.addControl('name', new FormControl(null, Validators.required));
    this.baseFormGroup.addControl('country', new FormControl(null, Validators.required));
    this.baseFormGroup.addControl('city', new FormControl(null, Validators.required));
    this.baseFormGroup.addControl('state', new FormControl(null, Validators.required));
    this.baseFormGroup.addControl('startDate', new FormControl(null, Validators.required));
    this.baseFormGroup.addControl('endDate', new FormControl(null, Validators.required));
    this.baseFormGroup.setValidators([FormValidatorService.getDateValidator('startDate', 'endDate')])
  }

  populateFormControl() {
    this.baseFormGroup.get('name').setValue(this.residencyProgramDetail.name);
    this.baseFormGroup.get('country').setValue(this.residencyProgramDetail.country);
    this.baseFormGroup.get('city').setValue(this.residencyProgramDetail.city);
    this.baseFormGroup.get('state').setValue(this.residencyProgramDetail.state);
    this.baseFormGroup.get('startDate').setValue(this.residencyProgramDetail.startDate);
    this.baseFormGroup.get('endDate').setValue(this.residencyProgramDetail.endDate);
  }

  initAutocomplete() {
    this.nameField =
      document.querySelector(`#name_${this.residencyProgramDetail.id}`) as HTMLInputElement;

    this.cityField =
      document.querySelector(`#city_${this.residencyProgramDetail.id}`) as HTMLInputElement;

    this.stateField =
      document.querySelector(`#state_${this.residencyProgramDetail.id}`) as HTMLInputElement;

    this.countryField =
      document.querySelector(`#country_${this.residencyProgramDetail.id}`) as HTMLInputElement;

    // Create the autocomplete object, restricting the search predictions to
    // addresses in the US and Canada.
    this.autocomplete = new google.maps.places.Autocomplete(this.nameField, {
      fields: ['address_components', 'name'],
      types: ['establishment'],
    });
    // this.address1Field.focus();

    // When the user selects an address from the drop-down, populate the
    // address fields in the form.
    google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
      this.fillInAddress();
    });
  }

  fillInAddress() {
    // Get the place details from the autocomplete object.
    const place = this.autocomplete.getPlace();
    const institutionName = place.name;
    let city = '';
    let state = '';
    let country = '';

    // Get each component of the address from the place details,
    // and then fill-in the corresponding field on the form.
    // place.address_components are google.maps.GeocoderAddressComponent objects
    // which are documented at http://goo.gle/3l5i5Mr
    for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
      // @ts-ignore remove once typings fixed
      const componentType = component.types[0];

      switch (componentType) {
        case 'locality':
          if (component.long_name !== '') {
            city = component.long_name;
          }
          break;

        case 'sublocality_level_1':
          city = component.long_name;
          break;

        case 'administrative_area_level_1': {
          state = component.long_name;
          break;
        }
        case 'country': {
          country = component.long_name;
          break;
        }
      }
    }

    this.baseFormGroup.get('name').setValue(institutionName);
    this.baseFormGroup.get('city').setValue(city);
    this.baseFormGroup.get('state').setValue(state);
    if (this.applicationCountries.find(x => x.preferenceDescription === country)) {
      this.baseFormGroup.get('country').setValue(country);
    }

    this.countryField.focus();

    this.detectInputChange();
  }
}
