<div *ngIf="isDeletable() === true">
  <div id="{{this.institutionDetail.id}}" class="delete-file-button" (click)="deleteInstitution($event.target.id)">
  </div>
</div>
<div *ngIf="!loading">

    <div class="form-group" #establishmentInfo [formGroup]="baseFormGroup">

      <div class="title title--xs">Name</div>
      <input class="form-control" formControlName="name" id="name_{{this.institutionDetail.id}}"
        (blur)="detectInputChange($event)" (focus)="loadGoogle()" required="true" type="text" />
      <div class="required-text" *ngIf="invalidRequiredField('name')">Name is required</div>

      <div class="title title--xs">City</div>
      <input class="form-control" formControlName="city" id="city_{{this.institutionDetail.id}}"
        (blur)="detectInputChange($event)" required="true" type="text" />
      <div class="required-text" *ngIf="invalidRequiredField('city')">City is required</div>

      <div class="title title--xs">State/Province</div>
      <input class="form-control" formControlName="state" id="state_{{this.institutionDetail.id}}"
        (blur)="detectInputChange($event)" required="true" type="text" />
      <div class="required-text" *ngIf="invalidRequiredField('state')">State/Province is required</div>

      <div class="title title--xs">Country of Education</div>
      <mat-select required="true" formControlName="country" id="country_{{this.institutionDetail.id}}"
        (blur)="detectInputChange($event)" disableRipple disableOptionCentering panelClass="mat-panel">
        <mat-option *ngFor="let country of applicationCountries" [value]="country.preferenceDescription">
          {{ country.preferenceDescription }}
        </mat-option>
      </mat-select>
      <div class="required-text" *ngIf="invalidRequiredField('country')">Country is required</div>

      <div class="title title--xs">Degree</div>
      <mat-select required="true" formControlName="degree" disableRipple disableOptionCentering panelClass="mat-panel"
        (blur)="detectInputChange($event)">
        <mat-option *ngFor="let degree of degrees" [value]="degree.code">
          {{ degree.description }}
        </mat-option>
      </mat-select>
      <div class="required-text" *ngIf="invalidRequiredField('degree')">Degree is required</div>

      <div class="title title--xs">Start Date</div>
      <div class="dateContainer">
        <div (click)="startDate.open()" class="date--form-control half-width">
          <input [matDatepicker]="startDate" formControlName="startDate" required="true" disabled="true"
            class="form-control">
        </div>
        <mat-datepicker-toggle [for]="startDate"></mat-datepicker-toggle>
        <mat-datepicker disabled="false" #startDate ngDefaultControl (closed)="detectInputChange($event)">
        </mat-datepicker>
      </div>
      <div class="required-text" *ngIf="invalidRequiredField('startDate') || invalidDateField()" >Start Date is required and must be
        precede the End Date
      </div>

      <div class="title title--xs">End Date</div>
      <div class="dateContainer">
        <div (click)="endDate.open()" class="date--form-control half-width">
          <input [matDatepicker]="endDate" formControlName="endDate" required="true" disabled="true"
            class="form-control">
        </div>
        <mat-datepicker-toggle [for]="endDate"></mat-datepicker-toggle>
        <mat-datepicker disabled="false" #endDate ngDefaultControl (closed)="detectInputChange($event)">
        </mat-datepicker>
      </div>
      <div class="required-text" *ngIf="invalidRequiredField('endDate')">End Date is required</div>
    </div>
  </div>
<app-aaos-spinner [isLoading]="loading"></app-aaos-spinner>