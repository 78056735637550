<div class="spacing">
    <div *ngIf="isDeletable() === true">
        <div id="{{this.practiceHistoryDetail.id}}" class="delete-file-button"
            (click)="deletePracticeHistory($event.target.id)"></div>
    </div>
    <h3>Practice History {{index + 1}}</h3>
</div>

<div *ngIf="!loading">
    <div class="form-group" #institutionInfo [formGroup]="baseFormGroup">
        <div class="title title--xs">Name</div>
        <input class="form-control" formControlName="name" id="name_{{this.practiceHistoryDetail.id}}"
            (blur)="detectInputChange($event)" required="true" type="text" />
        <div class="required-text" *ngIf="invalidRequiredField('name')">Name is required</div>

        <div class="title title--xs">City</div>
        <input class="form-control" formControlName="city" id="city_{{this.practiceHistoryDetail.id}}"
            (blur)="detectInputChange($event)" required="true" type="text" />
        <div class="required-text" *ngIf="invalidRequiredField('city')">City is required</div>

        <div class="title title--xs">State/Province</div>
        <mat-select *ngIf="(statesLoaded === true && states.length === 0) === false" required="true" formControlName="state" id="state_{{this.practiceHistoryDetail.id}}"
            (blur)="detectInputChange($event)" disableRipple disableOptionCentering panelClass="mat-panel">
            <mat-option *ngFor="let state of states" [value]="state.preferenceCode">
                {{ state.preferenceDescription }}
            </mat-option>
        </mat-select>
        <input *ngIf="statesLoaded === true && states.length === 0" class="form-control" formControlName="state" id="state_{{this.practiceHistoryDetail.id}}"
            (blur)="detectInputChange($event)" required="true" type="text" />
        <div class="required-text" *ngIf="invalidRequiredField('state')">State is required</div>

        <div class="title title--xs">Practice Employment Setting</div>
        <mat-select required="true" formControlName="employmentSetting" disableRipple disableOptionCentering
            panelClass="mat-panel" (blur)="detectInputChange($event)">
            <mat-option *ngFor="let practiceType of practiceTypes" [value]="practiceType.preferenceCode">
                {{ practiceType.preferenceDescription }}
            </mat-option>
        </mat-select>
        <div class="required-text" *ngIf="invalidRequiredField('employmentSetting')">Practice Employment Setting Setting
            is required</div>

        <div class="title title--xs">Practice Employment Setting Other (Optional)</div>
        <mat-select formControlName="employmentSettingOptional" disableRipple disableOptionCentering
            panelClass="mat-panel" (blur)="detectInputChange($event)">
            <mat-option *ngFor="let practiceType of practiceTypes" [value]="practiceType.preferenceCode">
                {{ practiceType.preferenceDescription }}
            </mat-option>
        </mat-select>

        <div class="title title--xs">Start Date</div>
        <div class="dateContainer">
            <div (click)="startDate.open()" class="date--form-control half-width">
                <input [matDatepicker]="startDate" formControlName="startDate" required="true" disabled="true"
                    class="form-control">
            </div>
            <mat-datepicker-toggle [for]="startDate"></mat-datepicker-toggle>
            <mat-datepicker disabled="false" #startDate ngDefaultControl (closed)="detectInputChange($event)">
            </mat-datepicker>
        </div>
        <div class="required-text" *ngIf="invalidRequiredField('startDate')">Start Date is required</div>

        <div class="title title--xs">Current Practice?</div>
        <mat-radio-group formControlName="currentPractice" disabled="false" (blur)="detectInputChange($event)" required="true">
            <mat-radio-button [value]="'yes'" [checked]="isCurrentPractice" (change)="YesNoClick(true)">Yes
            </mat-radio-button>
            <mat-radio-button [value]="'no'" [checked]="isCurrentPractice === false" (change)="YesNoClick(false)">No
            </mat-radio-button>
        </mat-radio-group>
        <div class="required-text" *ngIf="invalidRequiredField('currentPractice')">Current Practice is required</div>

        <div *ngIf="isCurrentPractice === false">
            <div class="title title--xs">End Date</div>
            <div class="dateContainer">
                <div (click)="endDate.open()" class="date--form-control half-width">

                    <input [matDatepicker]="endDate" formControlName="endDate" disabled="true" class="form-control" />
                </div>
                <mat-datepicker-toggle [for]="endDate"></mat-datepicker-toggle>
                <mat-datepicker disabled="false" #endDate ngDefaultControl (closed)="detectInputChange($event)">
                </mat-datepicker>
            </div>
            <div class="required-text" *ngIf="invalidRequiredField('endDate') || invalidDateField()" >End date is required and must be
                followed by the Begin Date
              </div>
        </div>
    </div>
</div>
<app-aaos-spinner [isLoading]="loading"></app-aaos-spinner>
