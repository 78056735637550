import { Component, OnInit, Input } from '@angular/core';
import { FormField, FormFieldRuleConstants } from '@shared/form-fields/models/form-field';

@Component({
  selector: 'app-aaos-form-field-title',
  templateUrl: './form-field-title.component.html',
  styleUrls: ['./form-field-title.component.scss']
})
export class FormFieldTitleComponent implements OnInit {

  @Input() field: FormField;
  paritalQuestionString: string[];
  @Input() displayText: string;

  optionalRuleId = FormFieldRuleConstants.OptionalEditableId.toLowerCase();
  constructor() { }

  ngOnInit() {
    this.formatSecretMessages();
  }

  private formatSecretMessages() {
    if (this.field.name.includes('NoMessage')) {
      this.paritalQuestionString = this.field.name.split('NoMessage:');
      this.displayText = this.paritalQuestionString[0];
    }
  }
}
