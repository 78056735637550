import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { FormField } from '@shared/form-fields/models/form-field';
import { ProfileFormOption } from '@shared/models/data';
import { FellowshipProgramDetail } from '@shared/models/data/FellowshipProgramDetail';
import { FieldsPrerequisite } from '@shared/models/data/fieldsPrerequisite';
import { Preference } from '@shared/models/data/Preference';

@Component({
  selector: 'app-aaos-fellowship-program-list',
  templateUrl: './fellowship-program-list.component.html',
  styleUrls: ['./fellowship-program-list.component.scss']
})
export class FellowshipProgramListComponent implements OnInit {
  @Output() componentChanged = new EventEmitter();

  @Input() dynamicForm: FormGroup;
  @Input() field: FormField;
  @Input() fieldsPrerequisite: FieldsPrerequisite;
  @Input() applicationCountries: Preference[];
  @Input() formOptions: ProfileFormOption[];


  lastId = 0;
  fellowshipProgramDetails: FellowshipProgramDetail[];

  constructor() { }

  ngOnInit() {
    this.setInstitutionList();
    // this.validateFormGroup();
  }

  public setInstitutionList() {
    this.fellowshipProgramDetails = new Array<FellowshipProgramDetail>();
    if (this.field.value) {
      const parsedJson: Array<FellowshipProgramDetail> = JSON.parse(this.field.value);
      this.fellowshipProgramDetails = parsedJson;

      const idList = this.fellowshipProgramDetails.map(x => x.id);
      this.lastId = Math.max.apply(Math, idList.map(function (o) { return o; })) + 1;
    } else {
      this.addInstitution();
    }
  }

  public addInstitution() {
    const additionalInstitution = new FellowshipProgramDetail();
    additionalInstitution.id = this.lastId;
    this.lastId++;
    this.fellowshipProgramDetails.push(additionalInstitution);
    // this.validateFormGroup();
  }

  deleteInstitution(id: number) {
    this.fellowshipProgramDetails = this.fellowshipProgramDetails.filter(x => x.id !== id);
    // this.validateFormGroup();
    this.onComponentChange(null);
  }

  onComponentChange(event: any) {
    if (event !== null) {
      const i = this.fellowshipProgramDetails.findIndex(x => x.id === event.id);
      if (i !== -1) {
        this.fellowshipProgramDetails[i].name = event.name;
        this.fellowshipProgramDetails[i].country = event.country;
        this.fellowshipProgramDetails[i].city = event.city;
        this.fellowshipProgramDetails[i].state = event.state;
        this.fellowshipProgramDetails[i].directorFirstName = event.directorFirstName;
        this.fellowshipProgramDetails[i].directorLastName = event.directorLastName;
        this.fellowshipProgramDetails[i].startDate = event.startDate;
        this.fellowshipProgramDetails[i].endDate = event.endDate;
      }
    }

    this.field.value = JSON.stringify(this.fellowshipProgramDetails);
    this.componentChanged.emit(this.field);
  }

  validateFormGroup() {
    if (this.fellowshipProgramDetails == null || this.fellowshipProgramDetails.length < 1) {
      this.dynamicForm.get(this.fieldsPrerequisite.formModuleTitle).setErrors(Validators.required);
    } else {
      this.dynamicForm.get(this.fieldsPrerequisite.formModuleTitle).setErrors(null);
    }
  }
}
