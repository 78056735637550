<div class="container">
    <div class="row justify-content-center">
        <mat-tab-group animationDuration="0ms" (selectedTabChange)="onTabChanged($event)">
            <mat-tab *ngFor="let type of memberApplicationTypes; let index=index" label="type.name">
                <ng-template mat-tab-label>{{type.name}}</ng-template>
                <h2>{{type.name}}</h2>
                <app-status-tab [memberTypeId]="type.id"></app-status-tab>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
<app-aaos-spinner [isLoading]="loading"></app-aaos-spinner>