<div class="container">
  <div class="row justify-content-center">
    <!-- <div class="col-12 col-sm-8"> -->
    <div class="col-xl">
      <h3>
        Unpaid Notifications
      </h3>


      <aaos-payment-reminder-table>

      </aaos-payment-reminder-table>
    </div>
  </div>
</div>