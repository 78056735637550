export class MemberType {
    Id: string;
    Name: string;
    Code: string;

    ToMemberTypeDto(data: any): MemberType {
        this.Id = data.id;
        this.Name = data.name;
        this.Code = data.code;

        return this;
    }
}
