import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { BaseFieldComponentDirective } from '../base-field-component';
import { FormValidatorService } from '@shared/services/forms/form-validator.service';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent extends BaseFieldComponentDirective implements OnInit {

  isRequired = true;

  ngOnInit(): void {
    super.ngOnInit();
    const constField = this.baseFormGroup.get(this.field.name);
    if (this.field.displayName.indexOf('Optional') !== -1) {
      this.isRequired = false;
      constField.setValidators([Validators.nullValidator]);
      constField.setErrors(null);
      this.baseFormGroup.updateValueAndValidity();
    }
    this.emailAddressValidator();
  }

  emailAddressValidator(){
    if(this.field.name.toLowerCase().includes('email')) {
      this.baseFormGroup.get(this.field.name).setValidators([Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/), Validators.required]);
      this.baseFormGroup.updateValueAndValidity();
    }
  }

}
