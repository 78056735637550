import { Component, Input, OnInit } from '@angular/core';
import { FormField } from '../models/form-field';

@Component({
  selector: 'app-aaos-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss']
})
export class LabelComponent implements OnInit {
  @Input() field: FormField;

  constructor() { }

  ngOnInit() {
  }

}
