import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomerSearchProfileMini } from '@shared/models/data/CustomerSearchProfileMini';
import { Constants } from '@shared/services/constants/constants';

@Component({
  selector: 'app-sponsors-dialog',
  templateUrl: './sponsors-dialog.component.html',
  styleUrls: ['./sponsors-dialog.component.scss']
})
export class SponsorsDialogComponent implements OnInit {

  sponsor: any;

  @Output()
  actionTaken: EventEmitter<string> = new EventEmitter<string>();
  searchResultDisplayCity: string;

  constructor(public dialogRef: MatDialogRef<SponsorsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  formatDisplayText(sponsor: any) {
    if (sponsor.country !== Constants.CountryConstants.UnitedStates) {
      this.searchResultDisplayCity = `${sponsor.city}, ${sponsor.country}`;
    } else {
      this.searchResultDisplayCity = `${sponsor.city}, ${sponsor.state}`;
    }
  }

  addSponsor() {
    this.actionTaken.next(this.sponsor);
    this.dialogRef.close();
  }

  sponsorNotFound() {
    this.dialogRef.close();
  }

}
