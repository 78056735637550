import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormGroup, Form } from '@angular/forms';
import { FormFieldGroup } from '@shared/form-fields/models/form-field-group';
import { FormField } from '@shared/form-fields/models/form-field';
import { Preference, ProfileFormOption } from '@shared/models/data';
import { FieldsPrerequisite } from '@shared/models/data/fieldsPrerequisite';
import { AddressDetail } from '@shared/models/data/AddressDetail';
import { EducationInstitutionDetail } from '@shared/models/data/EducationInstitutionDetail';
import { ResidencyProgramDetail } from '@shared/models/data/ResidencyProgramDetail';
import { FellowshipProgramDetail } from '@shared/models/data/FellowshipProgramDetail';
import { HospitalPrivilegeDetail } from '@shared/models/data/hospital-privilege-detail';
import { LicensureDetail } from '@shared/models/data/LicensureDetail';
import { CertificationDetail } from '@shared/models/CertificationDetail';
import { Constants } from '@shared/services/constants/constants';

@Component({
    selector: 'app-aaos-form-field-group',
    templateUrl: './form-field-group.component.html',
    styleUrls: ['./form-field-group.component.scss']
})
export class FormFieldGroupComponent implements OnInit, OnChanges {

    @Input() fieldsPrerequisite: FieldsPrerequisite;
    @Input() dynamicForm: FormGroup;
    @Input() formOptions: ProfileFormOption[];
    applicationCountries: Preference[];
    formFields: FormField[];
    @Input() groups: FormFieldGroup[];
    formSubmit: Form;
    false = false;
    @Output() componentChanged = new EventEmitter();
    addressDetails: AddressDetail[];
    institutionDetails: EducationInstitutionDetail[];
    residencyProgramDetails: ResidencyProgramDetail[];
    fellowshipProgramDetails: FellowshipProgramDetail[];
    hospitalPrivilegeDetails: HospitalPrivilegeDetail[];
    licensureDetails: LicensureDetail[];
    certificationDetail: CertificationDetail;
    active: boolean;


    constructor() {
        if (this.dynamicForm === null || this.dynamicForm === undefined) {
            this.dynamicForm = new FormGroup({});
        }
    }

    ngOnInit() {
    }

    ngOnChanges() {
        if (this.formOptions) {
            this.applicationCountries = this.formOptions.find(x => x.key === 'ApplicationCountries').value;
        }
    }

    onComponentChange(component: FormField) {
        this.componentChanged.emit(component);
    }

    yesClicked($event: FormField) {
        if ($event !== null && $event !== undefined) {
            const controlledFields = $event.controlledFields;
            const controlledType = $event.controlledType;

            if (controlledFields !== null && controlledFields !== undefined) {
                if (controlledType !== null && controlledType !== undefined) {
                    if (controlledType === Constants.ControlledType.Yes) {
                        this.enableFields(controlledFields);
                    } else {
                        this.disableFields(controlledFields);
                    }
                }
            }
        }
    }

    noClicked($event: FormField) {
        if ($event !== null && $event !== undefined) {
            const controlledFields = $event.controlledFields;
            const controlledType = $event.controlledType;

            if (controlledFields !== null && controlledFields !== undefined) {
                if (controlledType !== null && controlledType !== undefined) {
                    if (controlledType === Constants.ControlledType.No) {
                        this.enableFields(controlledFields);
                    } else {
                        this.disableFields(controlledFields);
                    }
                }
            }
        }
    }

    enableFields(controlledFields: any) {
        this.groups.forEach(curGroup => {
            const foundFields = curGroup.formFields.filter(f => controlledFields.includes(f.id));
            foundFields.forEach(x => x.fieldIsDisabled = false);
            this.active = true;
        });
    }

    disableFields(controlledFields: any) {
        this.groups.forEach(curGroup => {
            const foundFields = curGroup.formFields.filter(f => controlledFields.includes(f.id));
            foundFields.forEach(x => {
                x.fieldIsDisabled = true;
                x.value = null;
                this.active = false;
            });
        });
    }
}
