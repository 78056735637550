<div class="form-group" *ngIf="(field)" [formGroup]="baseFormGroup">
    <app-aaos-form-field-title [field]="field" [displayText]="field.displayName"></app-aaos-form-field-title>
    <div class="dateContainer" [disabled]='field.fieldIsDisabled'>
        <div (click)="datePicker.open()" class="date--form-control half-width">
            <input [disabled]='true' [required]="isRequired" [matDatepicker]="datePicker" formControlName="{{field.name}}"
                [value]="field.value" class="form-control"
                (dateInput)="componentChange($event)">
        </div>
        <mat-datepicker-toggle [for]="datePicker"></mat-datepicker-toggle>
        <mat-datepicker [disabled]='field.fieldIsDisabled' #datePicker ngDefaultControl></mat-datepicker>
    </div>

    <div class="required-text" *ngIf="!field.value && !field.fieldIsDisabled && isRequired">
        {{field.displayName}} is required
    </div>
    <div class="required-text" *ngIf="invalidDate()" >
        Dates out of order
     </div>
</div>
