<div class="close-container">
  <mat-icon class="close-icon" (click)="dialogRef.close()"></mat-icon>
</div>
<div class="title title--lg dialog" mat-dialog-title>Discount Code</div>
<mat-dialog-content>
  <div [formGroup]="formGroup" class="form-group">
    <div class="smallerText mat-radio-label-content mb15">
      Please enter an active discount code if you have one.
    </div>
    <div class="display--inline">
      <input type="text" formControlName="discountCodeControl" class="form-control" [required]="false">
      <button (click)="validateCode()" class="btn btn--small btn--solid btn--red">APPLY</button>
    </div>
    <div *ngIf="showMessage">
      <div *ngIf="saveSuccess" class="smallerText mb15 mt15">
        <strong>Discount Code Applied!</strong>
      </div>
      <ng-template #invalidDiscountCodeMessage [ngIf]="validDiscountCode == false">
        <div class="required-text">
        Invalid Discount Code
        </div>
      </ng-template>
      <ng-template #failedSavingDiscountCode [ngIf]="saveSuccess == false">
        <div class="required-text">
        Please try again
        </div>
      </ng-template>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="btn--inline dialog-btn-container">
    <button (click)="dialogRef.close()" class=" btn btn--small btn--solid btn--red">CLOSE</button>
  </div>
</mat-dialog-actions>
