import { Preference } from '.';
import { PracticeInformation } from './PracticeInformation';

export class UserDataDetailed {
    primaryEmail: string;
    primaryCountry: string;
    practiceInformation: PracticeInformation;
    areasOfInterest: Preference[];
    primaryPracticeSpecialiation: string;
    suffix: string;
    credentials: string;
    gender: string;
    ethnicity: string;
    sexualOrientation: string;
    pronouns: string;
    dateOfBirth: string;
    primaryPhone: string;
    secondaryEmail: string;
}
